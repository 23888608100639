// export const CREATE = "CREATE";
export const CREATE_LISTING = "CREATE_LISTING";
export const CREATE_PROMO_LISTING = "CREATE_PROMO_LISTING";
export const UPDATE = "UPDATE";
export const UPDATE_LISTING = "UPDATE_LISTING";
export const DEACTIVATE_LISTING = "DEACTIVATE_LISTING";
export const FETCH_DEACTIVATED_LISTINGS = "FETCH_DEACTIVATED_LISTINGS";
export const FETCH_OUT_OF_STOCK_LISTINGS = "FETCH_OUT_OF_STOCK_LISTINGS";
export const REACTIVATE_LISTING = "REACTIVATE_LISTING";
export const DELETE = "DELETE";
export const DELETE_LISTING = "DELETE_LISTING";
export const FETCH_ALL = "FETCH_ALL";
export const FETCH_ALL_ORDERS_PAGINATED = "FETCH_ALL_ORDERS_PAGINATED";
export const FETCH_ALL_ORDERS_PAGINATED_SEARCH =
  "FETCH_ALL_ORDERS_PAGINATED_SEARCH";
export const FETCH_ALL_VENDOR_ORDERS_PAGINATED_SEARCH =
  "FETCH_ALL_VENDOR_ORDERS_PAGINATED_SEARCH";
export const FETCH_ALL_MOBILE_ORDERS_NO_LOADER =
  "FETCH_ALL_MOBILE_ORDERS_NO_LOADER";
export const FETCH_BY_SEARCH = "FETCH_BY_SEARCH";
export const FETCH_CLIENT_BY_SEARCH = "FETCH_CLIENT_BY_SEARCH";
export const FETCH_LISTING = "FETCH_LISTING";
export const LIKE = "LIKE";
export const END_LOADING = "END_LOADING";
export const START_LOADING = "START_LOADING";

export const AUTH = "AUTH";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const CREATE_USER = "CREATE_USER";
export const USER_CREATED = "USER_CREATED";
export const FETCH_USER_SINGLE = "FETCH_USER_SINGLE";
export const AGENT_CREATION_FAIL = "AGENT_CREATION_FAIL";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const LOGOUT = "LOGOUT";
export const FETCH_USERS = "FETCH_USERS";

export const FETCH_ORDER = "FETCH_ORDER";

export const MOBILE_ORDER_DELIVER_SUCCESS = "MOBILE_ORDER_DELIVER_SUCCESS";
export const MOBILE_ORDER_DELIVER_REQUEST = "MOBILE_ORDER_DELIVER_REQUEST";
export const MOBILE_ORDER_DELIVER_FAIL = "MOBILE_ORDER_DELIVER_FAIL";
export const MOBILE_ORDER_DELIVER_RESET = "MOBILE_ORDER_DELIVER_RESET";

export const VENDOR_REGISTER_REQUEST = "VENDOR_REGISTER_REQUEST";
export const VENDOR_REGISTER_SUCCESS = "VENDOR_REGISTER_SUCCESS";
export const VENDOR_REGISTER_FAIL = "VENDOR_REGISTER_FAIL";

export const GET_SELLERS = "GET_SELLERS";
export const GET_SELLERS_FAIL = "GET_SELLERS_FAIL";
export const GET_SELLER = "GET_SELLER";
export const GET_SELLER_FAIL = "GET_SELLER_FAIL";

export const UPDATE_SELLER_REQUEST = "UPDATE_SELLER_REQUEST";
export const UPDATE_SELLER_SUCCESS = "UPDATE_SELLER_SUCCESS";
export const UPDATE_SELLER_FAIL = "UPDATE_SELLER_FAIL";
export const DELETE_SELLER = "DELETE_SELLER";

export const FETCH_VENDOR_ORDERS = "FETCH_VENDOR_ORDERS";
export const VERIFY_LISTING = "VERIFY_LISTING";
export const UNVERIFY_LISTING = "UNVERIFY_LISTING";

export const CREATE_SECTION = "CREATE_SECTION";
export const GET_SECTIONS = "GET_SECTIONS";
export const UPDATE_SECTION = "UPDATE_SECTION";

// export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_COLLECTION = "ADD_COLLECTION";

export const START_LOADING_RESTAURANT_PRODUCT =
  "START_LOADING_RESTAURANT_PRODUCT";
export const END_LOADING_RESTAURANT_PRODUCT =
  "END_START_LOADING_RESTAURANT_PRODUCT";
export const CREATE_RESTAURANT_PRODUCT = "CREATE_RESTAURANT_PRODUCT";
export const GET_RESTAURANT_PRODUCTS = "GET_RESTAURANT_PRODUCTS";
export const CREATE_RESTAURANT_PRODUCT_ERROR =
  "CREATE_RESTAURANT_PRODUCT_ERROR";
export const FETCH_RESTAURANT_PRODUCT_DETAILS =
  "FETCH_RESTAURANT_PRODUCT_DETAILS";
export const FETCH_RESTAURANT_PRODUCT_DETAILS_ERROR =
  "FETCH_RESTAURANT_PRODUCT_DETAILS_ERROR";
export const UPDATE_RESTAURANT_PRODUCT = "UPDATE_RESTAURANT_PRODUCT";
export const UPDATE_RESTAURANT_PRODUCT_ERROR =
  "UPDATE_RESTAURANT_PRODUCT_ERROR";

export const FETCH_RESTAURANTS_REQUEST = "FETCH_RESTAURANTS_REQUEST";
export const FETCH_RESTAURANTS_SUCCESS = "FETCH_RESTAURANTS_SUCCESS";
export const FETCH_RESTAURANTS_FAILURE = "FETCH_RESTAURANTS_FAILURE";

export const UPDATE_RESTAURANT_REQUEST = "UPDATE_RESTAURANT_REQUEST";
export const UPDATE_RESTAURANT_SUCCESS = "UPDATE_RESTAURANT_SUCCESS";
export const UPDATE_RESTAURANT_FAILURE = "UPDATE_RESTAURANT_FAILURE";

export const CREATE_OPTION_GROUP = "CREATE_OPTION_GROUP";
export const GET_OPTION_GROUPS = "GET_OPTION_GROUPS";
export const UPDATE_OPTION_GROUP = "UPDATE_OPTION_GROUP";
export const DELETE_OPTION_GROUP = "DELETE_OPTION_GROUP";

export const CREATE_OPTION = "CREATE_OPTION";
export const GET_OPTIONS = "GET_OPTIONS";
export const UPDATE_OPTION = "UPDATE_OPTION";
export const DELETE_OPTION = "DELETE_OPTION";

export const DELETE_RESTAURANT_PRODUCT_REQUEST =
  "DELETE_RESTAURANT_PRODUCT_REQUEST";
export const UPDATE_RESTAURANT_PRODUCT_SUCCESS =
  "UPDATE_RESTAURANT_PRODUCT_SUCCESS";
export const UPDATE_RESTAURANT_PRODUCT_FAILURE =
  "UPDATE_RESTAURANT_PRODUCT_FAILURE";

export const DELETE_SECTION_REQUEST = "DELETE_SECTION_REQUEST";
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS";
export const DELETE_SECTION_FAILURE = "DELETE_SECTION_FAILURE";

export const FETCH_SELLER_STATS = "FETCH_SELLER_STATS";
export const FETCH_SELLER_STATS_SUCCESS = "FETCH_SELLER_STATS_SUCCESS";
export const FETCH_SELLER_STATS_ERROR = "FETCH_SELLER_STATS_ERROR";

export const FETCH_RESTAURANT_PROFILE = "FETCH_RESTAURANT_PROFILE";
export const UPDATE_RESTAURANT_PROFILE = "UPDATE_RESTAURANT_PROFILE";
export const FETCH_RESTAURANT_PROFILE_ERROR = "FETCH_RESTAURANT_PROFILE_ERROR";

export const GET_RETAILER_WHOLESALERS = "GET_RETAILER_WHOLESALERS";
export const CREATE_SECTION_RW = "CREATE_SECTION_RW";
export const GET_SECTIONS_RW = "GET_SECTIONS_RW";
export const UPDATE_SECTION_RW = "UPDATE_SECTION_RW";
export const DELETE_SECTION_REQUEST_RW = "DELETE_SECTION_REQUEST_RW";
export const DELETE_SECTION_SUCCESS_RW = "DELETE_SECTION_SUCCESS_RW";
export const DELETE_SECTION_FAILURE_RW = "DELETE_SECTION_FAILURE_RW";

export const CREATE_PRODUCT_RW = "CREATE_PRODUCT_RW";
export const GET_PRODUCT_RW = "GET_PRODUCT_RW";
export const UPDATE_PRODUCT_RW = "UPDATE_PRODUCT_RW";
export const DELETE_PRODUCT_RW = "DELETE_PRODUCT_RW";

export const CREATE_RW_OPTION = "CREATE_RW_OPTION";
export const GET_RW_OPTIONS = "GET_RW_OPTIONS";
export const UPDATE_RW_OPTION = "UPDATE_RW_OPTION";
export const DELETE_RW_OPTION = "DELETE_RW_OPTION";
export const CREATE_RW_OPTION_GROUP = "CREATE_RW_OPTION_GROUP";
export const GET_RW_OPTION_GROUPS = "GET_RW_OPTION_GROUPS";
export const UPDATE_RW_OPTION_GROUP = "UPDATE_RW_OPTION_GROUP";
export const DELETE_RW_OPTION_GROUP = "DELETE_RW_OPTION_GROUP";

export const FETCH_RETAILER_WHOLESALER_PROFILE =
  "FETCH_RETAILER_WHOLESALER_PROFILE";
export const UPDATE_RETAILER_WHOLESALER_PROFILE =
  "UPDATE_RETAILER_WHOLESALER_PROFILE";
export const FETCH_RETAILER_WHOLESALER_PROFILE_ERROR =
  "FETCH_RETAILER_WHOLESALER_PROFILE_ERROR";

export const DELETE_RESTAURANT_REQUEST = "DELETE_RESTAURANT_REQUEST";
export const DELETE_RESTAURANT_SUCCESS = "DELETE_RESTAURANT_SUCCESS";
export const DELETE_RESTAURANT_FAILURE = "DELETE_RESTAURANT_FAILURE";

export const DELETE_RESTAURANT_SUCCESS_PRODUCT =
  "DELETE_RESTAURANT_SUCCESS_PRODUCT";
export const DELETE_RESTAURANT_PRODUCT_FAILURE =
  "DELETE_RESTAURANT_PRODUCT_FAILURE";

export const FETCH_RETAILERS_WHOLESALERS = "FETCH_RETAILERS_WHOLESALERS";
export const DELETE_RETAILER_WHOLESALER = "DELETE_RETAILER_WHOLESALER";

export const FETCH_OUTLETS_REQUEST = "FETCH_OUTLETS_REQUEST";
export const FETCH_OUTLETS_SUCCESS = "FETCH_OUTLETS_SUCCESS";
export const FETCH_OUTLETS_FAILURE = "FETCH_OUTLETS_FAILURE";
export const CREATE_OUTLET = "CREATE_OUTLET";
export const UPDATE_OUTLET_SUCCESS = "UPDATE_OUTLET_SUCCESS";
export const UPDATE_OUTLET_FAILURE = "UPDATE_OUTLET_FAILURE";
export const DELETE_OUTLET_SUCCESS = "DELETE_OUTLET_SUCCESS";
export const DELETE_OUTLET_FAILURE = "DELETE_OUTLET_FAILURE";

export const ATTACH_STORE_REQUEST = "ATTACH_STORE_REQUEST";
export const ATTACH_STORE_SUCCESS = "ATTACH_STORE_SUCCESS";
export const ATTACH_STORE_FAILURE = "ATTACH_STORE_FAILURE";

export const UPDATE_OUTLET_MENU_ITEMS_REQUEST =
  "UPDATE_OUTLET_MENU_ITEMS_REQUEST";
export const UPDATE_OUTLET_MENU_ITEMS_SUCCESS =
  "UPDATE_OUTLET_MENU_ITEMS_SUCCESS";
export const UPDATE_OUTLET_MENU_ITEMS_FAILURE =
  "UPDATE_OUTLET_MENU_ITEMS_FAILURE";

export const TOGGLE_OUTLET_ACTIVATION_REQUEST =
  "TOGGLE_OUTLET_ACTIVATION_REQUEST";
export const TOGGLE_OUTLET_ACTIVATION_SUCCESS =
  "TOGGLE_OUTLET_ACTIVATION_SUCCESS";
export const TOGGLE_OUTLET_ACTIVATION_FAILURE =
  "TOGGLE_OUTLET_ACTIVATION_FAILURE";

export const UPDATE_RESTAURANT_OUTLET = "UPDATE_RESTAURANT_OUTLET";
