import { toast } from "react-toastify";
import {
  FETCH_RESTAURANTS_REQUEST,
  FETCH_RESTAURANTS_SUCCESS,
  FETCH_RESTAURANTS_FAILURE,
  UPDATE_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_SUCCESS,
  UPDATE_RESTAURANT_FAILURE,
  DELETE_RESTAURANT_REQUEST,
  DELETE_RESTAURANT_SUCCESS,
  DELETE_RESTAURANT_FAILURE,
  DELETE_RESTAURANT_PRODUCT_REQUEST,
  DELETE_RESTAURANT_SUCCESS_PRODUCT,
  DELETE_RESTAURANT_PRODUCT_FAILURE,
  END_LOADING,
  ATTACH_STORE_REQUEST,
  ATTACH_STORE_SUCCESS,
  ATTACH_STORE_FAILURE,
} from "../constants/actionTypes.js";
import * as api from "../api/index.js";

// Thunk to fetch restaurants from the API || Conditional fetching based on vendorId
export const fetchRestaurants =
  (vendorId, isSuperAdmin = false) =>
  async (dispatch) => {
    dispatch({ type: FETCH_RESTAURANTS_REQUEST });
    try {
      const response = await api.apiFetchRestaurants(vendorId, isSuperAdmin);
      dispatch({
        type: FETCH_RESTAURANTS_SUCCESS,
        payload: response.data.restaurants,
      });
    } catch (error) {
      dispatch({ type: FETCH_RESTAURANTS_FAILURE, payload: error.message });
    }
  };

// Thunk to update a restaurant
export const updateRestaurant = (id, updatedData) => async (dispatch) => {
  dispatch({ type: UPDATE_RESTAURANT_REQUEST });
  try {
    const response = await api.apiUpdateRestaurant(id, updatedData);
    dispatch({ type: UPDATE_RESTAURANT_SUCCESS, payload: response.data.data });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: UPDATE_RESTAURANT_FAILURE, payload: error.message });
  }
};

// Thunk to delete a restaurant
export const deleteRestaurant = (restaurantId) => async (dispatch) => {
  dispatch({ type: DELETE_RESTAURANT_REQUEST });
  try {
    const response = await api.apiDeleteRestaurant(restaurantId);
    dispatch({
      type: DELETE_RESTAURANT_SUCCESS,
      payload: response.data.restaurants,
    });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: DELETE_RESTAURANT_FAILURE, payload: error.message });
    toast.error(
      `Failed to delete restaurant: ${
        error.response?.data?.message || error.message
      }`
    );
  }
};

// Thunk to delete a restaurant product
export const deleteRestaurantProduct = (productId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_RESTAURANT_PRODUCT_REQUEST });
    const response = await api.apiDeleteRestaurantProduct(productId);
    // console.log(response, "res>>>>>")
    dispatch({ type: DELETE_RESTAURANT_SUCCESS_PRODUCT, payload: productId });
    toast.success(response.data.message);
    dispatch({ type: END_LOADING });
  } catch (error) {
    dispatch({
      type: DELETE_RESTAURANT_PRODUCT_FAILURE,
      payload: error.message,
    });
  }
};

export const attachStore = (storeData) => async (dispatch) => {
  dispatch({ type: ATTACH_STORE_REQUEST });
  try {
    const response = await api.apiAttachStore(storeData);
    dispatch({ type: ATTACH_STORE_SUCCESS, payload: response.data.data });
    toast.success("Store attached successfully!");
  } catch (error) {
    dispatch({ type: ATTACH_STORE_FAILURE, payload: error.message });
    toast.error(error.response?.data?.message || "Failed to attach store");
  }
};
