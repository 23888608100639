import { toast } from "react-toastify";
import * as api from "../api";
import {
  FETCH_OUTLETS_REQUEST,
  FETCH_OUTLETS_SUCCESS,
  FETCH_OUTLETS_FAILURE,
  CREATE_OUTLET,
  DELETE_OUTLET_SUCCESS,
  UPDATE_OUTLET_FAILURE,
  DELETE_OUTLET_FAILURE,
  UPDATE_OUTLET_SUCCESS,
  UPDATE_OUTLET_MENU_ITEMS_REQUEST,
  UPDATE_OUTLET_MENU_ITEMS_SUCCESS,
  UPDATE_OUTLET_MENU_ITEMS_FAILURE,
  TOGGLE_OUTLET_ACTIVATION_REQUEST,
  TOGGLE_OUTLET_ACTIVATION_SUCCESS,
  TOGGLE_OUTLET_ACTIVATION_FAILURE,
} from "../constants/actionTypes";

export const fetchOutlets = (restaurantId) => async (dispatch) => {
  dispatch({ type: FETCH_OUTLETS_REQUEST });
  try {
    const { data } = await api.apiFetchOutlets(restaurantId);
    dispatch({ type: FETCH_OUTLETS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_OUTLETS_FAILURE, payload: error.message });
    toast.error("Failed to fetch outlets");
  }
};

export const createOutlet = (outletData) => async (dispatch) => {
  try {
    const { data } = await api.apiCreateOutlet(outletData);
    dispatch({ type: CREATE_OUTLET, payload: data });
    toast.success("Outlet created successfully");
  } catch (error) {
    toast.error("Failed to create outlet");
  }
};

export const updateOutlet = (outletId, updatedData) => async (dispatch) => {
  try {
    const { data } = await api.apiUpdateOutlet(outletId, updatedData);
    dispatch({ type: UPDATE_OUTLET_SUCCESS, payload: data.data });
    toast.success("Outlet updated successfully");
  } catch (error) {
    dispatch({ type: UPDATE_OUTLET_FAILURE, payload: error.message });
    toast.error("Failed to update outlet");
  }
};

export const deleteOutlet = (outletId) => async (dispatch) => {
  try {
    await api.apiDeleteOutlet(outletId);
    dispatch({ type: DELETE_OUTLET_SUCCESS, payload: outletId });
    toast.success("Outlet deleted successfully");
  } catch (error) {
    dispatch({ type: DELETE_OUTLET_FAILURE, payload: error.message });
    toast.error("Failed to delete outlet");
  }
};

export const updateOutletMenuItems =
  (outletId, menuItems) => async (dispatch) => {
    dispatch({ type: UPDATE_OUTLET_MENU_ITEMS_REQUEST });
    try {
      const { data } = await api.apiUpdateOutletMenuItems(outletId, menuItems);
      dispatch({
        type: UPDATE_OUTLET_MENU_ITEMS_SUCCESS,
        payload: data.data,
      });
      toast.success("Menu items updated successfully");
    } catch (error) {
      dispatch({
        type: UPDATE_OUTLET_MENU_ITEMS_FAILURE,
        payload: error.message,
      });
      toast.error("Failed to update menu items");
    }
  };

export const toggleOutletActivation =
  (outletId, isActive) => async (dispatch) => {
    dispatch({ type: TOGGLE_OUTLET_ACTIVATION_REQUEST });
    try {
      const { data } = await api.apiToggleOutletActivation(outletId, isActive);

      dispatch({
        type: TOGGLE_OUTLET_ACTIVATION_SUCCESS,
        payload: data.data,
      });

      // Also dispatch an update to the restaurant reducer
      dispatch({
        type: "UPDATE_RESTAURANT_OUTLET",
        payload: data.data,
      });

      return data;
    } catch (error) {
      console.error("Toggle activation error:", error);
      dispatch({
        type: TOGGLE_OUTLET_ACTIVATION_FAILURE,
        payload: error.message,
      });
      throw error;
    }
  };
