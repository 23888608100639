import React, { useState } from "react";
import { Card, Row, Col, Badge, Button, Modal, Form } from "react-bootstrap";
import {
  FaMapMarkerAlt,
  FaRoute,
  FaClock,
  FaStore,
  FaUtensils,
  FaCheckCircle,
  FaArrowRight,
  FaArrowLeft,
  FaExchangeAlt,
  FaPowerOff,
} from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { toast } from "react-toastify";

import "./OutletManager.css";
import { useDispatch } from "react-redux";
import { updateOutletMenuItems } from "../actions/outletActions";
import { toggleOutletActivation } from "../actions/outletActions";

const OutletManager = ({ restaurant, readOnly = false }) => {
  const dispatch = useDispatch();
  const outlets = restaurant?.outlets || [];
  const [showMenuItemsModal, setShowMenuItemsModal] = useState(false);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [availableItems, setAvailableItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [originalMenuItems, setOriginalMenuItems] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [outletToToggle, setOutletToToggle] = useState(null);

  const handleManageMenuItems = (outlet) => {
    setSelectedOutlet(outlet);

    // Get all menu items from all sections
    const allMenuItems = [];

    console.log("Sections:", restaurant.sections);

    restaurant.sections?.forEach((section) => {
      console.log("Processing section:", section.title, section.products);

      if (section.products && section.products.length > 0) {
        section.products.forEach((product) => {
          if (product._id && product.name) {
            allMenuItems.push({
              _id: product._id,
              name: product.name,
              sectionName: section.title,
              price: product.price || 0,
              image: product.image || placeholderImage,
              description: product.description,
              enabled: product.enabled,
            });
          }
        });
      }
    });

    console.log("All menu items:", allMenuItems);

    const availableMenuItems = allMenuItems.filter(
      (item) => !(outlet.menuItems || []).includes(item._id)
    );

    console.log("Available items:", availableMenuItems);
    console.log("Current outlet menu items:", outlet.menuItems);

    setAvailableItems(availableMenuItems);
    const currentSelectedItems = allMenuItems.filter((item) =>
      (outlet.menuItems || []).includes(item._id)
    );
    setSelectedItems(currentSelectedItems);

    // Store original menu items for comparison
    setOriginalMenuItems(outlet.menuItems || []);
    setHasChanges(false);
    setShowMenuItemsModal(true);
  };

  const moveToSelected = (item) => {
    setSelectedItems([...selectedItems, item]);
    setAvailableItems(availableItems.filter((i) => i._id !== item._id));
    checkForChanges([...selectedItems, item]);
  };

  const moveToAvailable = (item) => {
    setAvailableItems([...availableItems, item]);
    setSelectedItems(selectedItems.filter((i) => i._id !== item._id));
    checkForChanges(selectedItems.filter((i) => i._id !== item._id));
  };

  const checkForChanges = (currentSelectedItems) => {
    const currentIds = currentSelectedItems
      .map((item) => item._id)
      .sort()
      .join(",");
    const originalIds = originalMenuItems.sort().join(",");
    setHasChanges(currentIds !== originalIds);
  };

  const handleSaveMenuItems = async () => {
    setIsSaving(true);
    try {
      const menuItemIds = selectedItems.map((item) => item._id);
      const result = await dispatch(
        updateOutletMenuItems(selectedOutlet._id, menuItemIds)
      );

      // Update the local outlets state with the new menu items
      const updatedOutlets = outlets.map((outlet) =>
        outlet._id === selectedOutlet._id
          ? { ...outlet, menuItems: menuItemIds }
          : outlet
      );

      // Update the restaurant object with the new outlets
      if (restaurant && restaurant.outlets) {
        restaurant.outlets = updatedOutlets;
      }

      setShowMenuItemsModal(false);
    } catch (error) {
      console.error("Error saving menu items:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const moveAllToSelected = () => {
    setSelectedItems([...selectedItems, ...availableItems]);
    setAvailableItems([]);
    checkForChanges([...selectedItems, ...availableItems]);
  };

  const moveAllToAvailable = () => {
    setAvailableItems([...availableItems, ...selectedItems]);
    setSelectedItems([]);
    checkForChanges([]);
  };

  const handleToggleActivation = (outlet) => {
    setOutletToToggle(outlet);
    setShowConfirmModal(true);
  };

  const confirmToggleActivation = async () => {
    if (!outletToToggle) return;

    const newStatus = !outletToToggle.isActive;
    try {
      const result = await dispatch(
        toggleOutletActivation(outletToToggle._id, newStatus)
      );

      toast.success(
        `Store ${newStatus ? "activated" : "deactivated"} successfully`
      );
    } catch (error) {
      console.error("Error toggling outlet activation:", error);
      toast.error("Failed to update store status");
    } finally {
      setShowConfirmModal(false);
      setOutletToToggle(null);
    }
  };

  return (
    <>
      <Card className="mt-3 shadow-lg">
        <Card.Header className="bg-white border-bottom d-flex justify-content-between align-items-center py-3">
          <div>
            <h4 className="mb-0">
              <FaStore className="me-2 text-primary" />
              {restaurant.name} - Outlets
            </h4>
            <small className="text-muted">
              Managing {outlets.length} outlet{outlets.length !== 1 ? "s" : ""}
            </small>
          </div>
        </Card.Header>
        <Card.Body className="bg-light">
          <Row className="g-4">
            {outlets.length > 0 ? (
              outlets.map((outlet) => (
                <Col md={4} key={outlet._id}>
                  <Card className="h-100 border-1 shadow-sm hover-shadow">
                    <Card.Body>
                      <Card.Title className="d-flex justify-content-between align-items-center mb-3">
                        <span className="outlet-name">{outlet.name}</span>
                        <Form.Check
                          type="switch"
                          id={`outlet-switch-${outlet._id}`}
                          checked={outlet.isActive}
                          onChange={() => handleToggleActivation(outlet)}
                          className="outlet-switch"
                          disabled={isSaving}
                        />
                      </Card.Title>
                      <div className="outlet-details">
                        <div className="mb-2 d-flex align-items-center text-muted">
                          <FaMapMarkerAlt className="me-2 text-danger" />
                          <span className="small">{outlet.address}</span>
                        </div>
                        <div className="mb-2 d-flex align-items-center text-muted">
                          <FaRoute className="me-2 text-success" />
                          <span className="small">
                            Delivery Radius: {outlet.deliveryZoneRadius} km
                          </span>
                        </div>
                        <div className="mb-2 d-flex align-items-center text-muted">
                          <FaUtensils className="me-2 text-warning" />
                          <span className="menu-items-count d-flex align-items-center gap-1">
                            <MdVerified className="check-icon" />
                            Menu Items:
                            <span className="count-badge">
                              {outlet.menuItems?.length || 0}
                            </span>
                          </span>
                        </div>
                        <div className="mb-2 d-flex align-items-center text-muted">
                          <FaClock className="me-2 text-info" />
                          <span className="small">
                            Added:{" "}
                            {new Date(outlet.createdAt).toLocaleDateString()}
                          </span>
                        </div>
                        <div className="d-flex align-items-center text-muted">
                          <FaPowerOff
                            className={`me-2 ${
                              outlet.isActive ? "text-success" : "text-danger"
                            }`}
                          />
                          <Badge
                            bg={outlet.isActive ? "success" : "danger"}
                            className="status-badge"
                            pill
                          >
                            {outlet.isActive ? "Active" : "Deactivated"}
                          </Badge>
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0">
                      <div className="d-grid">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => handleManageMenuItems(outlet)}
                          className="custom-manage-btn"
                        >
                          <FaUtensils className="me-2" />
                          Manage Menu Items
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>
                </Col>
              ))
            ) : (
              <Col>
                <Card className="border-0 shadow-sm">
                  <Card.Body className="text-center py-5">
                    <FaStore className="text-muted mb-3" size={40} />
                    <h5 className="text-muted">No outlets available</h5>
                    <p className="text-muted mb-0">
                      Contact support to add outlets for {restaurant.name}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>

      <Modal
        show={showMenuItemsModal}
        onHide={() => setShowMenuItemsModal(false)}
        size="lg"
        className="menu-management-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <FaUtensils className="me-2 text-primary" />
            Manage Menu Items - {selectedOutlet?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={5}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0 d-flex align-items-center">
                  Available Items
                  <span className="item-count ms-2">
                    {availableItems.length} items
                  </span>
                </h5>
              </div>
              <div className="menu-list">
                {availableItems.length > 0 ? (
                  availableItems.map((item) => (
                    <div
                      key={item._id}
                      className={`menu-item ${isSaving ? "disabled" : ""}`}
                      onClick={() => !isSaving && moveToSelected(item)}
                    >
                      <div className="d-flex align-items-center p-2 border rounded mb-2 hover-item">
                        <img
                          src={item.image || placeholderImage}
                          alt={item.name}
                          className="menu-item-image"
                        />
                        <div className="ms-3 flex-grow-1">
                          <div className="product-name">{item.name}</div>
                          <small className="text-muted d-block">
                            {item.sectionName}
                          </small>
                          <div className="text-primary">
                            {new Intl.NumberFormat("en-UG", {
                              style: "currency",
                              currency: "UGX",
                            }).format(item.price)}
                          </div>
                        </div>
                        <FaArrowRight className="ms-3 text-primary" />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-muted p-3">
                    <p>No available menu items</p>
                    <small>
                      All items have been selected or no items exist
                    </small>
                  </div>
                )}
              </div>
            </Col>
            <Col
              md={2}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              {/* Bulk Add Arrow */}
              <div
                className="bulk-action"
                onClick={() => !isSaving && moveAllToSelected()}
              >
                <FaArrowRight
                  className={`text-primary mb-2 ${
                    isSaving ? "text-muted" : ""
                  }`}
                  size={24}
                />
                <p
                  className={`text-muted small mb-3 ${
                    isSaving ? "opacity-50" : ""
                  }`}
                >
                  Add All
                </p>
              </div>

              {/* Middle Section */}
              <div className="middle-section">
                <FaExchangeAlt className="text-muted mb-2" size={20} />
                <p className="text-muted small mb-0">
                  Click on items to move them between lists
                </p>
              </div>

              {/* Bulk Remove Arrow */}
              <div
                className="bulk-action"
                onClick={() => !isSaving && moveAllToAvailable()}
              >
                <FaArrowLeft
                  className={`text-danger mt-3 ${isSaving ? "text-muted" : ""}`}
                  size={24}
                />
                <p
                  className={`text-muted small ${isSaving ? "opacity-50" : ""}`}
                >
                  Remove All
                </p>
              </div>
            </Col>
            <Col md={5}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0 d-flex align-items-center">
                  Selected Items
                  <span className="item-count ms-2">
                    {selectedItems.length} items
                  </span>
                </h5>
              </div>
              <div className="menu-list">
                {selectedItems.map((item) => (
                  <div
                    key={item._id}
                    className={`menu-item ${isSaving ? "disabled" : ""}`}
                    onClick={() => !isSaving && moveToAvailable(item)}
                  >
                    <div className="d-flex align-items-center p-2 border rounded mb-2 hover-item">
                      <FaArrowLeft className="me-2 text-danger" />
                      <img
                        src={item.image || placeholderImage}
                        alt={item.name}
                        className="menu-item-image"
                      />
                      <div className="ms-3">
                        <div className="product-name">{item.name}</div>
                        <small className="text-muted">{item.sectionName}</small>
                        <div className="text-primary">
                          {new Intl.NumberFormat("en-UG", {
                            style: "currency",
                            currency: "UGX",
                          }).format(item.price)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button
            variant="light"
            onClick={() => setShowMenuItemsModal(false)}
            className="px-4"
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveMenuItems}
            className={`px-4 ${isSaving || !hasChanges ? "btn-saving" : ""}`}
            disabled={isSaving || !hasChanges}
          >
            {isSaving ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="saving-text">Saving...</span>
              </>
            ) : (
              "Save Changes"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to{" "}
            {outletToToggle?.isActive ? "deactivate" : "activate"}{" "}
            {outletToToggle?.name}?
          </p>
          {outletToToggle?.isActive ? (
            <div className="alert-dialog-deactivate" role="alert">
              <small>
                ⚠️ Deactivating this outlet will make it unavailable for
                customers to place orders!
              </small>
            </div>
          ) : (
            <div className="alert-dialog-activate" role="alert">
              <small>
                ✅ Activating this outlet will make it available for customers
                to place orders!
              </small>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button
            variant={outletToToggle?.isActive ? "danger" : "success"}
            onClick={confirmToggleActivation}
          >
            {outletToToggle?.isActive ? "Deactivate" : "Activate"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OutletManager;
