import React, { useEffect, useState } from "react";
import { Card, Table, Button, Form, Spinner } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { FaCodeBranch } from "react-icons/fa6";
import { Divider, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRestaurants,
  updateRestaurant,
  deleteRestaurant,
  attachStore,
} from "../actions/restaurantActions.js";
import EditRestaurantForm from "./EditRestaurantForm.js";
import restaurantPlaceholderImage from "../assets/img/restaurantimage_unavailable.png";
import { getUserFromLocalStorage } from "utilities/user.js";
import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import { updateOutlet, deleteOutlet } from "../actions/outletActions.js";
import { useGoogleMaps } from "../GoogleMapsContext";
import { toast } from "react-toastify";
import LocationAutocomplete from "./LocationAutoComplete.js";

const useStyles = makeStyles((theme) => ({
  switchBase: {
    color: theme.palette.secondary.main,
    "&$checked": {
      color: theme.palette.secondary.main,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  checked: {},
  track: {},
}));

const Restaurants = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, restaurants, error } = useSelector(
    (state) => state.restaurants
  );
  const { mapsApiLoaded } = useGoogleMaps();

  const [loadingSuggestions, setLoadingSuggestions] = useState(false);

  function useCustomPlacesAutocomplete(mapsApiLoaded, retry) {
    const options = {
      requestOptions: {
        componentRestrictions: { country: "ug" },
        cacheTimeout: 10 * 60 * 60 * 24,
        debounce: 100,
        location: { lat: () => 0.347596, lng: () => 32.58252 },
        radius: 200 * 1000,
        origin: { lat: 0.3148136, lng: 32.5788721 },
        strictBounds: true,
        types: ["establishment"],
      },
      debounce: 100,
    };

    const {
      value,
      ready,
      suggestions: { data } = { data: [] },
      setValue,
      clearSuggestions,
      status,
    } = usePlacesAutocomplete(options);

    useEffect(() => {
      if (mapsApiLoaded) {
        setValue("");
      }
    }, [mapsApiLoaded, retry]);

    useEffect(() => {
      if (status === "ERROR") {
        toast.error("Failed to load Google Maps API", status);
        console.error("Error fetching suggestions:", status);
      }
    }, [status]);

    return {
      value,
      ready,
      data,
      setValue,
      clearSuggestions,
    };
  }

  const { value, ready, data, setValue, clearSuggestions } =
    useCustomPlacesAutocomplete(mapsApiLoaded);

  // Hook for loading suggestions
  useEffect(() => {
    if (data && data.length > 0) {
      setLoadingSuggestions(false);
    }
  }, [data]);

  const [editingRestaurant, setEditingRestaurant] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [restaurantToDelete, setRestaurantToDelete] = useState(null);
  const [showAttachStoreModal, setShowAttachStoreModal] = useState(false);
  const [storeDetails, setStoreDetails] = useState({
    name: "",
    location: "",
    latitude: null,
    longitude: null,
    deliveryZoneRadius: 5,
  });
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [editingOutlet, setEditingOutlet] = useState(null);
  const [showEditOutletModal, setShowEditOutletModal] = useState(false);
  const [showDeleteOutletModal, setShowDeleteOutletModal] = useState(false);
  const [outletToDelete, setOutletToDelete] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = getUserFromLocalStorage();
  const adminId = user?.result?._id;
  const isSuperAdmin =
    user?.result?.userType === "LUSUKU STAFF" &&
    user?.result?.user_role === "SUPERUSER";

  useEffect(() => {
    dispatch(fetchRestaurants(adminId, isSuperAdmin));
  }, [dispatch, adminId, isSuperAdmin]);

  const handleRetry = () => {
    dispatch(fetchRestaurants(adminId, isSuperAdmin));
  };

  const handleDeleteRestaurant = (id) => {
    if (isSuperAdmin) {
      setRestaurantToDelete(id);
      setShowDeleteModal(true);
    }
  };

  const confirmDeleteRestaurant = () => {
    if (restaurantToDelete) {
      dispatch(deleteRestaurant(restaurantToDelete));
      setShowDeleteModal(false);
    }
  };

  const handleEditClick = (restaurant) => {
    setEditingRestaurant(restaurant);
  };

  const handleSave = (updatedRestaurant) => {
    dispatch(updateRestaurant(updatedRestaurant._id, updatedRestaurant));
    setEditingRestaurant(null);
  };

  const handleCancel = () => {
    setEditingRestaurant(null);
  };

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const handleAttachStoreClick = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setShowAttachStoreModal(true);
  };

  const handleStoreDetailsChange = (e) => {
    const { name, value } = e.target;
    setStoreDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleLocationSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      setStoreDetails((prev) => ({
        ...prev,
        location: address,
        latitude: latLng.lat,
        longitude: latLng.lng,
      }));
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error getting location coordinates");
    }
  };

  const handleLocationChange = (address) => {
    setStoreDetails((prev) => ({
      ...prev,
      location: address,
    }));
  };

  const handleAttachStore = async () => {
    try {
      if (!selectedRestaurant) {
        toast.error("Please select a restaurant first");
        return;
      }

      if (
        !storeDetails.name ||
        !storeDetails.location ||
        !storeDetails.deliveryZoneRadius
      ) {
        toast.error("Please fill in all required fields");
        return;
      }

      setIsSubmitting(true);

      const payload = {
        name: storeDetails.name,
        location: storeDetails.location,
        latitude: storeDetails.latitude,
        longitude: storeDetails.longitude,
        deliveryZoneRadius: Number(storeDetails.deliveryZoneRadius),
        restaurantId: selectedRestaurant._id,
      };

      // Wait for the dispatch to complete
      await dispatch(attachStore(payload));

      // If we reach here, it means the dispatch was successful
      toast.success("Store attached successfully!");

      // Clear form
      setStoreDetails({
        name: "",
        location: "",
        latitude: null,
        longitude: null,
        deliveryZoneRadius: 5,
      });

      // Clear location input and suggestions
      setValue("");
      clearSuggestions();

      // Close modal
      setShowAttachStoreModal(false);

      // Refresh restaurants list
      dispatch(fetchRestaurants(adminId, isSuperAdmin));
    } catch (error) {
      console.error("Error attaching store:", error);
      toast.error(error.message || "Error attaching store");
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleAttachStoreModal = () => {
    if (showAttachStoreModal) {
      setStoreDetails({
        name: "",
        location: "",
        latitude: null,
        longitude: null,
        deliveryZoneRadius: 5,
      });
      setValue("");
      clearSuggestions();
    }
    setShowAttachStoreModal(!showAttachStoreModal);
  };

  const handleEditOutlet = (outlet) => {
    setEditingOutlet(outlet);
    setShowEditOutletModal(true);
  };

  const handleDeleteOutlet = (outlet) => {
    setOutletToDelete(outlet);
    setShowDeleteOutletModal(true);
  };

  const confirmDeleteOutlet = () => {
    if (outletToDelete) {
      dispatch(deleteOutlet(outletToDelete._id));
      setShowDeleteOutletModal(false);
      setOutletToDelete(null);
    }
  };

  const handleUpdateOutlet = (updatedData) => {
    dispatch(updateOutlet(editingOutlet._id, updatedData));
    setShowEditOutletModal(false);
    setEditingOutlet(null);
  };

  const handleSelect = async (address) => {
    console.log("Address selected:", address);
    setValue(address, false);
    clearSuggestions();

    try {
      // First get the geocode results
      const results = await getGeocode({
        address,
        componentRestrictions: { country: "ug" },
        region: "ug",
      });

      // console.log("Geocode results:", results);

      if (results && results.length > 0) {
        // Get the lat/lng
        const { lat, lng } = await getLatLng(results[0]);
        // console.log("Location coordinates:", { lat, lng });

        // Get additional place details
        const placeId = results[0].place_id;
        // console.log("Place ID:", placeId);

        setStoreDetails((prev) => ({
          ...prev,
          location: address,
          latitude: lat,
          longitude: lng,
          place_id: placeId,
        }));
      } else {
        throw new Error("No results found");
      }
    } catch (error) {
      console.error("Geocoding error details:", error);
      toast.error(
        "Error getting location coordinates. Please try selecting a different address."
      );
    }
  };

  let content;

  if (loading) {
    content = <div>Loading...</div>;
  } else if (error) {
    content = (
      <div>
        {error}
        <Button
          onClick={handleRetry}
          color="primary"
          style={{ marginLeft: "10px" }}
        >
          Retry!
        </Button>
      </div>
    );
  } else {
    content = (
      <Table responsive hover striped>
        <thead className="thead-dark">
          <tr>
            <th style={{ textAlign: "left", verticalAlign: "middle" }}>#</th>
            <th style={{ textAlign: "left", verticalAlign: "middle" }}>Name</th>
            <th style={{ textAlign: "center", verticalAlign: "middle" }}>
              Logo
            </th>
            <th
              style={{
                textAlign: "left",
                verticalAlign: "middle",
                width: "20%",
              }}
            >
              Location
            </th>
            <th style={{ textAlign: "left", verticalAlign: "middle" }}>
              Outlets
            </th>
            <th style={{ textAlign: "center", verticalAlign: "middle" }}>
              Activation
            </th>
            <th style={{ textAlign: "left", verticalAlign: "middle" }}>ID</th>
            <th style={{ textAlign: "center", verticalAlign: "middle" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {restaurants.map((restaurant, index) => (
            <tr key={restaurant._id}>
              <td
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  padding: "15px",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  padding: "15px",
                }}
              >
                {restaurant.name}
              </td>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  padding: "15px",
                }}
              >
                <img
                  src={restaurant.imageUrl || restaurantPlaceholderImage}
                  alt={restaurant.name || "restaurantPlaceholderImage"}
                  style={{
                    width: "100px",
                    height: "auto",
                    borderRadius: "25px",
                  }}
                  onError={(e) => (e.target.src = restaurantPlaceholderImage)} // Fallback to placeholder image if there's an error
                />
              </td>
              <td
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  padding: "15px",
                }}
              >
                {restaurant.address}
              </td>
              <td
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  padding: "15px",
                }}
              >
                {restaurant.outlets && restaurant.outlets.length > 0 ? (
                  <>
                    <div
                      style={{
                        marginBottom: "8px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <span
                        style={{
                          background: "#4169E1",
                          color: "white",
                          padding: "2px 8px",
                          borderRadius: "12px",
                          fontSize: "0.8em",
                        }}
                      >
                        {restaurant.outlets.length}{" "}
                        {restaurant.outlets.length === 1 ? "Store" : "Stores"}
                      </span>
                    </div>
                    <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                      {restaurant.outlets.map((outlet, idx) => (
                        <div
                          key={outlet._id}
                          style={{
                            padding: "8px 12px",
                            margin: "4px 0",
                            backgroundColor:
                              idx % 2 === 0 ? "#f8f9fa" : "#ffffff",
                            borderLeft: "3px solid #4169E1",
                            borderRadius: "4px",
                            fontSize: "0.9em",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            transition: "all 0.2s ease",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                              gap: "8px",
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <div
                                style={{
                                  fontWeight: "600",
                                  color: "#2c3e50",
                                  marginBottom: "4px",
                                }}
                              >
                                {outlet.name}
                              </div>
                              <div
                                style={{
                                  fontSize: "0.85em",
                                  color: "#666",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                <i
                                  className="fas fa-map-marker-alt"
                                  style={{ color: "#4E1", fontSize: "12px" }}
                                ></i>
                                {outlet.address}
                              </div>
                              {/* <br /> */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                  marginTop: "4px",
                                }}
                              >
                                <i
                                  className="fas fa-route"
                                  style={{ color: "#9E0001" }}
                                ></i>
                                Radius: {outlet.deliveryZoneRadius} km
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                                minWidth: "40px",
                              }}
                            >
                              <Button
                                size="sm"
                                variant="link"
                                onClick={() => handleEditOutlet(outlet)}
                                disabled={!isSuperAdmin}
                                style={{
                                  padding: "4px",
                                  color: "#4169E1",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <FaEdit />
                              </Button>
                              <Button
                                size="sm"
                                variant="link"
                                onClick={() => handleDeleteOutlet(outlet)}
                                disabled={!isSuperAdmin}
                                style={{
                                  padding: "4px",
                                  color: "#DC143C",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <FaTrash />
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      color: "#666",
                      fontStyle: "italic",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <i
                      className="fas fa-store-slash"
                      style={{ color: "#999" }}
                    ></i>
                    No stores yet
                  </div>
                )}
              </td>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  padding: "15px",
                }}
              >
                <Switch
                  checked={restaurant.isActive}
                  disabled
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.checked,
                    track: classes.track,
                  }}
                />
              </td>
              <td
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  padding: "15px",
                }}
              >
                {restaurant._id}
              </td>
              <td
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  padding: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "5px",
                  cursor: isSuperAdmin ? "pointer" : "not-allowed",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="primary"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#4169E1",
                      border: "none",
                      width: "100%",
                    }}
                    onClick={() => handleEditClick(restaurant)}
                  >
                    <FaEdit style={{ marginRight: "5px" }} /> Edit
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: !isSuperAdmin ? "not-allowed" : "pointer",
                  }}
                >
                  <Button
                    variant="danger"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: !isSuperAdmin ? "#fff" : "#DC143C",
                      border: !isSuperAdmin ? "1px solid #DC143C" : "none",
                      color: !isSuperAdmin ? "#DC143C" : "#fff",
                      width: "100%",
                    }}
                    onClick={() => handleDeleteRestaurant(restaurant.owner)}
                    disabled={!isSuperAdmin}
                  >
                    <FaTrash
                      style={{
                        marginRight: "5px",
                        color: !isSuperAdmin ? "#DC143C" : "#fff",
                      }}
                    />{" "}
                    Delete
                  </Button>
                </div>
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#4B0082",
                    border: "none",
                    width: "100%",
                  }}
                  onClick={() => handleAttachStoreClick(restaurant)}
                  disabled={!isSuperAdmin}
                >
                  <FaCodeBranch style={{ marginRight: "5px" }} />
                  Attach Store
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <div style={{ padding: "20px" }}>
      <Card
        style={{
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Card.Header
          style={{
            backgroundColor: "#343a40",
            color: "#fff",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            textAlign: "center",
          }}
        >
          <h2>Restaurants</h2>
        </Card.Header>
        <Card.Body>
          {editingRestaurant ? (
            <EditRestaurantForm
              restaurant={editingRestaurant}
              onSave={handleSave}
              onCancel={handleCancel}
            />
          ) : (
            <>{content}</>
          )}
        </Card.Body>
      </Card>
      <Modal show={showAttachStoreModal} onHide={toggleAttachStoreModal}>
        <Modal.Header closeButton>
          <Modal.Title>Attach Store to {selectedRestaurant?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="storeName">
              <Form.Label>Store Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter store name"
                name="name"
                value={storeDetails.name}
                onChange={handleStoreDetailsChange}
              />
            </Form.Group>
            <Form.Group controlId="storeLocation">
              <Form.Label>Store Location</Form.Label>
              <LocationAutocomplete
                value={value}
                setValue={setValue}
                ready={ready}
                data={data}
                clearSuggestions={clearSuggestions}
                mapsApiLoaded={mapsApiLoaded}
                loadingSuggestions={loadingSuggestions}
                defaultValue={editingOutlet?.address}
                onLocationSelect={({ address, latitude, longitude }) => {
                  setStoreDetails((prev) => ({
                    ...prev,
                    location: address,
                    latitude,
                    longitude,
                  }));
                }}
              />
            </Form.Group>
            <Divider style={{ margin: "10px 0" }} />

            <Form.Group controlId="storeRadius" style={{ marginTop: "15px" }}>
              <Form.Label>Delivery Zone Radius (km)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Delivery Radius in Kilometers!"
                name="deliveryZoneRadius"
                value={storeDetails.deliveryZoneRadius}
                onChange={handleStoreDetailsChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={toggleAttachStoreModal}
            disabled={isSubmitting}
            style={{
              opacity: isSubmitting ? "0.7" : "1",
              color: "#6c757d",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleAttachStore}
            disabled={isSubmitting}
            style={{
              backgroundColor: "#4B0082",
              border: "none",
              opacity: isSubmitting ? "0.7" : "1",
              color: "#fff",
              display: "inline-flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {isSubmitting ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "#fff" }}
                />
                <span>Attaching...</span>
              </>
            ) : (
              "Attach"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} onHide={toggleDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this restaurant? This action can't be
          undone!
        </Modal.Body>
        <Modal.Footer>
          <Button color="danger" onClick={confirmDeleteRestaurant}>
            Delete
          </Button>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showEditOutletModal}
        onHide={() => setShowEditOutletModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Store</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Store Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter store name"
                defaultValue={editingOutlet?.name}
                onChange={(e) =>
                  setEditingOutlet({ ...editingOutlet, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Address</Form.Label>
              <LocationAutocomplete
                value={value}
                setValue={setValue}
                ready={ready}
                data={data}
                clearSuggestions={clearSuggestions}
                mapsApiLoaded={mapsApiLoaded}
                loadingSuggestions={loadingSuggestions}
                defaultValue={editingOutlet?.address}
                onLocationSelect={({ address, latitude, longitude }) => {
                  setEditingOutlet({
                    ...editingOutlet,
                    address,
                    latitude,
                    longitude,
                  });
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Delivery Zone Radius (km)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter radius in kilometers"
                defaultValue={editingOutlet?.deliveryZoneRadius}
                onChange={(e) =>
                  setEditingOutlet({
                    ...editingOutlet,
                    deliveryZoneRadius: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowEditOutletModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdateOutlet(editingOutlet)}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeleteOutletModal}
        onHide={() => setShowDeleteOutletModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Store</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <i
              className="fas fa-exclamation-triangle"
              style={{
                fontSize: "3em",
                color: "#DC143C",
                marginBottom: "15px",
              }}
            ></i>
            <p>Are you sure you want to delete this store?</p>
            <div
              style={{
                padding: "10px",
                margin: "15px 0",
                backgroundColor: "#f8f9fa",
                borderRadius: "4px",
                border: "1px solid #dee2e6",
              }}
            >
              <strong style={{ color: "#DC143C" }}>
                {outletToDelete?.name}
              </strong>
              <div
                style={{
                  fontSize: "0.9em",
                  color: "#666",
                  marginTop: "5px",
                }}
              >
                <i
                  className="fas fa-map-marker-alt"
                  style={{ marginRight: "5px" }}
                ></i>
                {outletToDelete?.address}
              </div>
            </div>
            <p
              style={{
                fontSize: "0.9em",
                color: "#666",
                marginTop: "10px",
              }}
            >
              This action cannot be undone.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteOutletModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={confirmDeleteOutlet}
            style={{
              backgroundColor: "#DC143C",
              border: "none",
            }}
          >
            Delete Store
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Restaurants;
