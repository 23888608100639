import { Switch } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Image,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeactivatedListings,
  reactivateSku,
} from "../../../actions/listingsActions";
import { getUserFromLocalStorage } from "utilities/user";
import BlockIcon from "@material-ui/icons/Block";

const DeactivatedListings = () => {
  const user = getUserFromLocalStorage();
  const listings = useSelector((state) => state.listings);
  const { deactivatedListings, isLoading } = listings;
  const [reactivated, setReactivated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeactivatedListings(user?.result?._id));
  }, [reactivated]);

  if (isLoading) {
    return (
      <Spinner
        animation="grow"
        variant="danger"
        style={{
          marginTop: "70px",
          top: "50%",
          left: "50%",
          position: "fixed",
        }}
      />
    );
  }

  const handleReactivation = async (listingId, userId) => {
    await dispatch(reactivateSku(listingId, userId));
    setReactivated(!reactivated);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Card.Title as="h4">Deactivated Listings</Card.Title>
                  <p className="card-category">
                    <BlockIcon
                      style={{ color: "#dc3545", fontSize: 17, marginRight: 5 }}
                    />
                    {deactivatedListings?.length || 0} Deactivated Products
                  </p>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              {deactivatedListings.length === 0 ? (
                <div className="text-center p-4">
                  <BlockIcon
                    style={{ fontSize: 50, color: "#dc3545", marginBottom: 15 }}
                  />
                  <h4>No Deactivated Listings</h4>
                  <p className="text-muted">
                    All your listings are currently active
                  </p>
                </div>
              ) : (
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Category</th>
                      <th>Stock</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deactivatedListings.map((listing) => (
                      <tr key={listing._id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <Image
                              src={listing.product_image}
                              style={{
                                width: 45,
                                height: 45,
                                borderRadius: "8px",
                                marginRight: "10px",
                                objectFit: "cover",
                              }}
                            />
                            <div>
                              <div style={{ fontWeight: "500" }}>
                                {listing.title}
                              </div>
                              <small className="text-muted">
                                {listing.description}
                              </small>
                            </div>
                          </div>
                        </td>
                        <td>UGX {listing.price?.toLocaleString()}</td>
                        <td>{listing.category}</td>
                        <td>{listing.countInStock}</td>
                        <td>
                          <span className="badge bg-danger">Deactivated</span>
                        </td>
                        <td>
                          <Switch
                            checked={false}
                            onChange={() =>
                              handleReactivation(listing._id, user?.result?._id)
                            }
                            color="primary"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DeactivatedListings;
