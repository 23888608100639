import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRestaurantVendorOrders } from "../../actions/restaurantVendorOrdersActions";
import { useHistory } from "react-router-dom";
import {
  Container,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  makeStyles,
  TablePagination,
  Button,
  Chip,
  Box,
  FormControl,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Card,
  CardContent,
  Switch,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { getUserFromLocalStorage } from "../../utilities/user.js";
import NoOrdersIcon from "../../assets/img/NoOrdersIcon.png"; // Ensure you have a suitable SVG or image
import { socket } from "../../utilities/socket";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    border: `1px solid ${theme.palette.divider}`,
  },
  container: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  error: {
    color: theme.palette.error.main,
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  tableHeadCell: {
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  pagination: {
    marginTop: theme.spacing(2),
  },
  chipProcessing: {
    backgroundColor: "#ff9800",
    color: "#fff",
  },
  chipAssigned: {
    backgroundColor: "#3f51b5",
    color: "#fff",
  },
  chipDispatched: {
    backgroundColor: "#2196f3",
    color: "#fff",
  },
  chipDelivered: {
    backgroundColor: "#4caf50",
    color: "#fff",
  },
  chipRejected: {
    backgroundColor: "#f44336",
    color: "#fff",
  },
  noOrdersContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    textAlign: "center",
  },
  noOrdersIcon: {
    width: "180px",
    height: "180px",
    marginBottom: theme.spacing(3),
    borderRadius: "10%",
  },
  filterCard: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: "0 2px 8px rgba(0,0,0,0.6)",
    borderRadius: "12px",
    transition: "box-shadow 0.2s ease",
    "&:hover": {
      boxShadow: "0 4px 16px rgba(0,0,0,0.08)",
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(3),
    padding: "12px 20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  filterTitle: {
    fontSize: "0.875rem",
    color: "#64748b",
    fontWeight: 600,
    letterSpacing: "0.5px",
  },
  formControl: {
    minWidth: 200,
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: "#f8fafc",
      },
      "&.Mui-focused": {
        boxShadow: "0 0 0 2px rgba(59, 130, 246, 0.1)",
      },
    },
  },
  checkboxGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1.5),
    overflowX: "auto",
    "& .MuiFormControlLabel-root": {
      margin: 0,
      padding: "4px 12px",
      borderRadius: "8px",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: "#f8fafc",
      },
    },
    "&::-webkit-scrollbar": {
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f5f9",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#cbd5e1",
      borderRadius: "4px",
      "&:hover": {
        background: "#94a3b8",
      },
    },
  },
  orderCount: {
    backgroundColor: "#f1f5f9",
    color: "#64748b",
    padding: "2px 8px",
    borderRadius: "12px",
    fontSize: "0.75rem",
    fontWeight: 600,
    marginLeft: "3px",
    transition: "all 0.2s ease",
  },
  selectedOrderCount: {
    backgroundColor: "#029722",
    color: "#fff",
  },
  menuItem: {
    borderRadius: "6px",
    margin: "2px 4px",
    "&.Mui-selected": {
      backgroundColor: "#f0f9ff",
      "&:hover": {
        backgroundColor: "#e0f7ff",
      },
    },
  },
  locationChip: {
    borderRadius: "6px",
    fontWeight: 500,
    transition: "all 0.2s ease",
    "&:hover": {
      transform: "translateY(-1px)",
    },
  },
  multipleSelected: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
  },
}));

const RestaurantOrderTable = () => {
  const classes = useStyles();
  const [vendorId, setVendorId] = useState(null);
  const [fetchComplete, setFetchComplete] = useState(false);
  const dispatch = useDispatch();
  const { orders, loading, error, currentPage, numberOfPages } = useSelector(
    (state) => state.restaurantVendorOrders
  );
  const history = useHistory();
  const [selectedOutlet, setSelectedOutlet] = useState("all");
  const [filters, setFilters] = useState({
    mainStore: true,
    outlets: {},
  });
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [persistFilters, setPersistFilters] = useState(() => {
    return localStorage.getItem("persistOutletFilters") === "true";
  });

  const saveFilterState = (newFilters, newSelectedOutlet) => {
    if (persistFilters) {
      localStorage.setItem("outletFilters", JSON.stringify(newFilters));
      localStorage.setItem("selectedOutlet", newSelectedOutlet);
    }
  };

  const user = getUserFromLocalStorage();

  useEffect(() => {
    if (user) {
      setVendorId(user.result._id); // Ensure this is the _id of the owner
    }
  }, [user]);

  const fetchData = async () => {
    if (vendorId) {
      await dispatch(fetchRestaurantVendorOrders(vendorId, currentPage));
      setFetchComplete(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, vendorId, currentPage]);

  const getChipClass = (status) => {
    switch (status) {
      case "PROCESSING":
        return classes.chipProcessing;
      case "ASSIGNED":
        return classes.chipAssigned;
      case "DISPATCHED":
        return classes.chipDispatched;
      case "DELIVERED":
        return classes.chipDelivered;
      case "REJECTED":
        return classes.chipRejected;
      default:
        return "";
    }
  };

  const getUniqueOutlets = () => {
    if (!orders) return [];

    const uniqueOutlets = new Set();
    orders.forEach((order) => {
      order.orderItems.forEach((item) => {
        if (item.isOutletOrder && item.outletId?.name) {
          uniqueOutlets.add(
            JSON.stringify({
              _id: item.outletId._id,
              name: item.outletId.name,
            })
          );
        }
      });
    });

    return Array.from(uniqueOutlets).map((outlet) => JSON.parse(outlet));
  };

  const filterOrders = () => {
    if (!orders || selectedOutlet === "") return [];

    return orders.filter((order) => {
      // Check if any order item matches our filter criteria
      return order.orderItems.some((item) => {
        if (selectedOutlet === "all") {
          // For "all" selection, check if the item's location is enabled in filters
          if (item.isOutletOrder) {
            return filters[item.outletId?._id] || false;
          } else {
            return filters.mainStore;
          }
        } else {
          // For specific outlet selections, check against the filters directly
          if (item.isOutletOrder) {
            return filters[item.outletId?._id];
          } else {
            return filters.mainStore;
          }
        }
      });
    });
  };

  useEffect(() => {
    setFilteredOrders(filterOrders());
  }, [orders, selectedOutlet, filters]);

  const handleFilterChange = (event) => {
    const { name, checked } = event.target;

    const newFilters = {
      ...filters,
      [name]: checked,
    };

    // Check if all checkboxes will be unchecked
    const willHaveCheckedBoxes = Object.values(newFilters).some(
      (value) => value
    );

    if (!willHaveCheckedBoxes) {
      // If no checkboxes will be checked, clear the dropdown selection
      setSelectedOutlet("");
    } else {
      // Count total possible checkboxes and how many are checked
      const totalOutlets = getUniqueOutlets().length + 1; // +1 for main store
      const checkedFilters = Object.entries(newFilters).filter(
        ([_, value]) => value
      );

      if (checkedFilters.length === totalOutlets) {
        // Only set to "all" if ALL checkboxes are checked
        setSelectedOutlet("all");
      } else if (checkedFilters.length === 1) {
        // If only one checkbox is checked, set dropdown to that option
        const [checkedName] = checkedFilters[0];
        setSelectedOutlet(checkedName);
      } else {
        // If multiple (but not all) checkboxes are checked, show first checked option
        const [firstChecked] = checkedFilters;
        setSelectedOutlet(firstChecked[0]);
      }
    }

    setFilters(newFilters);
    saveFilterState(newFilters, selectedOutlet);
  };

  const handleOutletChange = (event) => {
    const newSelectedOutlet = event.target.value;
    setSelectedOutlet(newSelectedOutlet);

    // Update checkboxes based on dropdown selection
    const uniqueOutlets = getUniqueOutlets();
    let newFilters;

    if (newSelectedOutlet === "all") {
      // When "All Locations" is selected, check all checkboxes
      newFilters = {
        mainStore: true,
        ...uniqueOutlets.reduce(
          (acc, outlet) => ({
            ...acc,
            [outlet._id]: true,
          }),
          {}
        ),
      };
    } else {
      // When a specific location is selected, only check that checkbox
      newFilters = {
        mainStore: false,
        ...uniqueOutlets.reduce(
          (acc, outlet) => ({
            ...acc,
            [outlet._id]: false,
          }),
          {}
        ),
      };

      if (newSelectedOutlet === "mainStore") {
        newFilters.mainStore = true;
      } else {
        newFilters[newSelectedOutlet] = true;
      }
    }

    setFilters(newFilters);
    saveFilterState(newFilters, newSelectedOutlet);
  };

  useEffect(() => {
    const uniqueOutlets = getUniqueOutlets();
    if (persistFilters) {
      const savedFilters = localStorage.getItem("outletFilters");
      const savedOutlet = localStorage.getItem("selectedOutlet");

      if (savedFilters) {
        setFilters(JSON.parse(savedFilters));
        setSelectedOutlet(savedOutlet);
      } else {
        setFilters({
          mainStore: true,
          ...uniqueOutlets.reduce(
            (acc, outlet) => ({
              ...acc,
              [outlet._id]: true,
            }),
            {}
          ),
        });
      }
    } else {
      setFilters({
        mainStore: true,
        ...uniqueOutlets.reduce(
          (acc, outlet) => ({
            ...acc,
            [outlet._id]: true,
          }),
          {}
        ),
      });
    }
  }, [orders]);

  const getStoreCounts = () => {
    if (!orders) return {};

    const counts = {
      mainStore: 0,
    };

    orders.forEach((order) => {
      // Only count orders with PROCESSING status
      if (order.delivery_status === "PROCESSING") {
        order.orderItems.forEach((item) => {
          if (item.isOutletOrder && item.outletId?._id) {
            counts[item.outletId._id] = (counts[item.outletId._id] || 0) + 1;
          } else {
            counts.mainStore += 1;
          }
        });
      }
    });

    return counts;
  };

  const handlePersistToggle = (event) => {
    const newPersistState = event.target.checked;
    setPersistFilters(newPersistState);
    localStorage.setItem("persistOutletFilters", newPersistState);

    if (!newPersistState) {
      // Clear saved filters when turning off persistence
      localStorage.removeItem("outletFilters");
      localStorage.removeItem("selectedOutlet");

      // Reset to default state (all selected)
      const uniqueOutlets = getUniqueOutlets();
      const defaultFilters = {
        mainStore: true,
        ...uniqueOutlets.reduce(
          (acc, outlet) => ({
            ...acc,
            [outlet._id]: true,
          }),
          {}
        ),
      };
      setFilters(defaultFilters);
      setSelectedOutlet("all");
    } else {
      // Save current state when turning on persistence
      saveFilterState(filters, selectedOutlet);
    }
  };

  useEffect(() => {
    // Load persist preference from localStorage
    const savedPersistPreference = localStorage.getItem("persistOutletFilters");
    setPersistFilters(savedPersistPreference === "true");

    // If persistence is enabled, load saved filters
    if (savedPersistPreference === "true") {
      const savedFilters = localStorage.getItem("outletFilters");
      const savedOutlet = localStorage.getItem("selectedOutlet");

      if (savedFilters) {
        setFilters(JSON.parse(savedFilters));
        setSelectedOutlet(savedOutlet);
      }
    }
  }, []); // Empty dependency array means this runs once on component mount

  useEffect(() => {
    if (!vendorId) return;

    // Join vendor-specific room
    socket.emit("joinRoom", `restaurant:${vendorId}`);

    // Listen for new orders
    socket.on("newRestaurantOrder", (newOrder) => {
      dispatch(fetchRestaurantVendorOrders(vendorId, currentPage));
    });

    // Listen for order updates
    socket.on("orderUpdated", (updatedOrder) => {
      dispatch(fetchRestaurantVendorOrders(vendorId, currentPage));
    });

    // Listen for status changes
    socket.on("orderStatusChanged", ({ orderId, status }) => {
      dispatch(fetchRestaurantVendorOrders(vendorId, currentPage));
    });

    return () => {
      socket.off("newRestaurantOrder");
      socket.off("orderUpdated");
      socket.off("orderStatusChanged");
      socket.emit("leaveRoom", `restaurant:${vendorId}`);
    };
  }, [vendorId, currentPage, dispatch]);

  if (loading && !fetchComplete) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Alert severity="error" className={classes.error}>
          Error: {error}
        </Alert>
      </div>
    );
  }

  return (
    <Container className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          {user?.result?.businessName} Orders
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={persistFilters}
              onChange={handlePersistToggle}
              size="small"
              style={{ color: "#c2037f" }}
            />
          }
          label={
            <Typography
              variant="body2"
              style={{ fontSize: "0.875rem", color: "#3c5d8a" }}
            >
              Remember My Selections!
            </Typography>
          }
        />
      </Box>
      <Card className={classes.filterCard}>
        <div className={classes.filterContainer}>
          <Typography variant="subtitle2" className={classes.filterTitle}>
            FILTER BY:
          </Typography>

          <FormControl className={classes.formControl} size="small">
            <Select
              value={selectedOutlet}
              onChange={handleOutletChange}
              variant="outlined"
              fullWidth
              displayEmpty
              renderValue={(selected) => {
                if (!selected || selected === "") {
                  return "Select Location";
                }

                // Get all checked filters
                const checkedFilters = Object.entries(filters).filter(
                  ([_, value]) => value
                );
                const totalOutlets = getUniqueOutlets().length + 1;

                if (checkedFilters.length === totalOutlets) {
                  return "All Locations";
                }

                // Render multiple selected options
                return (
                  <div className={classes.multipleSelected}>
                    {filters.mainStore && (
                      <Chip
                        size="small"
                        label="Main Store"
                        className={classes.locationChip}
                        style={{
                          backgroundColor: "#A62B1F",
                          color: "#fff",
                          boxShadow: "0 1px 5px rgba(0,0,0,0.7)",
                        }}
                      />
                    )}
                    {getUniqueOutlets().map(
                      (outlet) =>
                        filters[outlet._id] && (
                          <Chip
                            key={outlet._id}
                            size="small"
                            label={outlet.name}
                            className={classes.locationChip}
                            style={{
                              backgroundColor: "#193C40",
                              color: "#fff",
                              boxShadow: "0 1px 8px rgba(0,0,0,0.7)",
                            }}
                          />
                        )
                    )}
                  </div>
                );
              }}
            >
              <MenuItem value="all" className={classes.menuItem}>
                <Box
                  display="flex"
                  alignItems="center"
                  //   justifyContent="space-between"
                  width="100%"
                >
                  <Chip
                    size="small"
                    label="All Locations"
                    className={classes.locationChip}
                    style={{
                      backgroundColor:
                        selectedOutlet === "all" ? "#193C40" : "#f1f5f9",
                      boxShadow:
                        selectedOutlet === "all"
                          ? "0 1px 8px rgba(0,0,0,0.7)"
                          : "0 1px 8px rgba(0,0,0,0.3)",
                      color: selectedOutlet === "all" ? "#fff" : "#64748b",
                    }}
                  />
                </Box>
              </MenuItem>
              <MenuItem value="mainStore" className={classes.menuItem}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Chip
                    size="small"
                    label="Main Store"
                    className={classes.locationChip}
                    style={{
                      backgroundColor:
                        selectedOutlet === "mainStore"
                          ? "#A62B1F"
                          : "#a62a1f83",
                      color: "#fff",
                      marginRight: "8px",
                      boxShadow: "0 1px 5px rgba(0,0,0,0.7)",
                    }}
                  />
                  <span
                    className={`${classes.orderCount} ${
                      selectedOutlet === "mainStore"
                        ? classes.selectedOrderCount
                        : ""
                    }`}
                  >
                    {getStoreCounts().mainStore || 0}
                  </span>
                </Box>
              </MenuItem>
              {getUniqueOutlets().map((outlet) => (
                <MenuItem
                  key={outlet._id}
                  value={outlet._id}
                  className={classes.menuItem}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Chip
                      size="small"
                      label={outlet.name}
                      className={classes.locationChip}
                      style={{
                        backgroundColor:
                          selectedOutlet === outlet._id ? "#193C40" : "#f1f5f9",
                        boxShadow:
                          selectedOutlet === outlet._id
                            ? "0 1px 8px rgba(0,0,0,0.7)"
                            : "0 1px 8px rgba(0,0,0,0.3)",
                        color:
                          selectedOutlet === outlet._id ? "#fff" : "#64748b",
                      }}
                    />
                    <span
                      className={`${classes.orderCount} ${
                        selectedOutlet === outlet._id
                          ? classes.selectedOrderCount
                          : ""
                      }`}
                    >
                      {getStoreCounts()[outlet._id] || 0}
                    </span>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box className={classes.checkboxGroup}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.mainStore}
                  onChange={handleFilterChange}
                  name="mainStore"
                  color="primary"
                  size="small"
                />
              }
              label={
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Typography variant="body2" style={{ fontSize: "0.875rem" }}>
                    Main Store
                  </Typography>
                  <span
                    className={`${classes.orderCount} ${
                      filters.mainStore ? classes.selectedOrderCount : ""
                    }`}
                  >
                    {getStoreCounts().mainStore || 0}
                  </span>
                </Box>
              }
            />
            {getUniqueOutlets().map((outlet) => (
              <FormControlLabel
                key={outlet._id}
                control={
                  <Checkbox
                    checked={filters[outlet._id] || false}
                    onChange={handleFilterChange}
                    name={outlet._id}
                    color="primary"
                    size="small"
                  />
                }
                label={
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body2"
                      style={{ fontSize: "0.875rem" }}
                    >
                      {outlet.name}
                    </Typography>
                    <span
                      className={`${classes.orderCount} ${
                        filters[outlet._id] ? classes.selectedOrderCount : ""
                      }`}
                    >
                      {getStoreCounts()[outlet._id] || 0}
                    </span>
                  </Box>
                }
              />
            ))}
          </Box>
        </div>
      </Card>
      {fetchComplete && orders.length === 0 ? (
        <Box className={classes.noOrdersContainer}>
          <img
            src={NoOrdersIcon}
            alt="No orders"
            className={classes.noOrdersIcon}
          />
          <Typography variant="h6" color="textSecondary">
            No orders found
          </Typography>
          <Typography variant="body1" color="textSecondary">
            You have not received any orders yet!
          </Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classes.tableHeadCell}>
                    Order ID
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>Store</TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Total Price
                  </TableCell>
                  <TableCell className={classes.tableHeadCell} align="center">
                    Payment Method
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Delivery Status
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Order Date
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders.map((order) => (
                  <TableRow key={order._id} className={classes.tableRow}>
                    <TableCell component="th" scope="row">
                      {order._id}
                    </TableCell>
                    <TableCell>
                      {order.orderItems.map((item, index) => (
                        <div key={index}>
                          {item.isOutletOrder ? (
                            <>
                              <Chip
                                size="small"
                                label={item.outletId?.name}
                                style={{
                                  backgroundColor: "#193C40",
                                  color: "#fff",
                                  marginBottom: "4px",
                                  cursor: "pointer",
                                  boxShadow: "0 1px 8px rgba(0,0,0,0.7)",
                                }}
                                title={item.outletId?.address || ""}
                              />
                              <i className="fas fa-code-branch"></i>
                            </>
                          ) : (
                            <>
                              <i className="fas fa-store"></i>
                              <Chip
                                size="small"
                                label="Main Store"
                                style={{
                                  backgroundColor: "#A62B1F",
                                  color: "#fff",
                                  marginBottom: "4px",
                                  boxShadow: "0 1px 8px rgba(0,0,0,0.7)",
                                }}
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>{order.totalPrice.toLocaleString()}</TableCell>
                    <TableCell align="center">{order.paymentMethod}</TableCell>
                    <TableCell>
                      <Chip
                        label={order.delivery_status}
                        className={getChipClass(order.delivery_status)}
                        style={{
                          boxShadow: "0 2px 8px rgba(0,0,0,0.8)",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {new Date(order.createdAt).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        style={{
                          color: "#fff",
                          backgroundColor: "#003547",
                          boxShadow: "0 1px 8px rgba(0,0,0,0.9)",
                        }}
                        size="small"
                        onClick={() =>
                          history.push(
                            `/admin/restVenOrderDetails/${order._id}`
                          )
                        }
                      >
                        Manage Order
                        <i
                          style={{ marginLeft: "5px" }}
                          className="fas fa-edit"
                        ></i>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={numberOfPages * 10}
            page={currentPage - 1}
            onPageChange={(event, newPage) =>
              dispatch(fetchRestaurantVendorOrders(vendorId, newPage + 1))
            }
            rowsPerPage={10}
            rowsPerPageOptions={[10]}
            className={classes.pagination}
          />
        </>
      )}
    </Container>
  );
};

export default RestaurantOrderTable;
