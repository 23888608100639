import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
  statusChip: {
    fontWeight: "bold",
  },
  approved: {
    backgroundColor: "#E0F7FA",
    color: "#00796B",
  },
  pending: {
    backgroundColor: "#FFF3E0",
    color: "#F57C00",
  },
  rejected: {
    backgroundColor: "#FFEBEE",
    color: "#D32F2F",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

const WhatsAppTemplates = () => {
  const classes = useStyles();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get("/api/whatsapp/templates");
        setTemplates(response.data.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  if (loading)
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className={classes.container}>
      <Typography variant="h4" gutterBottom>
        WhatsApp Templates
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Language</TableCell>
              <TableCell style={{ paddingRight: "16px", textAlign: "center" }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map((template, index) => (
              <TableRow key={template.id}>
                <TableCell>
                  #{index + 1}. {template.name}
                </TableCell>
                <TableCell>{template.category}</TableCell>
                <TableCell>{template.language}</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Chip
                    label={template.status}
                    className={`${classes.statusChip} ${
                      template.status === "APPROVED"
                        ? classes.approved
                        : template.status === "PENDING"
                        ? classes.pending
                        : classes.rejected
                    }`}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default WhatsAppTemplates;
