import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Card, CircularProgress } from "@material-ui/core";
// import ImageUploadStyledAlert from "../../views/Components/ImageUploadStyledAlert";
// import axios from "axios";

import { submitVendorRegistration } from "../../actions/vendorActions.js";
import LusukuLogo from "../../views/Components/LusukuLogo";
import { useGoogleMaps } from "../../GoogleMapsContext";
import LocationAutocomplete from "../LocationAutoComplete";
import usePlacesAutocomplete from "use-places-autocomplete";

const options = [
  { value: "RESTAURANT", label: "Restaurant" },
  { value: "RETAILER", label: "Retailer" },
  { value: "WHOLESALER", label: "Wholesaler" },
];

const validationSchema = Yup.object().shape({
  businessName: Yup.string().required("Business name is required"),
  operationMode: Yup.string().required("Vendor type is required"),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  businessAddress: Yup.string().required("Business address is required"),
  businessPhone: Yup.string().required("Business phone number is required"),
  typeOfGoods: Yup.string().required((values) =>
    values.operationMode === "RESTAURANT"
      ? "Cuisine specialty is required"
      : "Type of goods is required"
  ),
});

const initialValues = {
  businessName: "",
  email: "",
  operationMode: "",
  businessAddress: "",
  businessPhone: "",
  typeOfGoods: "",
  password: "",
  confirmPassword: "",
};

function useCustomPlacesAutocomplete(mapsApiLoaded, retry) {
  const options = {
    requestOptions: {
      componentRestrictions: { country: "ug" },
      cacheTimeout: 10 * 60 * 60 * 24,
      debounce: 100,
      location: { lat: () => 0.347596, lng: () => 32.58252 },
      radius: 200 * 1000,
      origin: { lat: 0.3148136, lng: 32.5788721 },
      strictBounds: true,
      types: ["establishment"],
    },
    debounce: 100,
  };

  const {
    value,
    ready,
    suggestions: { data } = { data: [] },
    setValue,
    clearSuggestions,
    status,
  } = usePlacesAutocomplete(options);

  useEffect(() => {
    if (mapsApiLoaded) {
      setValue("");
    }
  }, [mapsApiLoaded, retry]);

  useEffect(() => {
    if (status === "ERROR") {
      toast.error("Failed to load Google Maps API", status);
      console.error("Error fetching suggestions:", status);
    }
  }, [status]);

  return {
    value,
    ready,
    data,
    setValue,
    clearSuggestions,
  };
}

const VendorRegistrationForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { mapsApiLoaded } = useGoogleMaps();

  const { value, ready, data, setValue, clearSuggestions } =
    useCustomPlacesAutocomplete(mapsApiLoaded);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);

  const handleLocationSelect = (
    { address, latitude, longitude },
    setFieldValue
  ) => {
    setSelectedLocation({ address, latitude, longitude });
    setFieldValue("businessAddress", address);
  };

  const handleSelectChange = (setFieldValue, e) => {
    setFieldValue("operationMode", e ? e.value : "");
    setFieldValue("isRestaurant", e.value === "RESTAURANT");
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      dispatch(submitVendorRegistration(values))
        .then((data) => {
          setSubmitting(false);
          resetForm({ initialValues: {} });
        })
        .catch((error) => {
          console.error("Form submission failed:", error);
          setSubmitting(false);
          // setIsUploading(false);
        });
    } catch (error) {
      console.error("Image upload failed:", error);
      setSubmitting(false);
      resetForm({ initialValues: {} });
      // setIsUploading(false);
    }
  };

  const handleInnerFormSubmit = (
    values,
    setFieldError,
    setTouched,
    setSubmitting,
    resetForm
  ) => {
    setSubmitting(true);

    setTouched({
      businessName: true,
      email: true,
      operationMode: true,
      businessAddress: true,
      businessPhone: true,
      businessTradingLicense: true,
      sellerNIN: true,
      typeOfGoods: true,
      password: true,
      confirmPassword: true,
    });

    try {
      validationSchema.validateSync(values, { abortEarly: false });
      // If validation passes, proceed with your form submission logic here
      handleFormSubmit(values, { setSubmitting, resetForm });
    } catch (errors) {
      // Handle validation errors here
      if (errors instanceof Yup.ValidationError) {
        errors.inner.forEach((error) => {
          setFieldError(error.path, error.message);
        });
      }
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setLoadingSuggestions(false);
    }
  }, [data]);

  return (
    <>
      <div className={classes.container}>
        <LusukuLogo />
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <Card className={classes.formCard}>
                <div className="card-body p-4">
                  <h4 className="text-center mb-4">
                    <strong>VENDOR REGISTRATION</strong>
                  </h4>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      setFieldError,
                      setTouched,
                      setSubmitting,
                      resetForm,
                    }) => (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleInnerFormSubmit(
                            values,
                            setFieldError,
                            setTouched,
                            setSubmitting,
                            resetForm
                          );
                        }}
                      >
                        {/* Business Information Section */}
                        <div className={classes.formSection}>
                          <h5 className={classes.sectionTitle}>
                            Business Information
                          </h5>

                          <div className="form-group mb-4">
                            <label
                              htmlFor="operationMode"
                              className={classes.fieldLabel}
                            >
                              Business Type
                            </label>
                            <Field name="operationMode">
                              {({ field, form }) => (
                                <Select
                                  className={`react-select primary ${
                                    form.errors.operationMode &&
                                    form.touched.operationMode
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  classNamePrefix="react-select"
                                  options={options}
                                  placeholder="Select your business type..."
                                  {...field}
                                  onChange={(e) =>
                                    handleSelectChange(form.setFieldValue, e)
                                  }
                                  value={options.find(
                                    (option) => option.value === field.value
                                  )}
                                />
                              )}
                            </Field>
                            {errors.operationMode && touched.operationMode && (
                              <div className="invalid-feedback d-block">
                                {errors.operationMode}
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group mb-4">
                                <label
                                  htmlFor="businessName"
                                  className={classes.fieldLabel}
                                >
                                  {values.operationMode === "RESTAURANT"
                                    ? "Restaurant Name"
                                    : "Business Name"}
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.businessName && touched.businessName
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="businessName"
                                  placeholder="Enter business name"
                                />
                                {errors.businessName &&
                                  touched.businessName && (
                                    <div className="invalid-feedback">
                                      {errors.businessName}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group mb-4">
                                <label
                                  htmlFor="businessPhone"
                                  className={classes.fieldLabel}
                                >
                                  Business Phone
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.businessPhone &&
                                    touched.businessPhone
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="businessPhone"
                                  placeholder="Enter business phone"
                                />
                                {errors.businessPhone &&
                                  touched.businessPhone && (
                                    <div className="invalid-feedback">
                                      {errors.businessPhone}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className="mb-3">
                            <label className={classes.fieldLabel}>
                              Business Address
                            </label>
                            <LocationAutocomplete
                              value={value}
                              setValue={setValue}
                              ready={ready}
                              data={data}
                              clearSuggestions={clearSuggestions}
                              mapsApiLoaded={mapsApiLoaded}
                              loadingSuggestions={loadingSuggestions}
                              defaultValue={values.businessAddress}
                              onLocationSelect={(location) =>
                                handleLocationSelect(location, setFieldValue)
                              }
                            />
                            {errors.businessAddress &&
                              touched.businessAddress && (
                                <div className="invalid-feedback">
                                  {errors.businessAddress}
                                </div>
                              )}
                          </div>

                          <div className="mb-3">
                            <label className={classes.fieldLabel}>
                              {values.operationMode === "RESTAURANT"
                                ? "Cuisine Specialty"
                                : "Type of Goods"}
                            </label>
                            <Field
                              type="text"
                              className={`form-control ${
                                errors.typeOfGoods && touched.typeOfGoods
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="typeOfGoods"
                              placeholder={
                                values.operationMode === "RESTAURANT"
                                  ? "Enter your cuisine specialty..."
                                  : "Enter the type of goods you sell..."
                              }
                            />
                            {errors.typeOfGoods && touched.typeOfGoods && (
                              <div className="invalid-feedback">
                                {errors.typeOfGoods}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Account Information Section */}
                        <div className={classes.formSection}>
                          <h5 className={classes.sectionTitle}>
                            Account Information
                          </h5>

                          <div className="form-group mb-4">
                            <label
                              htmlFor="email"
                              className={classes.fieldLabel}
                            >
                              Email Address
                            </label>
                            <Field
                              type="email"
                              className={`form-control ${
                                errors.email && touched.email
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="email"
                              placeholder="Enter business email"
                            />
                            {errors.email && touched.email && (
                              <div className="invalid-feedback">
                                {errors.email}
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group mb-4">
                                <label
                                  htmlFor="password"
                                  className={classes.fieldLabel}
                                >
                                  Password
                                </label>
                                <Field
                                  type="password"
                                  className={`form-control ${
                                    errors.password && touched.password
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="password"
                                  placeholder="Enter password"
                                />
                                {errors.password && touched.password && (
                                  <div className="invalid-feedback">
                                    {errors.password}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group mb-4">
                                <label
                                  htmlFor="confirmPassword"
                                  className={classes.fieldLabel}
                                >
                                  Confirm Password
                                </label>
                                <Field
                                  type="password"
                                  className={`form-control ${
                                    errors.confirmPassword &&
                                    touched.confirmPassword
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="confirmPassword"
                                  placeholder="Confirm password"
                                />
                                {errors.confirmPassword &&
                                  touched.confirmPassword && (
                                    <div className="invalid-feedback">
                                      {errors.confirmPassword}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          type="submit"
                          className={`btn w-100 mt-4 ${
                            isSubmitting
                              ? classes.disabledBtn
                              : classes.enabledBtn
                          }`}
                          disabled={isSubmitting}
                          style={{
                            color: isSubmitting ? "#6c957d" : "#fff",
                            opacity: isSubmitting ? "0.8" : "1",
                          }}
                        >
                          {isSubmitting ? (
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="me-2">Creating Account...</span>
                              <CircularProgress
                                style={{ color: "#84BF04" }}
                                size="1.2rem"
                              />
                            </div>
                          ) : (
                            "Create Vendor Account"
                          )}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  },
  formCard: {
    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
    borderRadius: "12px",
    border: "none",
  },
  formSection: {
    marginBottom: "2rem",
    padding: "1.5rem",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
  },
  sectionTitle: {
    color: "#172601",
    fontSize: "1.1rem",
    fontWeight: 600,
    marginBottom: "1.5rem",
    paddingBottom: "0.5rem",
    borderBottom: "2px solid #84BF04",
  },
  fieldLabel: {
    fontSize: "0.9rem",
    fontWeight: 500,
    color: "#495057",
    marginBottom: "0.5rem",
  },
  enabledBtn: {
    backgroundColor: "#172601",
    borderColor: "#84BF04",
    padding: "12px",
    fontSize: "1rem",
    fontWeight: 600,
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#2E5902",
      borderColor: "#84BF04",
      transform: "translateY(-1px)",
    },
  },
  disabledBtn: {
    backgroundColor: "#6c757d",
    borderColor: "#6c757d",
    padding: "12px",
    fontSize: "1rem",
    fontWeight: 600,
    cursor: "not-allowed",
  },
}));
export default VendorRegistrationForm;
