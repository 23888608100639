import React, { useEffect } from "react";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { Spinner } from "react-bootstrap";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { toast } from "react-toastify";

const LocationAutocomplete = ({
  value,
  setValue,
  ready,
  data,
  clearSuggestions,
  mapsApiLoaded,
  onLocationSelect,
  loadingSuggestions,
  defaultValue = "",
}) => {
  // Reset when component mounts
  useEffect(() => {
    setValue("", false);
    clearSuggestions();
  }, []); // Run only once on mount

  // Handle defaultValue changes
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue, false);
    } else {
      setValue("", false);
      clearSuggestions();
    }
  }, [defaultValue, setValue, clearSuggestions]);

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      onLocationSelect({ address, latitude: lat, longitude: lng });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error getting location coordinates");
    }
  };

  return (
    <div>
      {mapsApiLoaded ? (
        <Combobox onSelect={handleSelect}>
          <div style={{ position: "relative" }}>
            <ComboboxInput
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={!ready}
              placeholder="Enter location..."
              style={{
                width: "100%",
                padding: "10px",
                paddingRight: "35px",
                border: "1px solid #f58c60",
                borderRadius: "5px",
                zIndex: "3",
                boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.5)",
                outline: "none",
              }}
            />
            {value && (
              <IconButton
                size="small"
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: "4",
                  color: "#f58c60",
                }}
                onClick={() => {
                  setValue("");
                  clearSuggestions();
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>
          {loadingSuggestions && (
            <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
              <Spinner animation="border" size="sm" />
            </div>
          )}
          <ComboboxPopover
            style={{
              backgroundColor: "white",
              margin: "0",
              padding: "0",
              fontSize: "12px",
              border: "1px solid #F58C60",
              fontStyle: "italic",
              boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.8)",
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
              zIndex: 9999,
            }}
          >
            <ComboboxList>
              {data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      ) : (
        <div>Loading Google Maps API...</div>
      )}
    </div>
  );
};

export default LocationAutocomplete;
