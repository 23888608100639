import {
  FETCH_ALL,
  FETCH_BY_SEARCH,
  CREATE_LISTING,
  CREATE_PROMO_LISTING,
  UPDATE_LISTING,
  DELETE_LISTING,
  LIKE,
  START_LOADING,
  END_LOADING,
  FETCH_LISTING,
  DEACTIVATE_LISTING,
  FETCH_DEACTIVATED_LISTINGS,
  FETCH_OUT_OF_STOCK_LISTINGS,
  REACTIVATE_LISTING,
  VERIFY_LISTING,
  UNVERIFY_LISTING,
} from "../constants/actionTypes.js";

export default (
  state = {
    listings: [],
    promoListings: [],
    listing: {},
    deactivatedListings: [],
    outtaStock: [],
    fetched: false,
    isLoading: false,
    lastUpdateTimestamp: null,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case CREATE_LISTING:
      return {
        ...state,
        isLoading: false,
        listings: [...state.listings, action.payload],
      };

    case CREATE_PROMO_LISTING:
      return {
        ...state,
        isLoading: false,
        promoListings: [...state.promoListings, action.payload],
      };

    case FETCH_ALL:
      return {
        ...state,
        listings: action?.payload?.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        isLoading: false,
        fetched: true,
      };

    case FETCH_BY_SEARCH:
      return {
        ...state,
        listings: action?.payload?.data,
        isLoading: false,
      };
    case FETCH_LISTING:
      return { ...state, listing: action?.payload?.listing, isLoading: false };
    case FETCH_BY_SEARCH:
      return { ...state, listings: action.payload.data };

    case UPDATE_LISTING:
      return {
        ...state,
        listings: state.listings.map((listing) =>
          listing._id === action.payload._id ? action.payload : listing
        ),
        lastUpdateTimestamp: Date.now(),
      };

    case DEACTIVATE_LISTING:
      return {
        ...state,
        listing: action.payload,
      };
    case REACTIVATE_LISTING:
      return {
        ...state,
        listing: action.payload,
        // deactivatedListings: state.deactivatedListings.filter(
        //   (listing) => listing._id !== action.payload
        // ),
      };
    case FETCH_DEACTIVATED_LISTINGS:
      return {
        ...state,
        deactivatedListings: action?.payload,
        isLoading: false,
      };
    case FETCH_OUT_OF_STOCK_LISTINGS:
      return {
        ...state,
        outtaStock: action?.payload,
        isLoading: false,
      };

    case VERIFY_LISTING:
      return {
        ...state,
        listing: action?.payload?.listing,
        isLoading: false,
      };
    case UNVERIFY_LISTING:
      return {
        ...state,
        listing: action?.payload?.listing,
        isLoading: false,
      };

    case DELETE_LISTING:
      return {
        ...state,
        listings: state.listings.filter(
          (listing) => listing._id !== action.payload
        ),
        deactivatedListings: state.deactivatedListings.filter(
          (listing) => listing._id !== action.payload
        ),
        outtaStock: state.outtaStock.filter(
          (listing) => listing._id !== action.payload
        ),
        isLoading: false,
        lastUpdateTimestamp: Date.now(),
      };
    case END_LOADING:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
