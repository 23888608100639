import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  ButtonBase,
} from "@material-ui/core/";
import DeleteIcon from "@material-ui/icons/Delete";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useStyles from "./listingStyle.js";
import {
  getListing,
  verifySellerListing,
} from "../../../../actions/listingsActions.js";
import { deleteListing } from "../../../../actions/listingsActions.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { addToCart, removeFromCart } from "../../../../actions/cartActions.js";
import { getUserFromLocalStorage } from "utilities/user.js";

const Listing = ({ listing, getListings }) => {
  const [qty, setQty] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const isMounted = useRef(true);

  const dispatch = useDispatch();
  const classes = useStyles();
  const user = getUserFromLocalStorage();
  const history = useHistory();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { cartItems } = useSelector((state) => state.cart);

  const openPostDetail = () => {
    dispatch(getListing(listing?._id)),
      history.push(`/admin/listing-details/${listing?._id}`);
  };

  const addToCartHandler = () => {
    dispatch(
      addToCart(listing._id, qty, listing?.creator, listing?.unit_id?._id),
      history.push(`/admin/cart-page/${listing._id}?qty=${qty}`)
    );
  };

  const isInCart = (prod) => {
    if (cartItems?.filter((item) => item._id === prod._id).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const handleDelete = async () => {
    if (isDeleting) return;

    setIsDeleting(true);
    try {
      await dispatch(deleteListing(listing?._id, user?.result?._id));
      if (isMounted.current) {
        setOpenDialog(false);
        if (getListings) {
          getListings(); // Refresh the listings after deletion
        }
      }
    } finally {
      if (isMounted.current) {
        setIsDeleting(false);
      }
    }
  };

  const handleSellerListingVerification = async () => {
    try {
      await dispatch(
        verifySellerListing(listing._id, user?.result?._id, {
          verifyListing: "VERIFIED",
        })
      );
      if (isMounted.current) {
        setOpenVerificationDialog(false);
        if (getListings) {
          getListings();
        }
      }
    } catch (error) {
      console.error("Verification failed:", error);
    }
  };

  return (
    <Card className={classes.card} raised elevation={6}>
      <ButtonBase
        component="span"
        name="test"
        className={classes.cardAction}
        onClick={openPostDetail}
        disabled={listing.countInStock <= 0}
      >
        {listing?.countInStock <= 0 ? (
          <CardMedia
            className={classes.mediaOuttaStock}
            image={
              listing.product_image ||
              "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
            }
          >
            <p className={classes.pOuttaStock}>Outta Stock</p>
          </CardMedia>
        ) : (
          <CardMedia
            className={classes.media}
            image={
              listing.product_image ||
              "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
            }
          />
        )}
      </ButtonBase>
      {(user?.result?.user_role === "ADMIN" ||
        user?.result?.user_role === "SUPERUSER" ||
        user?.result?.user_role === "SUPERVISOR" ||
        user?.result?._id === listing?.creator ||
        user?.result?._id === listing?.creator) && (
        <div className={classes.overlay2} name="edit">
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                getListing(
                  listing._id,
                  history.push(`/admin/shopEdit/${listing?._id}`)
                )
              );
              // setCurrentId(post._id);
            }}
            style={{ color: "white" }}
            size="small"
          >
            <OverlayTrigger
              overlay={
                <Tooltip style={{ opacity: "42%" }} id="tooltip-981231696">
                  Edit
                </Tooltip>
              }
            >
              <MoreHorizIcon className={classes.moreGrey} fontSize="medium" />
            </OverlayTrigger>
          </Button>
        </div>
      )}

      <Typography
        className={classes.title}
        gutterBottom
        variant="body2"
        component="h2"
      >
        {listing.title}
      </Typography>
      <Typography variant="body2" className={classes.unitCount}>
        {listing?.measurements}{" "}
        {listing?.unit_id?.unit ? listing?.unit_id?.unit : ""}
      </Typography>
      <Typography variant="body2" className={classes.title}>
        Shs {listing?.price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
        {""}{" "}
      </Typography>

      <CardContent>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
          }}
        >
          {listing.message}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        {isInCart(listing) ? (
          <Chip
            label="In Cart"
            // color="secondary"
            className={classes.inCartChip}
            size="small"
            style={{ marginRight: "10px" }}
            onClick={() => removeFromCartHandler(listing._id)}
          />
        ) : (
          <Button
            size="small"
            color="primary"
            disabled={listing.countInStock <= 0}
            // disabled={!user?.result.user_role === 'ADMIN'}
            // onClick={() => dispatch(likePost(listing._id))}
            onClick={addToCartHandler}
          >
            <OverlayTrigger
              overlay={
                <Tooltip style={{ opacity: "42%" }} id="tooltip-981231696">
                  Add to Cart
                </Tooltip>
              }
            >
              <ShoppingCart fontSize="small" />
              {/* <Likes /> */}
            </OverlayTrigger>
          </Button>
        )}
        {(user?.result?.user_role === "ADMIN" ||
          user?.result?.user_role === "SUPERUSER" ||
          user?.result?._id === listing?.creator) && (
          <Button
            size="small"
            color="secondary"
            disabled={
              user?.result?.user_role !== "SUPERUSER" ||
              listing.countInStock <= 0
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Pending Verification Icon */}
              {listing?.verifyListing === "PENDING" && (
                <div>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-981231696">
                        Pending Verification
                      </Tooltip>
                    }
                  >
                    <i
                      className="fas fa-clock"
                      style={{ color: "#00bcd4" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenVerificationDialog(true);
                      }}
                    ></i>
                  </OverlayTrigger>
                </div>
              )}
              {/* End Pending Verification Icon */}
              {/* Verification Dialog */}
              <Dialog
                open={openVerificationDialog}
                onClose={() => setOpenVerificationDialog(false)}
                aria-labelledby="verification-dialog-title"
                aria-describedby="verification-dialog-description"
              >
                <DialogTitle id="verification-dialog-title">
                  {"Activate Listing"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="verification-dialog-description">
                    Listing is inactive, activate this listing?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpenVerificationDialog(false)}
                    color="primary"
                    className={classes.cancelActivationButton}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSellerListingVerification}
                    color="primary"
                    autoFocus
                    className={classes.activateListingButton}
                  >
                    Activate
                  </Button>
                </DialogActions>
              </Dialog>
              {/* End Verification Dialog */}

              {/* Delete Button with Confirmation Dialog */}
              <div>
                <OverlayTrigger
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   setOpenDialog(true);
                  // }}
                  overlay={<Tooltip id="tooltip-981231696">Delete</Tooltip>}
                >
                  <DeleteIcon
                    fontSize="small"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenDialog(true);
                    }}
                  />
                </OverlayTrigger>
                <Dialog
                  open={openDialog}
                  onClose={() => setOpenDialog(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Delete Listing"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete this listing?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDialog(false)}
                      color="default"
                      className={classes.cancelButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleDelete}
                      color="secondary"
                      autoFocus
                      className={
                        isDeleting
                          ? classes.isDeletingButton
                          : classes.deleteButton
                      }
                      disabled={isDeleting}
                    >
                      {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              {/* End Delete Button with Confirmation Dialog */}
            </div>
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default Listing;
