import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Table,
  Row,
  Col,
  Button,
  Spinner,
  Alert,
  Form,
  Modal,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Switch, Badge, Chip } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import {
  MdCancel,
  MdContacts,
  MdLocalPrintshop,
  MdArrowBackIosNew,
  MdOutlineCreditCardOff,
  MdOutlineCreditCard,
  MdMap,
  MdRefresh,
  MdLocationOn,
  MdStorefront,
  MdAnalytics,
  MdDirections,
  MdWarning,
  MdRadar,
} from "react-icons/md";
import { toast } from "react-toastify";
import { FaPhoneAlt } from "react-icons/fa";
import {
  assignRestaurantOrderToDeliveryAgent,
  getOrderDetailsToProcess,
  markOrderAsPaid,
  re_assignRestaurantOrderToDeliveryAgent,
  setOrderDataByRider,
  updateRestaurantOrderDeliveryStatus,
} from "../../../../actions/webOrderActions";
import { RECEIPT_URL } from "../../../../api/receiptUrl";
import { RESET_UPDATE_DELIVERY_STATUS_COMPLETE } from "../../../../constants/webOrderConstants";
import "./RestaurantOrderDetails.css";
import { getUserFromLocalStorage } from "../../../../utilities/user.js";
import RiderSelectionDropdown from "./RiderSelectionDropdown";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const isDevelopment = process.env.NODE_ENV === "development";

const RestaurantVendorOrderDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = getUserFromLocalStorage();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [riders, setRiders] = useState([]);
  const [riderId, setRiderId] = useState("");
  const [receiptLoading, setReceiptLoading] = useState(false);
  const [loadingRiders, setLoadingRiders] = useState(true);
  const [assignedRider, setAssignedRider] = useState(null);
  const [elapsedAssignedTime, setElapsedAssignedTime] = useState(0);
  const [elapsedDispatchedTime, setElapsedDispatchedTime] = useState(0);

  const [showMapModal, setShowMapModal] = useState(false);

  const mobileOrderDeliver = useSelector((state) => state.mobileOrderDeliver);
  const { loading: loadingDeliver, success: successDeliver } =
    mobileOrderDeliver;

  const orderDetails = useSelector((state) => state.orderDetails);
  const {
    order,
    loading,
    error,
    isUpdateDeliveryStatusComplete,
    paymentResult,
  } = orderDetails;

  useEffect(() => {
    dispatch(getOrderDetailsToProcess(id));
    if (isUpdateDeliveryStatusComplete) {
      dispatch(getOrderDetailsToProcess(id));
      dispatch({ type: RESET_UPDATE_DELIVERY_STATUS_COMPLETE });
    }
    if (order?.riderId) {
      setRiderId(
        typeof order.riderId === "object" ? order.riderId._id : order.riderId
      );
    }
  }, [isUpdateDeliveryStatusComplete, id, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (order?.riderId && riders.length) {
      setAssignedRider(riders.find((rider) => rider._id === order.riderId._id));
    }
  }, [order, riders]);

  const socketUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const socket = io(socketUrl, {
      query: { user: user?.result?._id },
      transports: ["websocket"],
    });

    socket.on("connect", () => {
      if (isDevelopment) {
        console.log("Webclient Connected");
      }
      // Emit the getRiders event after connecting
      socket.emit("getRiders");
      socket.emit("joinOrderRoom", id); // Join the specific order room
    });

    // Event listener for order updates
    socket.on("orderUpdated", (updatedOrder) => {
      if (updatedOrder._id === id) {
        dispatch(setOrderDataByRider(updatedOrder));

        // Check if the order was declined by a rider
        if (
          updatedOrder.delivery_status === "PROCESSING" &&
          order?.delivery_status === "ASSIGNED"
        ) {
          toast.info(
            "Rider has declined the order. Order status reverted to PROCESSING."
          );
          setRiderId(""); // Reset the selected rider
        }
      }
    });

    // Event listener for order updates
    socket.on("orderStatusChanged", ({ orderId, status }) => {
      if (orderId === id) {
        dispatch(
          setOrderDataByRider({ _id: orderId, delivery_status: status })
        );
        toast.info(`Order status updated to ${status}`);
      }
    });

    socket.on("initialRidersList", (socketData) => {
      setRiders(socketData);
      setLoadingRiders(false);
    });

    socket.on("updatedRidersList", (updatedRiders) => {
      setRiders(updatedRiders);
    });

    socket.on("riderAdded", (newRider) => {
      setRiders((prevRiders) => [...prevRiders, newRider]);
    });

    socket.on("riderUpdated", (updatedRider) => {
      setRiders((prevRiders) =>
        prevRiders.map((rider) =>
          rider._id === updatedRider._id ? updatedRider : rider
        )
      );
    });

    socket.on("riderDeleted", (deletedRider) => {
      setRiders((prevRiders) =>
        prevRiders.filter((rider) => rider._id !== deletedRider._id)
      );
    });

    return () => {
      socket.disconnect();
    };
  }, [user?.result?._id, dispatch, order?.delivery_status, id]);

  const handleBack = () => {
    history.push("/admin/resOrdersTable");
  };

  const handleUpdateStatus = (newStatus) => {
    if (newStatus === "ASSIGNED" && !riderId) {
      toast.warn("Please select a rider before assigning the order.");
      return;
    }
    if (newStatus === "ASSIGNED") {
      assignRestaurantOrderToRider();
    } else {
      dispatch(updateRestaurantOrderDeliveryStatus(order?._id, newStatus));
    }
  };

  const handleCancelOrder = () => {
    const delivery_status = "REJECTED";
    console.log("Cancelling order");
    dispatch(updateRestaurantOrderDeliveryStatus(order?._id, delivery_status));
    setShowCancelModal(false);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleContactCustomer = () => {
    alert(`Contacting customer: ${order?.shippingAddress.customerNumber}`);
  };

  const handleMarkAsPaid = () => {
    if (user?.result?.userType !== "LUSUKU STAFF") {
      toast.warn("🚫 You are not authorized to mark order as paid!");
      return;
    } else if (order?.isPaid) {
      toast.info("This order is already marked as paid.");
      return;
    } else {
      dispatch(markOrderAsPaid(order?._id));
    }
  };

  const assignRestaurantOrderToRider = () => {
    const delivery_status = "ASSIGNED";
    const assignedTime = Date.now();
    toast.success(`Assigning order ${order?._id} to rider ${riderId}`);
    localStorage.setItem(`order_${order?._id}_assignedTime`, assignedTime);
    dispatch(
      assignRestaurantOrderToDeliveryAgent(order?._id, riderId, delivery_status)
    );
    // setTimeout(() => {
    //     dispatch(getOrderDetailsToProcess(order?._id));
    // }, 500);
  };

  const handleDispatchRestaurantOrder = () => {
    const delivery_status = "DISPATCHED";
    const dispatchedTime = Date.now();
    console.log(`Dispatching order ${order?._id}`);
    localStorage.setItem(`order_${order?._id}_dispatchedTime`, dispatchedTime);
    dispatch(updateRestaurantOrderDeliveryStatus(order?._id, delivery_status));
    setTimeout(() => {
      dispatch(getOrderDetailsToProcess(order?._id));
    }, 500);
  };

  const handleDeliverRestaurantOrder = () => {
    const delivery_status = "DELIVERED";
    dispatch(updateRestaurantOrderDeliveryStatus(order?._id, delivery_status));
    setTimeout(() => {
      dispatch(getOrderDetailsToProcess(order?._id));
    }, 500);
  };

  const getProgress = () => {
    switch (order?.delivery_status) {
      case "PROCESSING":
        return 20;
      case "ASSIGNED":
        return 40;
      case "DISPATCHED":
        return 60;
      case "DELIVERED":
        return 100;
      case "PAID":
        return 100;
      default:
        return 0;
    }
  };

  const getProgressBarStyle = () => {
    switch (order?.delivery_status) {
      case "DELIVERED":
      case "PAID":
        return { backgroundColor: "green", color: "white" };
      default:
        return {};
    }
  };

  const getBadgeStyle = (status) => {
    switch (status) {
      case "PROCESSING":
        return {
          backgroundColor: "orange",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
          width: "110px",
          height: "20px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        };
      case "ASSIGNED":
        return {
          backgroundColor: "blue",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
        };
      case "DISPATCHED":
        return {
          backgroundColor: "purple",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
        };
      case "DELIVERED":
        return {
          backgroundColor: "green",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
        };
      case "REJECTED":
        return {
          backgroundColor: "red",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
        };
      default:
        return {
          backgroundColor: "grey",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
        };
    }
  };

  const getPaidBadgeStyle = (isPaid) => {
    return {
      backgroundColor: isPaid ? "green" : "#2C3A40",
      color: "white",
      borderRadius: "10px",
      padding: "3px",
    };
  };

  const canCancelOrder =
    order?.delivery_status === "PROCESSING" ||
    order?.delivery_status === "PAID";

  const getDisabledStatusOptions = () => {
    const disabledOptions = {
      PROCESSING: order?.delivery_status !== "PROCESSING",
      ASSIGNED:
        order?.delivery_status !== "PROCESSING" &&
        order?.delivery_status !== "ASSIGNED",
      DISPATCHED:
        order?.delivery_status !== "ASSIGNED" &&
        order?.delivery_status !== "DISPATCHED",
      DELIVERED:
        order?.delivery_status !== "DISPATCHED" &&
        order?.delivery_status !== "DELIVERED",
      REJECTED:
        order?.delivery_status === "DELIVERED" ||
        order?.delivery_status === "REJECTED",
    };
    return disabledOptions;
  };

  const disabledStatusOptions = getDisabledStatusOptions();

  useEffect(() => {
    if (order?.delivery_status === "ASSIGNED") {
      const storedAssignedTime = localStorage.getItem(
        `order_${order?._id}_assignedTime`
      );
      if (storedAssignedTime) {
        const elapsed = Math.floor(
          (Date.now() - parseInt(storedAssignedTime, 10)) / 1000
        );
        setElapsedAssignedTime(elapsed);
      }
    }
    if (order?.delivery_status === "DISPATCHED") {
      const storedDispatchedTime = localStorage.getItem(
        `order_${order?._id}_dispatchedTime`
      );
      if (storedDispatchedTime) {
        const elapsed = Math.floor(
          (Date.now() - parseInt(storedDispatchedTime, 10)) / 1000
        );
        setElapsedDispatchedTime(elapsed);
      }
    }

    const interval = setInterval(() => {
      if (order?.delivery_status === "ASSIGNED") {
        setElapsedAssignedTime((prev) => prev + 1);
      }
      if (order?.delivery_status === "DISPATCHED") {
        setElapsedDispatchedTime((prev) => prev + 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [order?.delivery_status, order?._id]);

  // calculate distance using the Haversine formula
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance.toFixed(2);
  };

  if (loading) {
    return (
      <Spinner
        animation="grow"
        variant="primary"
        style={{
          marginTop: "70px",
          top: "50%",
          left: "50%",
          position: "fixed",
        }}
      />
    );
  }

  if (error) {
    return (
      <Alert variant="danger" style={{ marginTop: "70px" }}>
        {error}
      </Alert>
    );
  }

  const handleShowMap = () => {
    setShowMapModal(true);
  };

  const handleCloseMap = () => {
    setShowMapModal(false);
  };

  // Reassign order to a new rider

  const handleReassignOrder = () => {
    const delivery_status = "PROCESSING";
    const currentRiderId =
      typeof order?.riderId === "object" ? order?.riderId?._id : order?.riderId;
    if (order?._id && currentRiderId) {
      dispatch(
        re_assignRestaurantOrderToDeliveryAgent(
          order?._id,
          currentRiderId,
          delivery_status,
          true
        )
      );
    } else {
      toast.error("No rider currently assigned to this order.");
    }
  };

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col md="12">
          <Button
            variant="outline-primary"
            onClick={handleBack}
            className="d-flex align-items-center"
            style={{
              borderRadius: "8px",
              padding: "10px 15px",
              transition: "all 0.2s",
              boxShadow: "0 3px 8px rgba(0,0,0,0.9)",
            }}
          >
            <MdArrowBackIosNew style={{ marginRight: "8px" }} />
            Back to Orders
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Card className="shadow">
            <Card.Header
              style={{
                backgroundColor: "#012030",
                padding: "15px 20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "none",
              }}
            >
              <div>
                <Card.Title
                  as="h4"
                  style={{ color: "white", marginBottom: "5px" }}
                >
                  Menu Order Details
                </Card.Title>
                <small style={{ color: "#a8c6df" }}>Order #{order?._id}</small>
              </div>
              <Button
                variant="outline-light"
                onClick={handlePrint}
                className="d-flex align-items-center"
                style={{
                  gap: "8px",
                  boxShadow: "0 1px 8px rgb(236, 234, 232, 0.5)",
                }}
              >
                <MdLocalPrintshop />
                Print Receipt
              </Button>
            </Card.Header>

            <Card.Body>
              <Row>
                <Col md="6">
                  <div
                    className="section-title"
                    style={{
                      borderBottom: "2px solid #013A63",
                      marginBottom: "20px",
                      paddingBottom: "10px",
                    }}
                  >
                    <h5 style={{ color: "#013A63", fontWeight: "600" }}>
                      Order Items
                    </h5>
                  </div>
                  <Table
                    hover
                    className="shadow-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "5%" }}>#</th>
                        <th style={{ width: "10%" }}>Image</th>
                        <th style={{ width: "40%" }}>Item Name</th>
                        <th style={{ width: "10%" }}>Quantity</th>
                        <th style={{ width: "15%" }}>Price</th>
                        <th
                          style={{
                            width: "20%",
                            whiteSpace: "nowrap",
                            textAlign: "right",
                          }}
                        >
                          Total
                        </th>
                        {/* Fix total header */}
                      </tr>
                    </thead>
                    <tbody>
                      {order?.orderItems?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="image-container">
                              <img
                                src={item.image}
                                alt={item.name}
                                className="table-image"
                              />
                              <div className="image-hover">
                                <img
                                  src={item.image}
                                  alt={item.name}
                                  className="hover-image"
                                />
                              </div>
                            </div>
                          </td>
                          <td>{item.name}</td>
                          <td>{item.qty}</td>
                          <td>{item.price.toLocaleString()}</td>
                          <td
                            style={{
                              textAlign: "right",
                              padding: "8px",
                              verticalAlign: "middle",
                            }}
                          >
                            {(item.qty * item.price).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>

                <Col md="6">
                  <div
                    className="section-title"
                    style={{
                      borderBottom: "2px solid #013A63",
                      marginBottom: "20px",
                      paddingBottom: "10px",
                    }}
                  >
                    <h5 style={{ color: "#013A63", fontWeight: "600" }}>
                      Order Summary
                    </h5>
                  </div>
                  <Card
                    className="summary-card"
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Card.Body>
                      <div className="customer-info mb-4">
                        <h6 className="text-muted mb-3">
                          Customer Information
                        </h6>
                        <div className="info-item d-flex justify-content-between align-items-center mb-2">
                          <span>Name:</span>
                          <strong>{order?.customerName}</strong>
                        </div>
                        <div className="info-item d-flex justify-content-between align-items-center mb-2">
                          <span>Payment Method:</span>
                          <strong>{order?.paymentMethod}</strong>
                        </div>
                      </div>

                      <div className="price-summary mb-4">
                        <h6 className="text-muted mb-3">Price Details</h6>
                        <div className="info-item d-flex justify-content-between align-items-center mb-2">
                          <span>Items Total:</span>
                          <strong>
                            Ush {order?.itemsPrice?.toLocaleString()}
                          </strong>
                        </div>
                        <div className="info-item d-flex justify-content-between align-items-center mb-2">
                          <span>Tax:</span>
                          <strong>Ush {order?.taxPrice?.toFixed(2)}</strong>
                        </div>
                        <div
                          className="info-item d-flex justify-content-between align-items-center mb-2"
                          style={{
                            borderTop: "1px dashed #dee2e6",
                            paddingTop: "10px",
                          }}
                        >
                          <span>Total Amount:</span>
                          <strong
                            style={{ color: "#013A63", fontSize: "1.1em" }}
                          >
                            Ush {order?.totalPrice?.toLocaleString()}
                          </strong>
                        </div>
                      </div>

                      <div className="status-section">
                        <h6 className="text-muted mb-3">Order Status</h6>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <span>Payment Status:</span>
                          <Badge
                            overlap="rectangular"
                            style={{
                              ...getPaidBadgeStyle(order?.isPaid),
                              padding: "8px 12px",
                              borderRadius: "20px",
                              boxShadow: "0 2px 5px rgb(217, 37, 37, 0.9)",
                            }}
                          >
                            {order?.isPaid ? (
                              <>
                                <MdOutlineCreditCard className="me-1" /> Paid
                              </>
                            ) : (
                              <>
                                <MdOutlineCreditCardOff className="me-1" />{" "}
                                Unpaid
                              </>
                            )}
                          </Badge>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Delivery Status:</span>
                          <Badge
                            overlap="rectangular"
                            style={{
                              ...getBadgeStyle(order?.delivery_status),
                              padding: "18px 12px",
                              borderRadius: "20px",
                              fontSize: "14px",
                              boxShadow: "0 2px 8px rgba(0,0,0,0.8)",
                            }}
                          >
                            {order?.delivery_status}
                          </Badge>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md="6">
                  <RiderSelectionDropdown
                    order={order}
                    riderId={riderId}
                    setRiderId={setRiderId}
                    loadingRiders={loadingRiders}
                    riders={riders}
                    assignRestaurantOrderToRider={assignRestaurantOrderToRider}
                    elapsedAssignedTime={elapsedAssignedTime}
                    elapsedDispatchedTime={elapsedDispatchedTime}
                    onRiderSelect={(newRiderId) => setRiderId(newRiderId)}
                  />
                  {order?.delivery_status !== "PROCESSING" &&
                    order?.delivery_status !== "DELIVERED" &&
                    order?.delivery_status !== "REJECTED" && (
                      <Button
                        variant="secondary"
                        onClick={handleReassignOrder}
                        className="mt-2"
                        style={{ width: "100%" }}
                      >
                        <>
                          <MdRefresh style={{ marginRight: "5px" }} />
                          Re-assign Order to different Rider!
                        </>
                      </Button>
                    )}
                </Col>
                <Col md="6">
                  <Form.Group
                    controlId="formOrderStatus"
                    className="shadow p-3"
                    style={{ boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)" }}
                  >
                    <Form.Label
                      style={{ color: "#012030", fontWeight: "bold" }}
                    >
                      Update Order Status
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={order.delivery_status}
                      onChange={(e) => handleUpdateStatus(e.target.value)}
                    >
                      <option
                        value="PROCESSING"
                        disabled={disabledStatusOptions.PROCESSING}
                      >
                        1. Processing
                      </option>
                      <option
                        value="ASSIGNED"
                        disabled={disabledStatusOptions.ASSIGNED}
                      >
                        2. Assigned
                      </option>
                      <option
                        value="DISPATCHED"
                        disabled={disabledStatusOptions.DISPATCHED}
                      >
                        3. Dispatched
                      </option>
                      <option
                        value="DELIVERED"
                        disabled={disabledStatusOptions.DELIVERED}
                      >
                        4. Delivered
                      </option>
                      <option
                        value="REJECTED"
                        disabled={disabledStatusOptions.REJECTED}
                      >
                        5. Cancel Order
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="d-flex justify-content-end align-items-center">
                  <div
                    style={{
                      cursor: !canCancelOrder ? "not-allowed" : "pointer",
                      backgroundColor: !canCancelOrder
                        ? "#8C1F28"
                        : "transparent",
                      borderRadius: "5px",
                      padding: "2px",
                      marginRight: "5px",
                      width: "160px",
                      height: "40px",
                    }}
                  >
                    <Button
                      variant="danger"
                      onClick={() => setShowCancelModal(true)}
                      className="mr-2"
                      disabled={!canCancelOrder}
                      style={{
                        backgroundColor: !canCancelOrder
                          ? "transparent"
                          : "red",
                        color: !canCancelOrder ? "white" : "white",
                        border: "none",
                        fontSize: "14px",
                        width: "100%",
                        height: "100%",
                        padding: "6px",
                        boxShadow: "0 3px 8px rgba(0,0,0,0.9)",
                      }}
                    >
                      <MdCancel
                        style={{
                          fontSize: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "5px",
                        }}
                      />
                      Cancel Order
                    </Button>
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      borderRadius: "5px",
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      borderRadius: "5px",
                      padding: "4px",
                      marginRight: "5px",
                      width: "180px",
                      height: "50px",
                    }}
                  >
                    <Button
                      variant="success"
                      onClick={handleContactCustomer}
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        border: "none",
                        fontSize: "14px",
                        width: "100%",
                        height: "100%",
                        padding: "10px",
                        boxShadow: "0 3px 8px rgba(0,0,0,0.9)",
                      }}
                    >
                      Contact Customer
                      <MdContacts style={{ marginLeft: "4px" }} />
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md="12">
                  <ProgressBar
                    now={getProgress()}
                    label={`${getProgress()}%`}
                    style={{ height: "30px" }}
                    variant={
                      order?.delivery_status === "DELIVERED"
                        ? "success"
                        : undefined
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="12" className="d-flex justify-content-between">
                  <div
                    style={{
                      cursor:
                        order?.delivery_status !== "ASSIGNED"
                          ? "not-allowed"
                          : "pointer",
                      backgroundColor:
                        order?.delivery_status !== "ASSIGNED"
                          ? "#4B4952"
                          : "transparent",
                      borderRadius: "5px",
                      padding: "2px",
                    }}
                  >
                    <Button
                      variant="warning"
                      onClick={handleDispatchRestaurantOrder}
                      disabled={order?.delivery_status !== "ASSIGNED"}
                      style={{
                        backgroundColor:
                          order?.delivery_status !== "ASSIGNED"
                            ? "transparent"
                            : "orange",
                        color:
                          order?.delivery_status !== "ASSIGNED"
                            ? "white"
                            : "black",
                        border: "none",
                        fontSize: "14px",
                        boxShadow: "0 2px 5px rgb(236, 234, 232)",
                      }}
                    >
                      Dispatch Order
                    </Button>
                  </div>
                  <div
                    style={{
                      cursor:
                        order?.delivery_status !== "DISPATCHED"
                          ? "not-allowed"
                          : "pointer",
                      backgroundColor:
                        order?.delivery_status !== "DISPATCHED"
                          ? "green"
                          : "transparent",
                      borderRadius: "5px",
                      padding: "2px",
                    }}
                  >
                    <Button
                      variant="success"
                      onClick={handleDeliverRestaurantOrder}
                      disabled={order?.delivery_status !== "DISPATCHED"}
                      style={{
                        backgroundColor:
                          order?.delivery_status !== "DISPATCHED"
                            ? "transparent"
                            : "green",
                        color:
                          order?.delivery_status !== "DISPATCHED"
                            ? "white"
                            : "black",
                        border: "none",
                        fontSize: "14px",
                        boxShadow: "0 2px 5px rgb(236, 234, 232)",
                      }}
                    >
                      Mark as Delivered
                    </Button>
                  </div>
                  <div
                    style={{
                      cursor: order?.isPaid ? "not-allowed" : "pointer",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={!!order?.isPaid}
                          onChange={handleMarkAsPaid}
                          color="primary"
                          disabled={
                            order?.delivery_status !== "DELIVERED" ||
                            !!order?.isPaid
                          }
                        />
                      }
                      label={order?.isPaid ? "Paid" : "Mark as Paid"}
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this order?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={handleCancelOrder}>
            Cancel Order
          </Button>
        </Modal.Footer>
      </Modal>

      {order?.orderItems[0]?.outletId || order?.orderItems[0]?.vendorId ? (
        <Card className="shadow-sm mt-4">
          <Card.Header
            style={{
              backgroundColor: "#012030",
              color: "white",
              padding: "1rem",
            }}
          >
            <h4 style={{ margin: 0, display: "flex", alignItems: "center" }}>
              <MdLocationOn style={{ marginRight: "10px", fontSize: "24px" }} />
              Delivery Distance Information
            </h4>
          </Card.Header>
          <Card.Body style={{ padding: "1.5rem" }}>
            <Row>
              <Col md={6}>
                <div
                  style={{
                    background: "#f8f9fa",
                    padding: "1.5rem",
                    borderRadius: "12px",
                    height: "100%",
                  }}
                >
                  <h5
                    style={{
                      color: "#193C40",
                      marginBottom: "1.2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MdStorefront
                      style={{ marginRight: "8px", fontSize: "20px" }}
                    />
                    {order?.orderItems[0]?.isOutletOrder
                      ? "Outlet Details"
                      : "Restaurant Details"}
                  </h5>
                  <div style={{ marginBottom: "1rem" }}>
                    <strong style={{ color: "#495057" }}>Name:</strong>
                    <p
                      style={{
                        margin: "4px 0 12px 0",
                        padding: "8px 12px",
                        background: "white",
                        borderRadius: "8px",
                        border: "1px solid #e9ecef",
                      }}
                    >
                      {order?.orderItems[0]?.outletId?.name ||
                        order?.orderItems[0]?.vendorId?.name}
                    </p>
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    <strong style={{ color: "#495057" }}>Address:</strong>
                    <p
                      style={{
                        margin: "4px 0 12px 0",
                        padding: "8px 12px",
                        background: "white",
                        borderRadius: "8px",
                        border: "1px solid #e9ecef",
                      }}
                    >
                      {order?.orderItems[0]?.outletId?.address ||
                        order?.orderItems[0]?.vendorId?.address}
                    </p>
                  </div>
                  <div>
                    <strong style={{ color: "#495057" }}>Delivery Zone:</strong>
                    <div
                      style={{
                        margin: "4px 0",
                        padding: "8px 12px",
                        background: "white",
                        borderRadius: "8px",
                        border: "1px solid #e9ecef",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <MdRadar
                        style={{ marginRight: "8px", color: "#193C40" }}
                      />
                      {`${
                        order?.orderItems[0]?.outletId?.deliveryZoneRadius ||
                        order?.orderItems[0]?.vendorId?.deliveryZoneRadius
                      } km radius`}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div
                  style={{
                    background: "#f8f9fa",
                    padding: "1.5rem",
                    borderRadius: "12px",
                    height: "100%",
                  }}
                >
                  <h5
                    style={{
                      color: "#193C40",
                      marginBottom: "1.2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MdAnalytics
                      style={{ marginRight: "8px", fontSize: "20px" }}
                    />
                    Distance Analysis
                  </h5>
                  {order?.shippingAddress?.latLng && (
                    <>
                      <div style={{ marginBottom: "1rem" }}>
                        <strong style={{ color: "#495057" }}>
                          Distance to Customer:
                        </strong>
                        <div
                          style={{
                            margin: "4px 0",
                            padding: "12px",
                            background: "white",
                            borderRadius: "8px",
                            border: "1px solid #e9ecef",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            <MdDirections
                              style={{ marginRight: "8px", color: "#193C40" }}
                            />
                            {(() => {
                              const storeLat =
                                order?.orderItems[0]?.outletId?.latitude ||
                                order?.orderItems[0]?.vendorId?.latitude;
                              const storeLng =
                                order?.orderItems[0]?.outletId?.longitude ||
                                order?.orderItems[0]?.vendorId?.longitude;
                              const customerLat =
                                order?.shippingAddress?.latLng?.lat;
                              const customerLng =
                                order?.shippingAddress?.latLng?.lng;

                              if (
                                !storeLat ||
                                !storeLng ||
                                !customerLat ||
                                !customerLng
                              ) {
                                return "-- km";
                              }

                              return `${calculateDistance(
                                storeLat,
                                storeLng,
                                customerLat,
                                customerLng
                              )} km`;
                            })()}
                          </span>
                          {(() => {
                            const distance = calculateDistance(
                              order?.orderItems[0]?.outletId?.latitude ||
                                order?.orderItems[0]?.vendorId?.latitude,
                              order?.orderItems[0]?.outletId?.longitude ||
                                order?.orderItems[0]?.vendorId?.longitude,
                              order?.shippingAddress?.latLng?.lat,
                              order?.shippingAddress?.latLng?.lng
                            );
                            const zoneRadius =
                              order?.orderItems[0]?.outletId
                                ?.deliveryZoneRadius ||
                              order?.orderItems[0]?.vendorId
                                ?.deliveryZoneRadius;

                            return distance > zoneRadius ? (
                              <Chip
                                label="Outside Zone"
                                style={{
                                  backgroundColor: "#dc3545",
                                  color: "white",
                                  fontSize: "0.8rem",
                                }}
                              />
                            ) : (
                              <Chip
                                label="Within Zone"
                                style={{
                                  backgroundColor: "#28a745",
                                  color: "white",
                                  fontSize: "0.8rem",
                                  boxShadow: "0 2px 5px rgb(241, 146, 13, 0.8)",
                                }}
                              />
                            );
                          })()}
                        </div>
                      </div>
                      {(() => {
                        const distance = calculateDistance(
                          order?.orderItems[0]?.outletId?.latitude ||
                            order?.orderItems[0]?.vendorId?.latitude,
                          order?.orderItems[0]?.outletId?.longitude ||
                            order?.orderItems[0]?.vendorId?.longitude,
                          order?.shippingAddress?.latLng?.lat,
                          order?.shippingAddress?.latLng?.lng
                        );
                        const zoneRadius =
                          order?.orderItems[0]?.outletId?.deliveryZoneRadius ||
                          order?.orderItems[0]?.vendorId?.deliveryZoneRadius;

                        return distance > zoneRadius ? (
                          <Alert
                            variant="warning"
                            style={{
                              borderRadius: "8px",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <MdWarning size={20} />
                            {`Customer is outside the ${
                              order?.orderItems[0]?.isOutletOrder
                                ? "outlet's"
                                : "restaurant's"
                            } delivery zone (${zoneRadius}km)`}
                          </Alert>
                        ) : null;
                      })()}
                      <div className="mt-4">
                        <Button
                          variant="info"
                          style={{
                            backgroundColor: "#193C40",
                            color: "white",
                            padding: "10px 20px",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            transition: "transform 0.2s",
                            width: "100%",
                            justifyContent: "center",
                          }}
                          onClick={() => setShowMapModal(true)}
                        >
                          <MdMap size={20} />
                          View Route on Map
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : null}

      <Modal
        show={showMapModal}
        onHide={() => setShowMapModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delivery Route Map</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "70vh", padding: 0 }}>
          <iframe
            title="Delivery Route"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            src={`https://www.google.com/maps/embed/v1/directions?key=${
              process.env.REACT_APP_GOOGLE_MAPS_API_KEY
            }
              &origin=${
                order?.orderItems[0]?.outletId?.latitude ||
                order?.orderItems[0]?.vendorId?.latitude
              },${
              order?.orderItems[0]?.outletId?.longitude ||
              order?.orderItems[0]?.vendorId?.longitude
            }
              &destination=${order?.shippingAddress?.latLng?.lat},${
              order?.shippingAddress?.latLng?.lng
            }
              &mode=driving`}
            allowFullScreen
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowMapModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              const storeLoc = `${
                order?.orderItems[0]?.outletId?.latitude ||
                order?.orderItems[0]?.vendorId?.latitude
              },${
                order?.orderItems[0]?.outletId?.longitude ||
                order?.orderItems[0]?.vendorId?.longitude
              }`;
              const customerLoc = `${order?.shippingAddress?.latLng?.lat},${order?.shippingAddress?.latLng?.lng}`;
              window.open(
                `https://www.google.com/maps/dir/${storeLoc}/${customerLoc}`,
                "_blank"
              );
            }}
          >
            Open in Google Maps
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RestaurantVendorOrderDetails;
