import { applyMiddleware, combineReducers, createStore } from "redux";

// import { authReducer } from "./auth";
import listings from "./listingsReducer";
import auth from "./auth";
import mobileOrders, {
  mobileOrderDeliverReducer,
  // mobileOrderDetailsReducer,
} from "./mobileOrdersReducer";
import shopReducer from "./shopReducer";
import { cartReducer, cartProductDetailsReducer } from "./cartReducers";
import {
  cashOnDeliveryOrderReducer,
  // cashOrderCreateReducer, //To Delete
  orderCreateReducer,
  // orderDeliverReducer,
  orderDetailsReducer,
  orderPayReducer,
  // vendorOrderDetailsReducer,
} from "./webOrdersReducer";
import { getDeliveryZoneReducer } from "./zoneReducer";
import { getCategoriesReducer } from "./categoryReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { getSiUnitsReducer } from "./siUnitsReducer";
import { ridersReducer } from "./ridersReducer";
import { getShippingCostsReducer } from "./shippingCostsReducer";
import { sellerReducer, vendorReducer } from "./vendorReducer";
import sections from "./sectionReducer";
import options from "./optionReducer";
import restaurantProducts from "./restaurantProductReducer.js";
import restaurantReducer from "./restaurantReducer.js";
import { restaurantVendorStatsReducer } from "./restaurantVendorStatsReducer";
import { restaurantVendorOrdersReducer } from "./restaurantVendorOrdersReducer";
import { retailerWholesalerVendorOrdersReducer } from "./retailerWholesalerVendorOrdersReducer";
import { sellerStatsReducer } from "./sellerStatsReducer";
import { restaurantProfileReducer } from "./restaurantProfileReducer";
import { retailerWholesalerSectionReducer } from "./retailerWholesalerSectionReducer";
import { retailerWholesalerProductReducer } from "./retailerWholesalerProductReducer";
import { retailerWholesalerOptionReducer } from "./retailerWholesalerOptionReducer";
import { retailerWholesalerProfileReducer } from "./retailerWholesalerProfileReducer";
import { advertsReducer } from "./advertsReducer";
import outletReducer from "./outletReducer";
export const rootReducer = combineReducers({
  // auth: authReducer,
  auth,
  listings,
  mobileOrders,
  cart: cartReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  // vendorOrderDetails: vendorOrderDetailsReducer,
  deliveryZones: getDeliveryZoneReducer,
  siUnits: getSiUnitsReducer,
  shippingCosts: getShippingCostsReducer,
  riders: ridersReducer,
  categories: getCategoriesReducer,
  cashOnDeliveryOrders: cashOnDeliveryOrderReducer,
  orderPay: orderPayReducer,
  mobileOrderDeliver: mobileOrderDeliverReducer,
  vendor: vendorReducer,
  sellers: sellerReducer,
  sections,
  options,
  restaurantProducts,
  restaurants: restaurantReducer,
  retailerWholesalerSections: retailerWholesalerSectionReducer,
  retailerWholesalerProducts: retailerWholesalerProductReducer,
  retailerWholesalerOptions: retailerWholesalerOptionReducer,
  restaurantVendorStats: restaurantVendorStatsReducer,
  restaurantVendorOrders: restaurantVendorOrdersReducer,
  retailerWholesalerVendorOrders: retailerWholesalerVendorOrdersReducer,
  sellerStats: sellerStatsReducer,
  restaurantProfile: restaurantProfileReducer,
  retailerWholesalerProfile: retailerWholesalerProfileReducer,
  adverts: advertsReducer,
  outlets: outletReducer
});

const cartItemsInLocalStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};
const INITIAL_STATE = {
  cart: {
    cartItems: cartItemsInLocalStorage,
    shippingAddress: shippingAddressFromStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  INITIAL_STATE,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
