import { useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Modal,
  Nav,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import BlockIcon from "@material-ui/icons/Block";
import moment from "moment";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  getSellers,
  getSellerDetail,
  softDeleteSeller,
  deleteSeller,
  reactivateSeller,
} from "../../actions/vendorActions";
import { Chip } from "@material-ui/core";
import { getUserFromLocalStorage } from "../../utilities/user.js";

const Sellers = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSoftDeleteModal, setShowSoftDeleteModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [currentSellerId, setCurrentSellerId] = useState(null);
  const [showCategory, setShowCategory] = useState("verified"); // 'verified', 'pending', 'deactivated'
  const [searchTerm, setSearchTerm] = useState("");

  //   Get Sellers with redux
  const dispatch = useDispatch();
  const history = useHistory();
  const { sellers, loading } = useSelector((state) => state.sellers);
  const user = getUserFromLocalStorage();

  // Verified Sellers
  const filterSellers = (sellers) => {
    return sellers.filter((seller) =>
      [seller.businessName, seller.email, seller.typeOfGoods]
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  };

  const verifiedSellers = useMemo(
    () =>
      filterSellers(
        sellers.filter(
          (seller) =>
            seller.sellerVerification === "VERIFIED" && seller.isActive
        )
      ),
    [sellers, searchTerm]
  );

  // Pending Sellers
  const pendingVerificationSellers = useMemo(
    () =>
      filterSellers(
        sellers.filter(
          (seller) => seller.sellerVerification === "PENDING" && seller.isActive
        )
      ),
    [sellers, searchTerm]
  );

  // Deactivated Sellers
  const deactivatedSellers = useMemo(
    () => filterSellers(sellers.filter((seller) => !seller.isActive)),
    [sellers, searchTerm]
  );

  useEffect(() => {
    dispatch(getSellers(user?.result._id));
  }, []);

  const handleDelete = (sellerId) => {
    dispatch(deleteSeller(sellerId, user?.result?._id));
    setShowDeleteModal(false);
  };
  const handleSoftDelete = (sellerId) => {
    dispatch(softDeleteSeller(sellerId, user?.result?._id));
    setShowSoftDeleteModal(false);
  };

  const handleActivateVendor = (sellerId) => {
    dispatch(reactivateSeller(sellerId, user?.result?._id));
    setShowActivateModal(false);
  };
  return (
    <>
      {loading && (
        <Spinner
          animation="grow"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      )}
      <Container fluid>
        <Row>
          <Col md="11">
            <Card className="regular-table-with-color">
              <Card.Header>
                <Card.Title as="h4" className="mb-1">
                  Sellers
                </Card.Title>
                {/* Number of Sellers */}
                <div
                  className="d-flex justify-content-between"
                  style={{
                    position: "relative",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "0",
                      display: "flex",
                    }}
                  >
                    <p
                      className="card-category"
                      style={{
                        top: "0",
                        margin: "0",
                        padding: "0.5rem 1rem",
                        backgroundColor: "#177",
                        borderRadius: "0 0 0 0.25rem",
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "0.875rem",
                        lineHeight: "1.5",
                        textTransform: "capitalize",
                        letterSpacing: "0.04em",
                        border: "1px solid rgba(0,0,0,0.125)",
                        borderTop: "none",
                        borderRight: "none",
                        borderBottom: "none",
                        borderLeft: "1px solid rgba(0,0,0,0.125)",
                      }}
                    >
                      <VerifiedUserIcon
                        style={{ color: "#1F2", fontSize: 16 }}
                      />{" "}
                      {verifiedSellers.length} Verified
                    </p>
                    <p
                      className="card-category"
                      style={{
                        top: "0",
                        margin: "0",
                        padding: "0.5rem 1rem",
                        backgroundColor: "#FF2976",
                        borderRadius: "0 0.25rem 0.25rem 0",
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "0.875rem",
                        lineHeight: "1.5",
                        textTransform: "capitalize",
                        letterSpacing: "0.04em",
                        border: "1px solid rgba(0,0,0,0.125)",
                        borderTop: "none",
                        borderRight: "none",
                        borderBottom: "none",
                        borderLeft: "1px solid rgba(0,0,0,0.125)",
                      }}
                    >
                      <TimelapseIcon
                        style={{ color: "#FFEC00", fontSize: 17 }}
                      />
                      {"  "}
                      {pendingVerificationSellers.length} Pending
                    </p>
                    <p
                      className="card-category"
                      style={{
                        top: "0",
                        margin: "0",
                        padding: "0.5rem 1rem",
                        backgroundColor: "#042326",
                        // borderRadius: "0 0.25rem 0.25rem 0",
                        borderRadius: "0.25rem",

                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "0.875rem",
                        lineHeight: "1.5",
                        textTransform: "capitalize",
                        letterSpacing: "0.04em",
                        border: "1px solid rgba(0,0,0,0.125)",
                        borderTop: "none",
                        borderRight: "none",
                        borderBottom: "none",
                        borderLeft: "1px solid rgba(0,0,0,0.125)",
                      }}
                    >
                      <BlockIcon style={{ color: "#FFEC00", fontSize: 17 }} />
                      {"  "}
                      {deactivatedSellers.length} Deactivated
                    </p>
                  </div>
                </div>

                <Nav variant="tabs" className="mb-1">
                  {/* Verified Vendors */}
                  <Nav.Item>
                    <Nav.Link
                      style={
                        showCategory === "verified"
                          ? { backgroundColor: "#28a745", color: "#fff" }
                          : {
                              backgroundColor: "#f8f9fa",
                              border: "1px solid #dee2e6",
                              color: "#343a40",
                            }
                      }
                      onClick={() => setShowCategory("verified")}
                    >
                      Verified
                    </Nav.Link>
                  </Nav.Item>
                  {/* Pending Verification */}
                  <Nav.Item>
                    <Nav.Link
                      style={
                        showCategory === "pending"
                          ? { backgroundColor: "#17a2b8", color: "#fff" }
                          : {
                              backgroundColor: "#f8f9fa",
                              border: "1px solid #dee2e6",
                              color: "#343a40",
                            }
                      }
                      onClick={() => setShowCategory("pending")}
                    >
                      Pending Verification
                    </Nav.Link>
                  </Nav.Item>

                  {/* Deactivated Vendors */}
                  <Nav.Item>
                    <Nav.Link
                      style={
                        showCategory === "deactivated"
                          ? { backgroundColor: "#6c757d", color: "#fff" }
                          : {
                              backgroundColor: "#f8f9fa",
                              border: "1px solid #dee2e6",
                              color: "#343a40",
                            }
                      }
                      onClick={() => setShowCategory("deactivated")}
                    >
                      Deactivated
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>

              <Col md="12">
                <Card.Body className="table-responsive p-0">
                  <div className="mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="search-container"
                        style={{ width: "350px", position: "relative" }}
                      >
                        <div
                          className="search-wrapper"
                          style={{
                            position: "relative",
                            // boxShadow: "0 2px 6px rgba(0,0,0,0.4)",
                            boxShadow: "0 2px 6px #0FEA52",
                            borderRadius: "24px",
                            backgroundColor: "#fff",
                            marginLeft: "10px",
                          }}
                        >
                          <i
                            className="fas fa-search"
                            style={{
                              position: "absolute",
                              left: "16px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              color: "#6c757d",
                              fontSize: "14px",
                            }}
                          ></i>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={`Search ${showCategory} sellers...`}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                              borderRadius: "24px",
                              padding: "12px 40px 12px 40px",
                              border: "1px solid #e0e0e0",
                              fontSize: "0.9rem",
                              transition: "all 0.2s ease",
                              backgroundColor: "transparent",
                            }}
                          />
                          {searchTerm && (
                            <button
                              className="btn btn-link"
                              onClick={() => setSearchTerm("")}
                              style={{
                                position: "absolute",
                                right: "8px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "#6c757d",
                                padding: "6px",
                                fontSize: "14px",
                              }}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div
                        className="search-stats"
                        style={{
                          backgroundColor: "#f8f9fa",
                          padding: "8px 16px",
                          borderRadius: "20px",
                          fontSize: "0.9rem",
                          color: "#495057",
                          border: "1px solid #e0e0e0",
                          marginRight: "10px",
                          boxShadow: "0 2px 6px #0FEA52",
                        }}
                      >
                        {showCategory === "verified" && (
                          <span>
                            <VerifiedUserIcon
                              style={{
                                color: "#00751E",
                                fontSize: 16,
                                marginRight: "6px",
                              }}
                            />
                            {verifiedSellers.length} verified seller
                            {verifiedSellers.length !== 1 ? "s" : ""}
                          </span>
                        )}
                        {showCategory === "pending" && (
                          <span>
                            <TimelapseIcon
                              style={{
                                color: "#17a2b8",
                                fontSize: 16,
                                marginRight: "6px",
                              }}
                            />
                            {pendingVerificationSellers.length} pending seller
                            {pendingVerificationSellers.length !== 1 ? "s" : ""}
                          </span>
                        )}
                        {showCategory === "deactivated" && (
                          <span>
                            <BlockIcon
                              style={{
                                color: "#6c757d",
                                fontSize: 16,
                                marginRight: "6px",
                              }}
                            />
                            {deactivatedSellers.length} deactivated seller
                            {deactivatedSellers.length !== 1 ? "s" : ""}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Type of Goods</th>
                        <th>CreatedAt</th>
                        <th>Verification Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Verified Sellers */}
                      {showCategory === "verified" &&
                        verifiedSellers.map((verified, index) => (
                          <tr key={verified._id}>
                            <td>{index + 1}.</td>
                            <td>
                              <VerifiedUserIcon
                                style={{ color: "#1DA1F2", fontSize: 18 }}
                              />
                              {"  "}
                              {verified.businessName}{" "}
                            </td>
                            <td>{verified.email}</td>
                            <td>{verified.typeOfGoods}</td>
                            <td>
                              {moment(verified.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              <Chip
                                variant="default"
                                label={verified.sellerVerification}
                                color={
                                  verified.sellerVerification === "VERIFIED"
                                    ? "primary"
                                    : verified.sellerVerification === "PENDING"
                                    ? "secondary"
                                    : "secondary"
                                }
                              />
                            </td>
                            <td>
                              <ButtonToolbar>
                                {["right"].map((placement) => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Tooltip id={`tooltip-${placement}`}>
                                        Vendor Details
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="info"
                                      size="sm"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(getSellerDetail(verified._id));
                                        history.push(
                                          `/admin/sellerDetail/${verified._id}`
                                        );
                                      }}
                                    >
                                      <i className="fas fa-eye"></i>
                                    </Button>
                                  </OverlayTrigger>
                                ))}
                              </ButtonToolbar>
                              <ButtonToolbar>
                                {["right"].map((placement) => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Tooltip id={`tooltip-${placement}`}>
                                        Manage Vendor
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="primary"
                                      size="sm"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(getSellerDetail(verified._id));
                                        history.push(
                                          `/admin/sellerEdit/${verified._id}`
                                        );
                                      }}
                                    >
                                      <i className="fas fa-edit"></i>
                                    </Button>
                                  </OverlayTrigger>
                                ))}
                              </ButtonToolbar>

                              <ButtonToolbar>
                                {["right"].map((placement) => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Tooltip id={`tooltip-${placement}`}>
                                        Delete Seller
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      style={{
                                        cursor:
                                          verified?.sellerVerification ===
                                          "VERIFIED"
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <Button
                                        disabled={
                                          verified?.sellerVerification ===
                                          "VERIFIED"
                                        }
                                        variant="info"
                                        size="sm"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          dispatch(
                                            deleteSeller(
                                              verified?._id,
                                              user?.result?._id
                                            )
                                          );
                                        }}
                                      >
                                        <i className="fas fa-trash"></i>
                                      </Button>
                                    </div>
                                  </OverlayTrigger>
                                ))}
                              </ButtonToolbar>
                            </td>
                          </tr>
                        ))}
                      {/* Pending Verification */}
                      {showCategory === "pending" &&
                        (pendingVerificationSellers.length > 0 ? (
                          pendingVerificationSellers.map((pending, index) => (
                            <tr key={pending._id}>
                              <td>{index + 1}.</td>
                              <td>
                                {" "}
                                <TimelapseIcon
                                  style={{ color: "#FF4A1A", fontSize: 19 }}
                                />
                                {"  "}
                                {pending.businessName}
                              </td>
                              <td>{pending.email}</td>
                              <td>{pending.typeOfGoods}</td>
                              <td>
                                {moment(pending.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                <Chip
                                  variant="default"
                                  label={pending.sellerVerification}
                                  color="secondary"
                                />
                              </td>

                              <td>
                                <ButtonToolbar>
                                  {["right"].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`tooltip-${placement}`}>
                                          View Vendor
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="info"
                                        size="sm"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          dispatch(
                                            getSellerDetail(pending._id)
                                          );
                                          history.push(
                                            `/admin/sellerDetail/${pending._id}`
                                          );
                                        }}
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  ))}
                                </ButtonToolbar>
                                <ButtonToolbar>
                                  {["right"].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`tooltip-${placement}`}>
                                          Manage Vendor
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          dispatch(
                                            getSellerDetail(pending._id)
                                          );
                                          history.push(
                                            `/admin/sellerEdit/${pending._id}`
                                          );
                                        }}
                                      >
                                        <i className="fas fa-edit"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  ))}
                                </ButtonToolbar>

                                {/* Ban Vendor */}
                                <ButtonToolbar>
                                  {["right"].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`tooltip-${placement}`}>
                                          Ban Vendor
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => {
                                          setShowSoftDeleteModal(true);
                                          setCurrentSellerId(pending?._id);
                                        }}
                                      >
                                        <i className="fas fa-ban"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  ))}
                                </ButtonToolbar>
                                <Modal
                                  show={showSoftDeleteModal}
                                  onHide={() => setShowSoftDeleteModal(false)}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      Confirm Vendor ban
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    Are you sure you want to ban this vendor?
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={() =>
                                        setShowSoftDeleteModal(false)
                                      }
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="danger"
                                      onClick={() =>
                                        handleSoftDelete(currentSellerId)
                                      }
                                    >
                                      Ban
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                                {/* End Ban */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={3}
                              style={{
                                textAlign: "left",
                                color: "#9008",
                                fontStyle: "italic",
                                fontSize: "0.875rem",
                              }}
                            >
                              No sellers pending verification...
                            </td>
                          </tr>
                        ))}
                      {/* Deactivated Vendors */}
                      {showCategory === "deactivated" &&
                        (deactivatedSellers.length > 0 ? (
                          deactivatedSellers.map((deactivated, index) => (
                            <tr key={deactivated._id}>
                              <td>{index + 1}.</td>
                              <td>{deactivated.businessName}</td>
                              <td>{deactivated.email}</td>
                              <td>{deactivated.typeOfGoods}</td>
                              <td>
                                {moment(deactivated.createdAt).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <Chip
                                  variant="default"
                                  label="Deactivated"
                                  color="secondary"
                                />
                              </td>

                              <td>
                                <ButtonToolbar>
                                  {["right"].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`tooltip-${placement}`}>
                                          Activate Vendor
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="inherit"
                                        size="md"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          dispatch(
                                            getSellerDetail(deactivated._id)
                                          );
                                          setShowActivateModal(true);
                                          setCurrentSellerId(deactivated?._id);
                                        }}
                                      >
                                        <i className="fas fa-eye-slash"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  ))}
                                </ButtonToolbar>

                                <Modal
                                  show={showActivateModal}
                                  onHide={() => setShowActivateModal(false)}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      Confirm Vendor Activation!
                                    </Modal.Title>{" "}
                                  </Modal.Header>
                                  <hr
                                    style={{
                                      borderColor: "black",
                                      borderWidth: 1,
                                      width: "98%",
                                    }}
                                  />
                                  <Modal.Body>
                                    Are you sure you want to Reactivate this
                                    vendor?
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={() =>
                                        setShowActivateModal(false)
                                      }
                                      style={{ width: "150px" }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="danger"
                                      onClick={() =>
                                        handleActivateVendor(currentSellerId)
                                      }
                                      style={{ width: "150px" }}
                                    >
                                      Activate Vendor
                                    </Button>
                                  </Modal.Footer>
                                </Modal>

                                {/* Hard Delete vendor */}
                                <ButtonToolbar>
                                  {["right"].map((placement) => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Tooltip id={`tooltip-${placement}`}>
                                          Delete Vendor
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="danger"
                                        size="md"
                                        onClick={() => {
                                          setShowDeleteModal(true);
                                          setCurrentSellerId(deactivated?._id);
                                        }}
                                      >
                                        <i className="fas fa-trash"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  ))}
                                </ButtonToolbar>
                                <Modal
                                  show={showDeleteModal}
                                  onHide={() => setShowDeleteModal(false)}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      Confirm Deletion (Pending Verification
                                      Vendor)
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    Are you sure you want to delete this vendor?
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={() => setShowDeleteModal(false)}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="danger"
                                      onClick={() =>
                                        handleDelete(currentSellerId)
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                                {/* End Delete */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={3}
                              style={{
                                textAlign: "left",
                                color: "#9008",
                                fontStyle: "italic",
                                fontSize: "0.875rem",
                              }}
                            >
                              No deactivated sellers...
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Sellers;
