import {
  FETCH_OUTLETS_REQUEST,
  FETCH_OUTLETS_SUCCESS,
  FETCH_OUTLETS_FAILURE,
  CREATE_OUTLET,
  UPDATE_OUTLET_SUCCESS,
  DELETE_OUTLET_SUCCESS,
  UPDATE_OUTLET_MENU_ITEMS_REQUEST,
  UPDATE_OUTLET_MENU_ITEMS_SUCCESS,
  UPDATE_OUTLET_MENU_ITEMS_FAILURE,
  TOGGLE_OUTLET_ACTIVATION_REQUEST,
  TOGGLE_OUTLET_ACTIVATION_SUCCESS,
  TOGGLE_OUTLET_ACTIVATION_FAILURE,
} from "../constants/actionTypes";

const initialState = {
  outlets: [], // Initialize as an empty array
  isLoading: false,
  error: null,
};

const outletReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OUTLETS_REQUEST:
      return { ...state, isLoading: true, error: null };
    case FETCH_OUTLETS_SUCCESS:
      return { ...state, isLoading: false, outlets: action.payload };
    case FETCH_OUTLETS_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case CREATE_OUTLET:
      return { ...state, outlets: [...state.outlets, action.payload] };

    case UPDATE_OUTLET_SUCCESS:
      return {
        ...state,
        outlets: state.outlets.map((outlet) =>
          outlet._id === action.payload._id ? action.payload : outlet
        ),
      };
    case DELETE_OUTLET_SUCCESS:
      return {
        ...state,
        outlets: state.outlets.filter(
          (outlet) => outlet._id !== action.payload
        ),
      };
    case UPDATE_OUTLET_MENU_ITEMS_REQUEST:
      return { ...state, isLoading: true, error: null };

    case UPDATE_OUTLET_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        outlets: state.outlets.map((outlet) =>
          outlet._id === action.payload._id
            ? { ...outlet, menuItems: action.payload.menuItems }
            : outlet
        ),
      };

    case UPDATE_OUTLET_MENU_ITEMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case TOGGLE_OUTLET_ACTIVATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case TOGGLE_OUTLET_ACTIVATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        outlets: state.outlets.map((outlet) =>
          outlet._id === action.payload._id
            ? { ...outlet, ...action.payload }
            : outlet
        ),
        error: null,
      };

    case TOGGLE_OUTLET_ACTIVATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default outletReducer;
