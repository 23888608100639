import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Container,
  Card,
  Table,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import { Chip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import useStyles from "../Forms/Listings/Listing/orderDetailsStyles";
import {
  getPaginatedOrders,
  getPaginatedOrdersBySearch,
} from "../../actions/mobileOrdersAction";
import PaginatedOrders from "../../views/Forms/Listings/PaginatedOrders.jsx";
import { getUserFromLocalStorage } from "../../utilities/user.js";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PaginatedOrderTable = () => {
  const user = getUserFromLocalStorage();
  const { mobileOrders, isLoading } = useSelector(
    (state) => state.mobileOrders
  );

  const [search, setSearch] = useState("");

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const page = query.get("page") || 1;

  useEffect(() => {
    if (page) {
      dispatch(getPaginatedOrders(page));
    }
  }, [dispatch, page]);

  if (isLoading) {
    return (
      <Spinner
        animation="grow"
        variant="danger"
        style={{
          marginTop: "70px",
          top: "50%",
          left: "50%",
          position: "fixed",
        }}
      />
    );
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (search.length > 0) {
        filterOrders();
      } else {
        dispatch(getPaginatedOrders(page));
        history.replace("/admin/paginatedOrdersTable");
        setSearch("");
      }
    }
  };

  const filterOrders = () => {
    if (search.trim()) {
      dispatch(getPaginatedOrdersBySearch({ search }));
      history.push(
        `/admin/paginatedOrdersTable?searchQuery=${search || "none"}`
      );
    } else {
      dispatch(getPaginatedOrders(page));
      history.replace("/admin/paginatedOrdersTable");
      setSearch("");
    }
  };

  const backToOrders = () => {
    history.push("/admin/paginatedOrdersTable");
    setSearch("");
  };

  const handleInputClear = (e) => {
    setSearch(e.target.value);

    if (e.target.value === "") {
      dispatch(getPaginatedOrders(page));
      history.replace("/admin/paginatedOrdersTable");
    }
  };

  const handleOrderManage = async (order) => {
    try {
      if (order.isPackageDelivery) {
        history.push(`/admin/package-delivery/${order._id}`);
        return;
      }
      for (const item of order.orderItems) {
        // console.log("Item:", item);
        if (
          (user?.result?.user_role === "SUPERUSER" &&
            item.vendorId &&
            item.vendorId.operationMode === "RETAILER") ||
          mobileOrders[0]?.isMultiRetailer
        ) {
          await history.push(`/admin/vendorOrders-mng/${order._id}`);
          return;
        }
        if (item.vendorId && item.vendorId.operationMode === "RESTAURANT") {
          await history.push(`/admin/restaurantOrders-mng/${order._id}`);
          return;
        }
      }
      await history.push(`/admin/orderDetails/${order._id}`);
    } catch (error) {
      console.error("Error navigating:", error);
    }
  };

  const tableStyles = {
    pageContainer: {
      padding: "24px",
      backgroundColor: "#f5f7fa",
      minHeight: "100vh",
    },
    card: {
      borderRadius: "16px",
      border: "none",
      backgroundColor: "white",
      boxShadow:
        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
    },
    cardHeader: {
      background: "#fff",
      borderBottom: "1px solid #f0f0f0",
      padding: "24px 32px",
    },
    cardTitle: {
      color: "#212B36",
      fontSize: "24px",
      fontWeight: "700",
      marginBottom: "4px",
    },
    cardSubtitle: {
      color: "#637381",
      fontSize: "14px",
    },
    searchContainer: {
      padding: "24px 32px",
      borderBottom: "1px solid #f0f0f0",
    },
    searchWrapper: {
      display: "flex",
      gap: "16px",
      alignItems: "center",
    },
    searchInput: {
      flex: 1,
      padding: "12px 16px",
      borderRadius: "8px",
      border: "1px solid #e0e3e7",
      fontSize: "14px",
      transition: "all 0.2s ease",
      backgroundColor: "#f8fafc",
      "&:focus": {
        borderColor: "#3366FF",
        backgroundColor: "#fff",
        boxShadow: "0 0 0 2px rgba(51, 102, 255, 0.1)",
      },
    },
    searchButton: {
      padding: "12px 24px",
      borderRadius: "8px",
      backgroundColor: "#3366FF",
      color: "white",
      border: "none",
      fontWeight: "600",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: "#2952CC",
      },
    },
    tableContainer: {
      padding: "0 32px 32px 32px",
    },
    table: {
      borderCollapse: "separate",
      borderSpacing: "0",
      width: "100%",
    },
    tableHeader: {
      backgroundColor: "#F9FAFB",
      color: "#637381",
      fontSize: "13px",
      fontWeight: "600",
      textTransform: "uppercase",
      letterSpacing: "0.5px",
      padding: "12px 16px",
      borderBottom: "1px solid #f0f0f0",
    },
    tableCell: {
      padding: "16px",
      fontSize: "14px",
      color: "#212B36",
      borderBottom: "1px solid #f0f0f0",
    },
    statusChip: {
      padding: "4px 12px",
      borderRadius: "6px",
      fontSize: "13px",
      fontWeight: "600",
    },
    actionButton: {
      padding: "8px",
      borderRadius: "8px",
      border: "1px solid #e0e3e7",
      backgroundColor: "transparent",
      color: "#637381",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: "#F9FAFB",
        color: "#3366FF",
      },
    },
  };

  return (
    <div style={tableStyles.pageContainer}>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card style={tableStyles.card}>
              <Card.Header style={tableStyles.cardHeader}>
                <h4 style={tableStyles.cardTitle}>Orders Management</h4>
                <p style={tableStyles.cardSubtitle}>
                  View and manage all customer orders
                </p>
              </Card.Header>

              <div style={tableStyles.searchContainer}>
                <div style={tableStyles.searchWrapper}>
                  <input
                    type="search"
                    placeholder="Search orders by item, customer, or order number..."
                    style={tableStyles.searchInput}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={handleKeyPress}
                    onInput={handleInputClear}
                  />
                  <Button
                    style={tableStyles.searchButton}
                    onClick={filterOrders}
                  >
                    <i className="fas fa-search me-2"></i>
                    Search
                  </Button>
                </div>
              </div>

              <div style={tableStyles.tableContainer}>
                <Table style={tableStyles.table} responsive>
                  <thead>
                    <tr>
                      <th style={tableStyles.tableHeader}>#.</th>
                      <th style={tableStyles.tableHeader}>Order Details</th>
                      <th style={tableStyles.tableHeader}>Order Source</th>
                      <th style={tableStyles.tableHeader}>Customer</th>
                      <th style={tableStyles.tableHeader}>Amount</th>
                      <th style={tableStyles.tableHeader}>Payment Status</th>
                      <th style={tableStyles.tableHeader}>Delivery Status</th>
                      <th style={tableStyles.tableHeader}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mobileOrders?.map((item, index) => (
                      <tr
                        key={item?._id}
                        style={{
                          transition: "background-color 0.2s ease",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "#F9FAFB")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor =
                            "transparent")
                        }
                      >
                        <td style={tableStyles.tableCell}>{index + 1}.</td>
                        <td style={tableStyles.tableCell}>
                          <div style={{ fontWeight: "600" }}>
                            #{item?.momo_id?.order_number || item?._id}
                          </div>
                          <div style={{ color: "#637381", fontSize: "13px" }}>
                            {moment(item?.createdAt).format("MMM DD, YYYY")}
                          </div>
                        </td>
                        <td style={tableStyles.tableCell}>
                          <Chip
                            label={item?.orderSrc}
                            style={{
                              ...tableStyles.statusChip,
                              backgroundColor: "#EFF6FF",
                              color: "#1E40AF",
                            }}
                          />
                        </td>
                        <td style={tableStyles.tableCell}>
                          <div>
                            {item?.client_id?.name || item?.customerName}
                          </div>
                          <div style={{ color: "#637381", fontSize: "13px" }}>
                            {item?.shippingAddress?.customerNumber}
                          </div>
                        </td>
                        <td style={tableStyles.tableCell}>
                          <div style={{ color: "#00AB55", fontWeight: "600" }}>
                            Shs{" "}
                            {item?.totalPrice.toLocaleString() ||
                              item?.itemsPrice.toLocaleString()}
                          </div>
                          <div style={{ color: "#637381", fontSize: "13px" }}>
                            {item?.paymentMethod}
                          </div>
                        </td>
                        <td style={tableStyles.tableCell}>
                          <Chip
                            label={item?.payment_status || "PENDING"}
                            style={{
                              ...tableStyles.statusChip,
                              backgroundColor:
                                item?.payment_status === "PAID"
                                  ? "#E5F6ED"
                                  : item?.payment_status === "FAILED"
                                  ? "#FFE9E9"
                                  : "#FFF7CD",
                              color:
                                item?.payment_status === "PAID"
                                  ? "#00AB55"
                                  : item?.payment_status === "FAILED"
                                  ? "#FF4842"
                                  : "#B78103",
                            }}
                          />
                        </td>
                        <td style={tableStyles.tableCell}>
                          <Chip
                            label={item?.delivery_status}
                            style={{
                              ...tableStyles.statusChip,
                              backgroundColor:
                                item.delivery_status === "DELIVERED"
                                  ? "#E5F6ED"
                                  : item.delivery_status === "REJECTED"
                                  ? "#FFE9E9"
                                  : "#FFF7CD",
                              color:
                                item.delivery_status === "DELIVERED"
                                  ? "#00AB22"
                                  : item.delivery_status === "REJECTED"
                                  ? "#FF4842"
                                  : "#B78103",
                            }}
                          />
                        </td>
                        <td style={tableStyles.tableCell}>
                          <Button
                            style={tableStyles.actionButton}
                            onClick={() => handleOrderManage(item)}
                          >
                            <i className="fas fa-edit"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
        <div style={{ marginTop: "24px" }}>
          <PaginatedOrders page={page} />
        </div>
      </Container>
    </div>
  );
};

export default PaginatedOrderTable;
