import {
  FETCH_ALL,
  FETCH_DEACTIVATED_LISTINGS,
  FETCH_OUT_OF_STOCK_LISTINGS,
  START_LOADING,
  END_LOADING,
  CREATE_LISTING,
  CREATE_PROMO_LISTING,
  FETCH_LISTING,
  UPDATE,
  DELETE_LISTING,
  UPDATE_LISTING,
  DEACTIVATE_LISTING,
  REACTIVATE_LISTING,
  FETCH_BY_SEARCH,
  VERIFY_LISTING,
  UNVERIFY_LISTING,
  ADD_COLLECTION,
} from "../constants/actionTypes.js";

import * as api from "../api/index.js";
import { toast } from "react-toastify";

export const createListing = (post, userId) => async (dispatch) => {
  try {
    // dispatch({ type: START_LOADING });
    const { data } = await api.apiCreateListing(post, userId);
    dispatch({ type: CREATE_LISTING, payload: data });
    dispatch({ type: END_LOADING });
    toast.success("Listing created successfully");
  } catch (error) {
    console.log(error.response.data.message);
    toast.error(error.response.data.message);
  }
};

export const createPromoListing = (post, userId) => async (dispatch) => {
  try {
    // dispatch({ type: START_LOADING });
    const { data } = await api.apiCreatePromoListing(post, userId);
    dispatch({ type: CREATE_PROMO_LISTING, payload: data });
    dispatch({ type: END_LOADING });
    toast.success("Listing created successfully");
  } catch (error) {
    console.log(error.response.data.message);
    toast.error(error.response.data.message);
  }
};

export const getListing = (listing_id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.apifetchListing(listing_id);

    dispatch({ type: FETCH_LISTING, payload: { listing: data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const getListingsBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const {
      data: { data },
    } = await api.fetchListingsBySearch(searchQuery);
    // console.log(data, "Data");

    dispatch({ type: FETCH_BY_SEARCH, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

export const getListings = (userId, page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data, currentPage, numberOfPages },
    } = await api.apifetchListings(userId, page);

    dispatch({
      type: FETCH_ALL,
      payload: { data, currentPage, numberOfPages },
    });
    dispatch({ type: END_LOADING });
  } catch (error) {
    // console.log(error.response.data.message, ">>>>");
    toast.error(error.response.data.message);
  }
};

export const updateListing = (listingId, post, userId) => async (dispatch) => {
  try {
    const { data } = await api.apiUpdateListing(listingId, post, userId);

    dispatch({ type: UPDATE_LISTING, payload: data });
    toast.success(data.message);
  } catch (error) {
    console.log(error);
    // toast.error("Only Admins can Update!");
    toast.error(error.response.data.message);
  }
};

// Deactivate Listing
export const deactivateSku = (listingId, user_id) => async (dispatch) => {
  try {
    const { data } = await api.apiDeactivateListing(listingId, user_id);

    dispatch({ type: DEACTIVATE_LISTING, payload: data });
    toast.success(data.message);
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

// Reactivate Listing
// export const reactivateSkuX = (listingId, user_id) => async (dispatch) => {
//   try {
//     const { data } = await api.apiReactivateListing(listingId, user_id);

//     dispatch({ type: REACTIVATE_LISTING, payload: data });

//     // Dispatch to get the deactivated listings
//     toast.success(data.message);
//   } catch (error) {
//     console.log(error);
//     toast.error(error);
//   }
// };

export const reactivateSku = (listingId, user_id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.apiReactivateListing(listingId, user_id);
      dispatch({ type: REACTIVATE_LISTING, payload: data });
      toast.success(data.message);
      resolve();
    } catch (error) {
      console.log(error);
      toast.error(error);
      reject();
    }
  });
};

// Verify Listing
export const verifySellerListing =
  (listingId, user_id, verifyListing) => async (dispatch) => {
    try {
      const { data } = await api.apiVerifySellerListing(
        listingId,
        user_id,
        verifyListing
      );

      dispatch({ type: VERIFY_LISTING, payload: data });
      toast.success(data.message);
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

// Unverify Listing
export const unverifySellerListing =
  (listingId, user_id, verifyListing) => async (dispatch) => {
    try {
      const { data } = await api.apiUnverifySellerListing(
        listingId,
        user_id,
        verifyListing
      );

      dispatch({ type: UNVERIFY_LISTING, payload: data });
      toast.success(data.message);
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

// Get deactivated listings
export const getDeactivatedListings = (user_id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    // const { data } = await axios.get("/posts/deactivated-listings");
    const { data } = await api.apiGetDeactivatedListings(user_id);
    dispatch({
      type: FETCH_DEACTIVATED_LISTINGS,
      payload: data,
    });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

//Get out of stock listings
export const getOuttaStockListings = (user_id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.apiGetOuttaStockListings(user_id);
    dispatch({
      type: FETCH_OUT_OF_STOCK_LISTINGS,
      payload: data,
    });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const deleteListing = (listingId, userId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.deleteListing(listingId, userId);

      if (data) {
        dispatch({ type: DELETE_LISTING, payload: listingId });
        // Remove from all listing states
        dispatch({ type: END_LOADING });
        // Refresh the listings
        await dispatch(getListings(userId));
        toast.success(data.message);
        resolve(data);
      }
    } catch (error) {
      dispatch({ type: END_LOADING });
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "Error deleting listing";
      console.error("Delete listing error:", errorMessage);
      toast.error(errorMessage);
      reject(error);
    }
  });
};

// Create Product

// Create Collection
// export const addCollection = (collectionData) => async (dispatch) => {
//   try {
//     dispatch({ type: START_LOADING });
//     const { data } = await api.apiAddCollection(collectionData);
//     dispatch({ type: ADD_COLLECTION, payload: data });
//     dispatch({ type: END_LOADING });
//     toast.success("Collection added successfully");
//   } catch (error) {
//     console.log(error.response?.data?.message || error.message);
//     toast.error(error.response?.data?.message || error.message);
//     dispatch({ type: END_LOADING });
//   }
// };
