import React, { useState } from "react";
import axios from "axios";

const CreateWhatsAppTemplate = () => {
  const [template, setTemplate] = useState({
    name: "",
    language: "en",
    category: "MARKETING",
    component: {
      header: {
        format: "TEXT",
        text: "",
        example: "",
      },
      body: {
        text: "",
        examples: ["", ""],
      },
      footer: {
        text: "",
      },
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      [name]: value,
    }));
  };

  const handleComponentChange = (e, component, field) => {
    const { value } = e.target;
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      component: {
        ...prevTemplate.component,
        [component]: {
          ...prevTemplate.component[component],
          [field]: value,
        },
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/whatsapp/templates", template, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Template created:", response.data);
    } catch (error) {
      console.error("Error creating template:", error);
    }
  };

  const formStyle = {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const inputStyle = {
    width: "100%",
    padding: "10px",
    marginBottom: "20px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "16px",
  };

  const buttonStyle = {
    width: "100%",
    padding: "10px",
    backgroundColor: "#333",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
  };

  return (
    <form style={formStyle} onSubmit={handleSubmit}>
      <h2>Create WhatsApp Template</h2>
      <label>
        Template Name:
        <input
          type="text"
          name="name"
          value={template.name}
          onChange={handleChange}
          placeholder="Enter template name"
          required
          style={inputStyle}
        />
      </label>
      <label>
        Header Text:
        <input
          type="text"
          value={template.component.header.text}
          onChange={(e) => handleComponentChange(e, "header", "text")}
          placeholder="Enter header text"
          required
          style={inputStyle}
        />
      </label>
      <label>
        Header Example:
        <input
          type="text"
          value={template.component.header.example}
          onChange={(e) => handleComponentChange(e, "header", "example")}
          placeholder="Enter header example"
          style={inputStyle}
        />
      </label>
      <label>
        Body Text:
        <textarea
          value={template.component.body.text}
          onChange={(e) => handleComponentChange(e, "body", "text")}
          placeholder="Enter body text"
          required
          style={inputStyle}
        />
      </label>
      <label>
        Footer Text:
        <input
          type="text"
          value={template.component.footer.text}
          onChange={(e) => handleComponentChange(e, "footer", "text")}
          placeholder="Enter footer text"
          style={inputStyle}
        />
      </label>
      <button type="submit" style={buttonStyle}>
        Create Template
      </button>
    </form>
  );
};

export default CreateWhatsAppTemplate;
