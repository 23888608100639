import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Alert,
  Form,
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import {
  MdAddAPhoto,
  MdWarning,
  MdCrop,
  MdCropSquare,
  MdCropLandscape,
  MdDelete,
} from "react-icons/md";
import { Switch, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  fetchRetailerWholesalerSections,
  fetchRetailerWholesalers,
} from "../actions/retailerWholesalerSectionActions";
import { fetchRetailerWholesalerOptionGroups } from "../actions/retailerWholesalerOptionActions";
// import OptionGroupForm from './OptionsGroupForm';
import { createRetailerWholesalerProduct } from "../actions/retailerWholesalerProductActions";
import { getUserFromLocalStorage } from "../utilities/user.js";
import RetailerWholesalerOptionGroupForm from "./RetailerWholesalerOptionGroupForm";
import { getSiUnits } from "actions/siUnitActions";
import ReactCrop from "react-image-crop";
import { centerCrop, makeAspectCrop } from "react-image-crop";
import { IconButton, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "normal",
    fontSize: "12px",
    textTransform: "capitalize",
  },
}));

const CreateRetailerWholesalerProductForm = () => {
  const user = getUserFromLocalStorage();
  const vendorId = user?.result?._id;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const sections = useSelector(
    (state) => state.retailerWholesalerSections.sectionsRW
  );
  const retailersWholesalers = useSelector(
    (state) => state.retailerWholesalerSections.retailersWholesalers
  );
  const optionGroups = useSelector(
    (state) => state.retailerWholesalerOptions.optionGroups
  );

  const { siUnits, loading } = useSelector((state) => state.siUnits);

  const isMounted = useRef(true);

  useEffect(() => {
    dispatch(fetchRetailerWholesalerSections(vendorId));
    dispatch(fetchRetailerWholesalers(vendorId));
    dispatch(fetchRetailerWholesalerOptionGroups(vendorId));

    return () => {
      isMounted.current = false;
    };
  }, [dispatch]);

  //   Get Units
  useEffect(() => {
    dispatch(getSiUnits());
  }, []);

  useEffect(() => {
    const mappedUnits = siUnits.map((unit) => ({
      value: unit._id,
      label: unit.unit,
      name: unit.unit,
    }));
    setUnits(mappedUnits);
  }, [siUnits]);

  const initialSection = location.state?.sectionId || "";

  const [product, setProduct] = useState({
    name: "",
    description: "",
    section: initialSection,
    retailerWholesaler: "",
    price: 0.0,
    countInStock: 0,
    image: null,
    enabled: true,
    optionGroups: [],
    measurements: 1,
  });

  const [priceWarning, setPriceWarning] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [isFormatCorrect, setIsFormatCorrect] = useState(false);
  const [isSizeCorrect, setIsSizeCorrect] = useState(false);
  const [isResolutionCorrect, setIsResolutionCorrect] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);

  const [selectedOptionGroups, setSelectedOptionGroups] = useState([]);
  const [showOptionGroupModal, setShowOptionGroupModal] = useState(false);

  const [minimumOrderVolume, setMinimumOrderVolume] = useState(10);
  const [operationMode, setOperationMode] = useState("");

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [inputValue, setValue] = useState("");

  const [currentImage, setCurrentImage] = useState(null);
  const previewCanvasRef = useRef(null);

  const [aspect, setAspect] = useState(undefined);
  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 90,
    height: 90,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);

  const ASPECT_RATIO = 1; // For 1:1 square crop
  const MIN_DIMENSION = 1000; // Minimum dimension requirement
  const PREVIEW_CONTAINER_SIZE = 300; // or whatever size you want for preview

  useEffect(() => {
    if (retailersWholesalers.length > 0) {
      setOperationMode(retailersWholesalers[0].operationMode);
    }
  }, [retailersWholesalers]);

  // Category Select
  const loadCategory = useCallback(async (inputValue) => {
    const res = await fetch("/category/get-all");
    const categories = await res.json();
    if (!inputValue) {
      return categories;
    }

    const filteredCategories = categories.filter((category) =>
      category.category.toLowerCase().includes(inputValue.toLowerCase())
    );

    return filteredCategories;
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "price") {
      if (parseFloat(value) < 0) {
        setPriceWarning("Please enter a Price higher than 0.");
        setProduct((prev) => ({ ...prev, [name]: "" }));
        return;
      } else {
        setPriceWarning("");
      }
    }

    if (name === "countInStock" && value < 1) {
      toast.error("Count in Stock cannot be less than 1");
      return;
    }

    setProduct({ ...product, [name]: value });
  };

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
          height: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const handleImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    let initialCrop;

    if (aspect) {
      initialCrop = centerAspectCrop(width, height, aspect);
    } else {
      // For free crop, just use default values
      initialCrop = {
        unit: "%",
        x: 10,
        y: 10,
        width: 80,
        height: 80,
      };
    }

    setCrop(initialCrop);
    setCompletedCrop(initialCrop);
  };

  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      const image = imgRef.current;
      const canvas = previewCanvasRef.current;
      const crop = completedCrop;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext("2d");

      const pixelRatio = window.devicePixelRatio;

      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    }
  }, [completedCrop]);

  const handleSaveCrop = useCallback(async () => {
    if (!imgRef.current || !completedCrop) return;

    const canvas = document.createElement("canvas");
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = completedCrop.width * scaleX;
    canvas.height = completedCrop.height * scaleY;

    const ctx = canvas.getContext("2d");
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Convert canvas to base64 string for preview
    const base64Image = canvas.toDataURL("image/jpeg", 1.0);

    // Also store the blob for later upload
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }

        setProduct((prev) => ({
          ...prev,
          image: blob, // Store the blob for later upload
        }));
        setImagePreview(base64Image); // Use base64 for preview
        setShowPreviewModal(false);
        setIsResolutionCorrect(true);
      },
      "image/jpeg",
      1
    );
  }, [completedCrop]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setUploadError("No file was selected.");
      toast.error("No file was selected.");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setCrop({
        unit: "%",
        x: 0,
        y: 0,
        width: 90,
        height: 90,
      });
      setCompletedCrop(null);

      const img = new Image();
      img.onload = () => {
        if (!isMounted.current) return;

        const validFormats = ["image/jpeg", "image/png"];
        const isCurrentFormatCorrect = validFormats.includes(file.type);
        const isCurrentSizeCorrect = file.size <= 10 * 1024 * 1024;

        setIsFormatCorrect(isCurrentFormatCorrect);
        setIsSizeCorrect(isCurrentSizeCorrect);
        setImagePreview(reader.result);
        setShowPreviewModal(true);

        if (!isCurrentFormatCorrect || !isCurrentSizeCorrect) {
          let errorMessage =
            "Please ensure the image meets all the requirements:";
          if (!isCurrentFormatCorrect)
            errorMessage += " Must be JPG or PNG format.";
          if (!isCurrentSizeCorrect)
            errorMessage += " Must be less than or equal to 10MB.";
          setUploadError(errorMessage);
        } else {
          setUploadError(null);
        }
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const uploadImageToCloudinary = async (file) => {
    if (!file) {
      toast.error("No image file selected!");
      return null;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "lusuku-clou");
    formData.append("cloud_name", "MM-iCT");
    formData.append("folder", "lusuku_menus");

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLOUDINARY_URL,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (!isMounted.current) return;
            setUploadProgress(percentCompleted);
          },
        }
      );
      const secureUrl = response.data.secure_url;
      if (!isMounted.current) return null;

      setProduct((prevProduct) => ({
        ...prevProduct,
        image: secureUrl,
      }));
      setUploading(false);
      setShowPreviewModal(false);
      return secureUrl;
    } catch (error) {
      if (!isMounted.current) return null;

      console.error("Error uploading the image:", error);
      toast.error(
        `Failed to upload image: ${
          error.response?.data?.message || error.message
        }`
      );
      setUploading(false);
      setShowPreviewModal(false);
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    setUploading(true);

    try {
      // First upload image to Cloudinary if it exists and is a Blob
      let imageUrl = product.image;
      if (product.image instanceof Blob) {
        const formData = new FormData();
        formData.append("file", product.image);
        formData.append("upload_preset", "lusuku-clou");
        formData.append("cloud_name", "MM-iCT");
        formData.append("folder", "lusuku_menus");

        const response = await axios.post(
          process.env.REACT_APP_CLOUDINARY_URL,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              if (!isMounted.current) return;
              setUploadProgress(percentCompleted);
            },
          }
        );

        imageUrl = response.data.secure_url;
      }

      // Now create the product with the Cloudinary URL
      const retailerWholesalerProductData = {
        name: product.name,
        description: product.description,
        sectionId: product.section,
        retailerWholesalerId: product.retailerWholesaler,
        price: product.price,
        image: imageUrl,
        countInStock: product.countInStock,
        enabled: product.enabled,
        optionGroups: selectedOptionGroups.map((group) => group.value),
        unit_id: selectedUnit?.value,
        measurements: product.measurements,
        category: selectedCategory,
      };

      await dispatch(
        createRetailerWholesalerProduct(
          retailerWholesalerProductData,
          user?.result?._id
        )
      );

      if (isMounted.current) {
        // Reset form and redirect
        setProduct({
          name: "",
          description: "",
          section: "",
          retailerWholesaler: "",
          price: 0.0,
          countInStock: 0,
          image: null,
          enabled: true,
          optionGroups: [],
          measurements: 1,
        });
        setSelectedOptionGroups([]);
        setSelectedUnit([]);
        setMinimumOrderVolume(10);
        setShowConfirmModal(false);
        history.push("/admin/catalog");
      }
    } catch (error) {
      if (isMounted.current) {
        toast.error("Failed to save product: " + error.message);
      }
    } finally {
      if (isMounted.current) {
        setUploading(false);
        setSubmitting(false);
      }
    }
  };

  const handleDiscardChanges = () => {
    setProduct({
      name: "",
      description: "",
      section: "",
      retailerWholesaler: "",
      price: 0.0,
      countInStock: 0,
      image: null,
      enabled: true,
    });
    setShowConfirmModal(false);
    history.push("/admin/catalog");
  };

  const handleCloseDiscardModal = () => {
    setShowConfirmModal(false);
  };

  const handleOptionGroupSave = (newOptionGroup) => {
    if (newOptionGroup && newOptionGroup._id) {
      setSelectedOptionGroups((prevGroups) => [
        ...prevGroups,
        {
          value: newOptionGroup._id,
          label: `${newOptionGroup.name} (Min: ${newOptionGroup.minOptions}, Max: ${newOptionGroup.maxOptions})`,
        },
      ]);
    } else {
      console.error("Invalid option group data:", newOptionGroup);
    }
  };

  // handle Unit Selection
  const handleUnitChange = (value) => {
    setSelectedUnit(value);
  };

  // handle selection
  const handleCategoryInputChange = (value) => {
    setValue(value);
  };

  // handle category Selection
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleAspectChange = (newAspect) => {
    setAspect(newAspect);

    if (imgRef.current) {
      const { width, height } = imgRef.current;

      // Calculate new crop based on aspect ratio
      let newCrop;
      if (newAspect) {
        // For fixed aspect ratios (square or landscape)
        const smallerDimension = Math.min(width, height);
        const cropWidth =
          newAspect >= 1 ? smallerDimension * newAspect : smallerDimension;
        const cropHeight =
          newAspect >= 1 ? smallerDimension : smallerDimension / newAspect;

        newCrop = centerAspectCrop(width, height, newAspect);
      } else {
        // For free crop
        newCrop = {
          unit: "%",
          x: 10,
          y: 10,
          width: 80,
          height: 80,
        };
      }

      setCrop(newCrop);
      setCompletedCrop(newCrop);
    }
  };

  const isCropValid = useCallback(() => {
    if (!completedCrop?.width || !completedCrop?.height) return false;

    const pixelWidth = imgRef.current
      ? completedCrop.width * (imgRef.current.naturalWidth / 100)
      : 0;
    const pixelHeight = imgRef.current
      ? completedCrop.height * (imgRef.current.naturalHeight / 100)
      : 0;

    // For fixed aspect ratios, check minimum dimensions
    if (aspect) {
      return pixelWidth >= MIN_DIMENSION && pixelHeight >= MIN_DIMENSION;
    }

    // For free crop, ensure reasonable minimum size (e.g., 100px)
    const MIN_FREE_CROP = 100;
    return pixelWidth >= MIN_FREE_CROP && pixelHeight >= MIN_FREE_CROP;
  }, [completedCrop, aspect]);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={12}>
          <Card style={{ padding: "30px" }}>
            <Card.Title as="h3">Create Product</Card.Title>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>1. Product Image</Form.Label>
                  <div className="d-flex">
                    <Card style={{ width: "100%", maxWidth: "400px" }}>
                      <Card.Body
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{
                          minHeight: `${PREVIEW_CONTAINER_SIZE}px`,
                          backgroundColor: "#f8f9fa",
                          border: "2px dashed #dee2e6",
                          borderRadius: "4px",
                          position: "relative",
                        }}
                      >
                        {product.image ? (
                          <div
                            className="position-relative"
                            style={{
                              width: "100%",
                              height: `${PREVIEW_CONTAINER_SIZE}px`,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={
                                typeof product.image === "string"
                                  ? product.image
                                  : imagePreview
                              }
                              alt="Product"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                                objectPosition: "center",
                              }}
                              onLoad={(e) => {
                                const img = e.target;
                                const { naturalWidth, naturalHeight } = img;
                                const isValidDimensions =
                                  naturalWidth >= 1000 && naturalHeight >= 1000;
                                setIsResolutionCorrect(isValidDimensions);
                              }}
                            />
                            {!isResolutionCorrect && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  background: "rgba(220, 53, 69, 0.9)",
                                  color: "white",
                                  padding: "4px 8px",
                                  fontSize: "0.75rem",
                                  textAlign: "center",
                                }}
                              >
                                Dimensions: ≥ 1000x1000px ✘
                              </div>
                            )}
                            <div
                              className="d-flex justify-content-center gap-3 mt-3"
                              style={{
                                position: "absolute",
                                bottom: -50,
                                width: "100%",
                              }}
                            >
                              <Button
                                variant="outline-success"
                                size="sm"
                                onClick={() => {
                                  setImagePreview(
                                    typeof product.image === "string"
                                      ? product.image
                                      : URL.createObjectURL(product.image)
                                  );
                                  setShowPreviewModal(true);
                                }}
                                className="image-action-button"
                                disabled={submitting}
                              >
                                <MdCrop
                                  size={18}
                                  style={{ marginRight: "6px" }}
                                />
                                Replace Image
                              </Button>
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() => {
                                  setProduct({ ...product, image: null });
                                  setImagePreview(null);
                                }}
                                className="image-action-button"
                                disabled={submitting}
                              >
                                <MdDelete
                                  size={18}
                                  style={{ marginRight: "6px" }}
                                />
                                Remove Image
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <Button
                            variant="primary"
                            as="label"
                            className="d-flex align-items-center justify-content-center"
                            style={{ color: "#fff" }}
                            disabled={submitting}
                          >
                            <MdAddAPhoto size={24} className="me-2" />
                            Add Image
                            <Form.Control
                              type="file"
                              hidden
                              accept=".jpg, .png, .jpeg"
                              onChange={handleImageChange}
                              disabled={submitting}
                            />
                          </Button>
                        )}
                      </Card.Body>
                    </Card>
                    <div className="ms-3">
                      <Form.Text className="text-muted">
                        <p style={{ marginBottom: "0" }}>
                          <strong>File requirements: </strong>
                        </p>
                        <p style={{ fontStyle: "italic", fontSize: "11px" }}>
                          JPG or PNG up to 10 MB. Minimum size of 1000x1000
                          pixels.
                        </p>
                        {product.image && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Button
                              variant="link"
                              as="label"
                              style={{
                                color: "green",
                                textDecoration: "none",
                                textTransform: "capitalize",
                                fontSize: "12px",
                              }}
                              onClick={() => {
                                setImagePreview(
                                  typeof product.image === "string"
                                    ? product.image
                                    : URL.createObjectURL(product.image)
                                );
                                setShowPreviewModal(true);
                              }}
                              disabled={submitting}
                            >
                              Edit Image
                            </Button>
                            <span
                              style={{
                                borderLeft: "1px solid #ccc",
                                height: "30px",
                              }}
                            ></span>
                            <Button
                              variant="link"
                              style={{
                                color: "red",
                                textDecoration: "none",
                                fontSize: "12px",
                              }}
                              onClick={() =>
                                setProduct({ ...product, image: null })
                              }
                              disabled={submitting}
                            >
                              Remove Image
                            </Button>
                          </div>
                        )}
                      </Form.Text>
                    </div>
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>2. Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={product.name}
                    onChange={handleInputChange}
                    required
                    placeholder="Product name..."
                    disabled={submitting}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>3. Description (optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={product.description}
                    onChange={handleInputChange}
                    style={{ height: "100px" }}
                    placeholder="Enter a brief description of the product..."
                    disabled={submitting}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>4. Choose the Retailer / Wholesaler</Form.Label>
                  <Form.Control
                    as="select"
                    name="retailerWholesaler"
                    value={product.retailerWholesaler}
                    onChange={handleInputChange}
                    required
                    disabled={submitting}
                  >
                    <option>Choose...</option>
                    {retailersWholesalers.map((rw) => (
                      <option key={rw._id} value={rw.owner}>
                        {rw.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>5. Select Section</Form.Label>
                  <Form.Control
                    as="select"
                    name="section"
                    value={product.section}
                    onChange={handleInputChange}
                    disabled={submitting}
                  >
                    <option>Choose...</option>
                    {sections.map((section) => (
                      <option key={section._id} value={section._id}>
                        {section.title}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>6. Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    value={product.price}
                    onChange={handleInputChange}
                    required
                    disabled={submitting}
                  />
                  {priceWarning && (
                    <Alert variant="danger">
                      <MdWarning size={24} className="me-2" />
                      {priceWarning}
                    </Alert>
                  )}
                </Form.Group>

                {operationMode === "WHOLESALER" && (
                  <Form.Group controlId="minimumOrderVolume" className="mt-3">
                    <Form.Label>7. Minimum Order Volume (Units)</Form.Label>
                    <Form.Control
                      as="select"
                      value={minimumOrderVolume}
                      onChange={(e) =>
                        setMinimumOrderVolume(parseInt(e.target.value))
                      }
                    >
                      {[10, 20, 30, 50, 100, 150, 200, 300, 500].map(
                        (volume) => (
                          <option key={volume} value={volume}>
                            {volume}
                          </option>
                        )
                      )}
                    </Form.Control>
                    {/* Foot note ~ Default iss 10 Units */}
                    <Form.Text className="text-muted mb-3">
                      <strong
                        style={{
                          color: "lightblue",
                          fontSize: "10px",
                          fontStyle: "italic",
                        }}
                      >
                        Minimum Order Volume Default: {minimumOrderVolume} Units
                      </strong>
                    </Form.Text>
                  </Form.Group>
                )}
                <Form.Group className="mt-3">
                  <Form.Label>8. Count in Stock</Form.Label>
                  <Form.Control
                    type="number"
                    name="countInStock"
                    value={product.countInStock}
                    onChange={handleInputChange}
                    required
                    min="1"
                    disabled={submitting}
                  />
                </Form.Group>

                {/* Measurements */}
                <Form.Group className="mt-3">
                  <Form.Label>9. Measurement Units</Form.Label>
                  <Form.Control
                    name="measurements"
                    type="number"
                    inputMode="numeric"
                    pattern="^\d*(\.\d{0,2})?$"
                    value={product.measurements}
                    onChange={handleInputChange}
                    required
                    disabled={submitting}
                  />
                </Form.Group>

                {/* Units Dropdown*/}
                <Form.Group className="mt-3">
                  <Form.Label>10. Units</Form.Label>
                  <Select
                    options={units}
                    onChange={handleUnitChange}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Select Measurement Units..."
                    isDisabled={submitting}
                  />
                </Form.Group>

                {/* Category */}
                <Form.Group className="mt-3">
                  <Form.Label>11. Select Category</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={selectedCategory}
                    getOptionLabel={(e) => e.category}
                    getOptionValue={(e) => e._id}
                    loadOptions={loadCategory}
                    onInputChange={handleCategoryInputChange}
                    onChange={handleCategoryChange}
                    isClearable={true}
                    isMulti={true}
                    isDisabled={submitting}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>12. Option Groups</Form.Label>
                  <Select
                    isMulti
                    options={optionGroups.map((optionGroup) => ({
                      value: optionGroup._id,
                      label: `${optionGroup.name} (Min: ${optionGroup.minOptions}, Max: ${optionGroup.maxOptions})`,
                    }))}
                    value={selectedOptionGroups}
                    onChange={setSelectedOptionGroups}
                    placeholder="Select option groups..."
                    isDisabled={submitting}
                  />
                  <Button
                    variant="link"
                    onClick={() => setShowOptionGroupModal(true)}
                    disabled={submitting}
                  >
                    Create New Option Group
                  </Button>
                </Form.Group>

                <Form.Group>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={product.enabled}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            enabled: e.target.checked,
                          })
                        }
                        name="enabled"
                        color="secondary"
                        disabled={submitting}
                      />
                    }
                    label={
                      product.enabled
                        ? "Activate Product For Customers"
                        : "Deactivate Product For Customers"
                    }
                    classes={{ label: classes.label }}
                  />
                </Form.Group>

                <Button
                  variant="danger"
                  onClick={() => setShowConfirmModal(true)}
                  disabled={submitting}
                  style={{
                    color: submitting ? "black" : "white",
                  }}
                >
                  Discard changes?
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  style={{
                    color: submitting ? "black" : "white",
                    backgroundColor: submitting ? "#e9ecef" : "#007BFF",
                    marginLeft: "10px",
                  }}
                  disabled={uploading || submitting}
                >
                  {uploading ? (
                    <>
                      <Spinner animation="border" size="sm" /> Saving...
                    </>
                  ) : (
                    "Save changes"
                  )}
                </Button>
              </Form>
              {uploading && (
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                  <h5>Uploading Image: {uploadProgress}%</h5>
                  <ProgressBar
                    now={uploadProgress}
                    label={`${uploadProgress}%`}
                    animated
                    striped
                    variant="success"
                    style={{ height: "25px" }}
                  />
                </div>
              )}
            </Card.Body>
          </Card>

          {/* Image preview Modal */}
          <Modal
            show={showPreviewModal}
            onHide={() => setShowPreviewModal(false)}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="d-flex align-items-center justify-content-between w-100">
                <span>Crop and Preview Image</span>
                <div className="d-flex gap-2">
                  <Tooltip title="Free Crop">
                    <IconButton
                      size="small"
                      onClick={() => handleAspectChange(undefined)}
                      style={{
                        backgroundColor: !aspect
                          ? "rgba(25, 118, 210, 0.12)"
                          : "transparent",
                        color: !aspect ? "#1976d2" : "rgba(0, 0, 0, 0.54)",
                      }}
                    >
                      <MdCrop size={20} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Square (1:1)">
                    <IconButton
                      size="small"
                      onClick={() => handleAspectChange(1)}
                      style={{
                        backgroundColor:
                          aspect === 1
                            ? "rgba(25, 118, 210, 0.12)"
                            : "transparent",
                        color: aspect === 1 ? "#1976d2" : "rgba(0, 0, 0, 0.54)",
                      }}
                    >
                      <MdCropSquare size={20} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Landscape (16:9)">
                    <IconButton
                      size="small"
                      onClick={() => handleAspectChange(16 / 9)}
                      style={{
                        backgroundColor:
                          aspect === 16 / 9
                            ? "rgba(25, 118, 210, 0.12)"
                            : "transparent",
                        color:
                          aspect === 16 / 9 ? "#1976d2" : "rgba(0, 0, 0, 0.54)",
                      }}
                    >
                      <MdCropLandscape size={20} />
                    </IconButton>
                  </Tooltip>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex">
                <div
                  className="flex-grow-1"
                  style={{
                    maxHeight: "70vh",
                    overflow: "auto",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "4px",
                    padding: "16px",
                  }}
                >
                  {imagePreview && (
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => {
                        setCrop(percentCrop);
                      }}
                      onComplete={(c) => {
                        setCompletedCrop(c);
                      }}
                      aspect={aspect}
                      minWidth={aspect ? MIN_DIMENSION : undefined}
                      minHeight={aspect ? MIN_DIMENSION : undefined}
                    >
                      <img
                        ref={imgRef}
                        alt="Crop me"
                        src={imagePreview}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "70vh",
                          objectFit: "contain",
                        }}
                        onLoad={handleImageLoad}
                      />
                    </ReactCrop>
                  )}
                </div>
                <div
                  className="ms-3"
                  style={{
                    width: "280px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <h6>Preview</h6>
                  <div
                    style={{
                      width: "100%",
                      aspectRatio: "1",
                      overflow: "hidden",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "4px",
                    }}
                  >
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    <Form.Text className="text-muted">
                      <strong>Image Requirements:</strong>
                      <ul style={{ fontSize: "12px", marginTop: "8px" }}>
                        <li>
                          Format: JPG or PNG
                          {isFormatCorrect ? (
                            <span className="text-success"> ✔</span>
                          ) : (
                            <span className="text-danger"> ✘</span>
                          )}
                        </li>
                        <li>
                          Size: ≤ 10MB
                          {isSizeCorrect ? (
                            <span className="text-success"> ✔</span>
                          ) : (
                            <span className="text-danger"> ✘</span>
                          )}
                        </li>
                        <li>
                          Dimensions: ≥ 1000x1000px
                          {completedCrop &&
                          completedCrop.width >= MIN_DIMENSION &&
                          completedCrop.height >= MIN_DIMENSION ? (
                            <span className="text-success"> ✔</span>
                          ) : (
                            <span className="text-danger"> ✘</span>
                          )}
                        </li>
                      </ul>
                    </Form.Text>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowPreviewModal(false);
                  setCrop({
                    unit: "%",
                    x: 0,
                    y: 0,
                    width: 90,
                    height: 90,
                  });
                  setCompletedCrop(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSaveCrop}
                disabled={!isCropValid()}
              >
                Save Cropped Image
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>

      {/* Modal for confirmation of product creation cancelation */}
      <Modal show={showConfirmModal} onHide={handleCloseDiscardModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.5rem",
              }}
            >
              <MdWarning
                size="30px"
                color="orange"
                style={{ marginRight: "10px" }}
              />
              You might have unsaved changes!
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>If you leave this page you will lose the changes you made.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseDiscardModal}
            disabled={submitting}
          >
            Go back
          </Button>
          <Button
            variant="danger"
            onClick={handleDiscardChanges}
            disabled={submitting}
          >
            Discard changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for creating a new option group */}
      {/* <OptionGroupForm
                show={showOptionGroupModal}
                handleClose={() => setShowOptionGroupModal(false)}
                handleSave={handleOptionGroupSave}
            /> */}
      <RetailerWholesalerOptionGroupForm
        show={showOptionGroupModal}
        handleClose={() => setShowOptionGroupModal(false)}
        handleSave={handleOptionGroupSave}
      />

      {/* Add some CSS for the active state of crop buttons */}
      <style>
        {`
          button.btn-outline-success {
            border-color: #28a745;
            color: #28a745;
          }
          
          button.btn-outline-success:hover {
            background-color: rgba(40, 167, 69, 0.08);
            border-color: #28a745;
            color: #28a745;
            transform: translateY(-1px);
          }
          
          button.btn-outline-danger {
            border-color: #dc3545;
            color: #dc3545;
          }
          
          button.btn-outline-danger:hover {
            background-color: rgba(220, 53, 69, 0.08);
            border-color: #dc3545;
            color: #dc3545;
            transform: translateY(-1px);
          }

          .MuiIconButton-root {
            transition: all 0.2s ease-in-out;
          }
          
          .MuiIconButton-root:hover {
            transform: scale(1.1);
          }

          .image-action-button {
            border-radius: 20px;
            padding: 6px 16px;
            font-size: 0.875rem;
            text-transform: none;
            font-weight: 500;
            transition: all 0.2s ease-in-out;
            display: flex;
            align-items: center;
          }

          .image-action-button:hover {
            transform: translateY(-1px);
          }
          
          button.btn-outline-success.image-action-button {
            border-color: #28a745;
            color: #28a745;
          }
          
          button.btn-outline-success.image-action-button:hover {
            background-color: rgba(40, 167, 69, 0.08);
            border-color: #28a745;
            color: #28a745;
          }
          
          button.btn-outline-danger.image-action-button {
            border-color: #dc3545;
            color: #dc3545;
          }
          
          button.btn-outline-danger.image-action-button:hover {
            background-color: rgba(220, 53, 69, 0.08);
            border-color: #dc3545;
            color: #dc3545;
          }
        `}
      </style>
    </Container>
  );
};

export default CreateRetailerWholesalerProductForm;
