import io from "socket.io-client";
import { toast } from "react-toastify";

const SOCKET_URL = process.env.REACT_APP_SERVER_URL;

// Configure socket with reconnection settings
export const socket = io(SOCKET_URL, {
  transports: ["websocket"],
  autoConnect: true,
  reconnection: true,
  reconnectionAttempts: 10, // Maximum number of reconnection attempts
  reconnectionDelay: 1000, // Initial delay between reconnection attempts (1 second)
  reconnectionDelayMax: 5000, // Maximum delay between attempts (5 seconds)
  timeout: 20000, // Connection timeout (20 seconds)
});

// Connection event handlers
socket.on("connect", () => {
  console.log("Socket connected");
  toast.success("Connection restored", { toastId: "socket-connected" });
});

socket.on("disconnect", (reason) => {
  console.log("Socket disconnected:", reason);
  if (reason === "io server disconnect") {
    // Server disconnected us, retry connection
    socket.connect();
  }
  toast.warning("Connection lost. Attempting to reconnect...", {
    toastId: "socket-disconnected",
  });
});

socket.on("reconnect", (attemptNumber) => {
  console.log("Socket reconnected after", attemptNumber, "attempts");
});

socket.on("reconnect_attempt", (attemptNumber) => {
  console.log("Reconnection attempt:", attemptNumber);
});

socket.on("reconnect_error", (error) => {
  console.error("Reconnection error:", error);
  toast.error("Failed to reconnect. Please refresh the page.", {
    toastId: "socket-reconnect-error",
  });
});

socket.on("reconnect_failed", () => {
  console.error("Failed to reconnect after all attempts");
  toast.error("Connection lost. Please refresh the page.", {
    toastId: "socket-reconnect-failed",
  });
});

socket.on("connect_error", (error) => {
  console.error("Connection error:", error);
});

// Add order-specific event listeners
socket.on("newRestaurantOrder", (order) => {
  console.log("New restaurant order received");
});

socket.on("orderUpdated", (order) => {
  console.log("Order updated");
});

socket.on("orderStatusChanged", ({ orderId, status }) => {
  console.log("Order status changed:");
});

// Helper function to check connection status
export const isSocketConnected = () => socket.connected;

// Helper function to manually reconnect
export const reconnectSocket = () => {
  if (!socket.connected) {
    socket.connect();
  }
};

export default socket;
