import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  DropdownButton,
  Dropdown,
  Form,
  Nav,
  Modal,
  Spinner,
  ListGroup,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit, MdDelete } from "react-icons/md";
import { useHistory } from "react-router-dom";
import {
  createSection,
  fetchSections,
  updateSection,
  deleteSection,
} from "../actions/sectionAction.js";
import { fetchRestaurants } from "../actions/restaurantActions";
import {
  createRestaurantOption,
  createRestaurantOptionGroup,
  fetchOptionGroupsRestaurant,
  deleteOptionGroup,
  deleteOption,
  updateOption,
  updateOptionGroup,
  fetchOptionsRestaurants,
} from "../actions/optionAction.js";
import { toast } from "react-toastify";
import { Switch } from "@material-ui/core";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import { getUserFromLocalStorage } from "utilities/user.js";
import placeholderImage from "../assets/img/menuImage.png";
import ReadOnlyQuill from "./ReadOnlyQuill.js";
import OutletManager from "components/OutletManager.js";

const customFontsCSS = `
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.ql-font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.ql-editor {
  font-size: 0.875em !important;  /* Always small */
}

.ql-editor * {
  font-size: inherit !important;
}

.ql-editor h1 {
  font-size: 1.5em !important;
}

.ql-editor h2 {
  font-size: 1.3em !important;
}

.ql-editor h3 {
  font-size: 1.1em !important;
}
`;

const RestaurantVendorMenu = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const decryptedProfile = getUserFromLocalStorage();
    if (decryptedProfile) {
      setUser(decryptedProfile);
    }
  }, []);

  const vendorId = user?.result?._id || "";
  const sections = useSelector((state) => state.sections.sections);
  const restaurants = useSelector((state) => state.restaurants.restaurants);
  const optionGroups = useSelector((state) => state.options.optionGroups);
  const options = useSelector((state) => state.options.options);
  const loading = useSelector((state) => state.sections.isLoading);
  const restaurantLoading = useSelector((state) => state.restaurants.isLoading);
  const optionLoading = useSelector((state) => state.options.isLoading);
  const isLoadingDeleteOptionGroup = useSelector(
    (state) => state.options.isLoading
  );

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");
  const [selectedRestaurantId, setSelectedRestaurantId] = useState("");
  const [activeKey, setActiveKey] = useState([]);
  const [activeNav, setActiveNav] = useState("menu-overview");
  const [restaurantRequired, setRestaurantRequired] = useState(false);
  const [currentSectionId, setCurrentSectionId] = useState(null);

  const [showOptionModal, setShowOptionModal] = useState(false);
  const [optionName, setOptionName] = useState("");
  const [optionPrice, setOptionPrice] = useState(0);

  const [showOptionGroupModal, setShowOptionGroupModal] = useState(false);
  const [optionGroupName, setOptionGroupName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [minOptions, setMinOptions] = useState(0);
  const [maxOptions, setMaxOptions] = useState(0);
  const [currentOptionGroupId, setCurrentOptionGroupId] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [showOptionGroupDeleteModal, setShowOptionGroupDeleteModal] =
    useState(false);
  const [optionGroupToDelete, setOptionGroupToDelete] = useState(null);
  const [showOptionDeleteModal, setShowOptionDeleteModal] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(null);

  const [showEditOptionModal, setShowEditOptionModal] = useState(false);
  const [currentOptionId, setCurrentOptionId] = useState(null);
  const [editOptionName, setEditOptionName] = useState("");
  const [editOptionPrice, setEditOptionPrice] = useState(0);
  const [editOptionEnabled, setEditOptionEnabled] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (vendorId) {
      dispatch(fetchRestaurants(vendorId));
      dispatch(fetchSections(vendorId));
      dispatch(fetchOptionGroupsRestaurant(vendorId));
      dispatch(fetchOptionsRestaurants(vendorId));
    }
  }, [dispatch, vendorId]);

  useEffect(() => {
    if (sections.length > 0) {
      setActiveKey(sections.map((section) => String(section._id)));
    }
  }, [sections]);

  const handleMenuSectionSubmit = (e) => {
    e.preventDefault();
    if (!selectedRestaurantId) {
      setRestaurantRequired(true);
      return;
    }
    dispatch(
      createSection(
        {
          title: sectionTitle,
          description: sectionDescription,
          restaurantId: selectedRestaurantId,
        },
        vendorId
      )
    );
    setShowCreateModal(false);
    resetForm();
  };

  const handleSectionEditSubmit = (e) => {
    e.preventDefault();
    if (!selectedRestaurantId) {
      setRestaurantRequired(true);
      return;
    }
    dispatch(
      updateSection(currentSectionId, {
        title: sectionTitle,
        description: sectionDescription,
        restaurantId: selectedRestaurantId,
      })
    );
    setShowEditModal(false);
    resetForm();
  };

  const handleAddRestaurantProductClick = () => {
    history.push("/admin/menu-create");
  };

  const handleSectionClick = (eventKey) => {
    setActiveKey((prevActiveKey) =>
      prevActiveKey.includes(eventKey)
        ? prevActiveKey.filter((key) => key !== eventKey)
        : [...prevActiveKey, eventKey]
    );
  };

  const handleEditProduct = (productId) => {
    const productToEdit = sections
      .flatMap((section) => section.products)
      .find((product) => product._id === productId);

    if (productToEdit) {
      history.push({
        pathname: `/admin/edit-menu-product/${productId}`,
        state: { productDetails: productToEdit },
      });
    } else {
      console.error("Product not found!");
    }
  };

  const handleAddProductToSection = (e, sectionId, restaurantId) => {
    e.preventDefault();
    history.push({
      pathname: "/admin/menu-create",
      state: { sectionId, restaurantId },
    });
  };

  const handleEditSection = (
    sectionId,
    sectionTitle,
    sectionDescription,
    restaurantId
  ) => {
    setCurrentSectionId(sectionId);
    setSectionTitle(sectionTitle);
    setSectionDescription(sectionDescription);
    setSelectedRestaurantId(restaurantId || ""); // Ensure this line is setting the correct value or default to an empty string
    setRestaurantRequired(false);
    setShowEditModal(true);
  };

  const handleOptionSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createRestaurantOption({
        name: optionName,
        price: optionPrice,
        owner: vendorId,
      })
    );
    setShowOptionModal(false);
    resetOptionForm();
  };

  const handleOptionGroupSubmit = async (e) => {
    e.preventDefault();

    if (!selectedRestaurantId) {
      setRestaurantRequired(true);
      return;
    }

    const optionGroupData = {
      name: optionGroupName,
      restaurantProductId: selectedRestaurantId,
      options: selectedOptions.map((option) => ({
        value: option.value,
        mandatory: minOptions > 0,
      })),
      minOptions,
      maxOptions,
      owner: vendorId,
    };

    if (currentOptionGroupId) {
      await dispatch(updateOptionGroup(currentOptionGroupId, optionGroupData));
    } else {
      await dispatch(createRestaurantOptionGroup(optionGroupData));
    }

    dispatch(fetchOptionGroupsRestaurant(vendorId));

    setShowOptionGroupModal(false);
    resetOptionGroupForm();
  };

  const handleEditOptionGroup = (optionGroup) => {
    setCurrentOptionGroupId(optionGroup._id);
    setOptionGroupName(optionGroup.name);
    setSelectedOptions(
      optionGroup.options
        .map((option) => {
          if (option.option && option.option._id) {
            return {
              value: option.option._id,
              label: `${option.option.name} - ${new Intl.NumberFormat("en-UG", {
                style: "currency",
                currency: "UGX",
              }).format(option.option.price)}`,
              mandatory: option.mandatory,
            };
          }
          return null;
        })
        .filter((option) => option !== null)
    );
    setMinOptions(optionGroup.minOptions);
    setMaxOptions(optionGroup.maxOptions);
    setSelectedRestaurantId(optionGroup.restaurantId || ""); // Set selectedRestaurantId
    setRestaurantRequired(false);
    setShowOptionGroupModal(true);
  };

  const handleDeleteOption = (optionId) => {
    setOptionToDelete(optionId);
    setShowOptionDeleteModal(true);
  };

  const confirmDeleteOption = async () => {
    try {
      await dispatch(deleteOption(optionToDelete));
      setShowOptionDeleteModal(false);
      setOptionToDelete(null);
    } catch (error) {
      toast.error("Failed to delete the option. Please try again.");
    }
  };

  const handleDeleteSection = (sectionId) => {
    setSectionToDelete(sectionId);
    setShowDeleteModal(true);
  };

  const confirmDeleteSection = async () => {
    try {
      await dispatch(deleteSection(sectionToDelete));
      toast.success("Section and its products deleted successfully");
      setShowDeleteModal(false);
      setSectionToDelete(null);
    } catch (error) {
      toast.error("Failed to delete the section. Please try again.");
    }
  };

  const handleDeleteOptionGroup = (optionGroupId) => {
    setOptionGroupToDelete(optionGroupId);
    setShowOptionGroupDeleteModal(true);
  };

  const confirmDeleteOptionGroup = async () => {
    try {
      await dispatch(deleteOptionGroup(optionGroupToDelete));
      setShowOptionGroupDeleteModal(false);
      setOptionGroupToDelete(null);
    } catch (error) {
      toast.error("Failed to delete the option group. Please try again.");
    }
  };

  const handleEditOption = (option) => {
    setCurrentOptionId(option._id);
    setEditOptionName(option.name);
    setEditOptionPrice(option.price);
    setEditOptionEnabled(option.enabled);
    setShowEditOptionModal(true);
  };

  const handleEditOptionSubmit = (e) => {
    e.preventDefault();
    const updatedOption = {
      name: editOptionName,
      price: editOptionPrice,
      enabled: editOptionEnabled,
    };
    dispatch(updateOption(currentOptionId, updatedOption));
    setShowEditOptionModal(false);
    resetEditOptionForm();
  };

  const handleCloseOptionGroupModal = () => {
    resetOptionGroupForm();
    setShowOptionGroupModal(false);
  };

  const resetForm = () => {
    setSectionTitle("");
    setSectionDescription("");
    setSelectedRestaurantId("");
    setRestaurantRequired(false);
  };

  const resetOptionForm = () => {
    setOptionName("");
    setOptionPrice(0);
  };

  const resetOptionGroupForm = () => {
    setOptionGroupName("");
    setSelectedRestaurantId("");
    setSelectedOptions([]);
    setMinOptions(0);
    setMaxOptions(0);
    setCurrentOptionGroupId(null);
    setRestaurantRequired(false);
  };

  const resetEditOptionForm = () => {
    setEditOptionName("");
    setEditOptionPrice(0);
    setCurrentOptionId(null);
  };

  return (
    <Col md="10">
      <style>{customFontsCSS}</style>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <div style={{ paddingBottom: "15px" }}>
            <Card.Title as="h3" style={{ paddingBottom: "5px" }}>
              Menu
            </Card.Title>
            <p className="card-category">Restaurant ~ Listing.</p>
          </div>
          <DropdownButton
            id="dropdown-item-button"
            title="Add"
            variant="primary"
            style={{
              marginRight: "40px",
              padding: "8px 16px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            <Dropdown.Item
              as="button"
              type="button"
              onClick={() => setShowCreateModal(true)}
            >
              Add section
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              type="button"
              onClick={handleAddRestaurantProductClick}
            >
              Add product
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              type="button"
              onClick={() => {
                /* handle add collection */
              }}
            >
              Add collection
            </Dropdown.Item>
          </DropdownButton>
        </Card.Header>
        <Nav
          variant="tabs"
          activeKey={activeNav}
          onSelect={(selectedKey) => setActiveNav(selectedKey)}
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <Nav.Item style={{ display: "inline-block", margin: "0 1px" }}>
            <Nav.Link
              eventKey="menu-overview"
              style={{
                backgroundColor:
                  activeNav === "menu-overview" ? "blue" : "black",
                color: "white",
                cursor: "pointer",
                fontSize: "14px",
                padding: "8px 16px",
              }}
            >
              Menu Overview
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{ display: "inline-block", margin: "0 1px" }}>
            <Nav.Link
              eventKey="outlets"
              style={{
                backgroundColor: activeNav === "outlets" ? "blue" : "black",
                color: "white",
                cursor: "pointer",
                fontSize: "14px",
                padding: "8px 16px",
              }}
            >
              Outlets
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{ display: "inline-block", margin: "0 1px" }}>
            <Nav.Link
              eventKey="products"
              style={{
                backgroundColor: activeNav === "products" ? "blue" : "black",
                color: "white",
                cursor: "pointer",
                fontSize: "14px",
                padding: "8px 16px",
              }}
            >
              Products
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{ display: "inline-block", margin: "0 1px" }}>
            <Nav.Link
              eventKey="option-groups"
              style={{
                backgroundColor:
                  activeNav === "option-groups" ? "blue" : "black",
                color: "white",
                cursor: "pointer",
                fontSize: "14px",
                padding: "8px 16px",
              }}
            >
              Option Groups
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{ display: "inline-block", margin: "0 1px" }}>
            <Nav.Link
              eventKey="options"
              style={{
                backgroundColor: activeNav === "options" ? "blue" : "black",
                color: "white",
                cursor: "pointer",
                fontSize: "14px",
                padding: "8px 16px",
              }}
            >
              Options
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{ display: "inline-block", margin: "0 1px" }}>
            <Nav.Link
              eventKey="simulation"
              style={{
                backgroundColor: activeNav === "simulation" ? "blue" : "black",
                color: "white",
                cursor: "pointer",
                fontSize: "14px",
                padding: "8px 16px",
              }}
            >
              Simulation
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Card.Body>
          {loading || restaurantLoading || optionLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {activeNav === "menu-overview" && (
                <Accordion
                  defaultActiveKey={activeKey}
                  activeKey={activeKey}
                  onSelect={handleSectionClick}
                >
                  {sections.map((section) => (
                    <Accordion.Item
                      eventKey={String(section._id)}
                      key={section._id}
                    >
                      <Accordion.Header
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ marginRight: "auto" }}>
                            {section.title} | {section.productCount - 1}{" "}
                            product(s)
                          </span>
                          <div
                            style={{
                              width: "30px",
                              padding: "0",
                              margin: "0 10px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleEditSection(
                                section._id,
                                section.title,
                                section.description,
                                section.restaurantId
                              )
                            }
                          >
                            <MdEdit size="24px" />
                          </div>
                          <div
                            style={{
                              width: "30px",
                              padding: "0",
                              margin: "0 10px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteSection(section._id)}
                          >
                            <MdDelete size="24px" color="#f44336" />
                          </div>
                        </span>
                        <div
                          style={{
                            marginRight: "12px",
                            padding: "6px 12px",
                            backgroundColor: "#010221",
                            color: "white",
                            borderRadius: "4px",
                            cursor: "pointer",
                            display: "inline-block",
                            textAlign: "center",
                          }}
                          onClick={(e) =>
                            handleAddProductToSection(
                              e,
                              section._id,
                              section.restaurantId
                            )
                          }
                        >
                          +Add product
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {section.products &&
                          section.products.map((product) => (
                            <div
                              key={product._id}
                              className="d-flex align-items-center justify-content-between"
                              style={{
                                marginBottom: "10px",
                                borderBottom: "1px solid #ccc",
                                paddingBottom: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleEditProduct(product._id)}
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  src={product.image || placeholderImage}
                                  alt={product.name}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    marginRight: "10px",
                                    borderRadius: "20%",
                                  }}
                                />
                                <div>
                                  <h6>{product.name}</h6>
                                  <div
                                    style={{
                                      marginBottom: 0,
                                      fontSize: "12px",
                                    }}
                                  >
                                    {/* <ReactQuill
                                                                        value={product.description || ''}
                                                                        readOnly={true}
                                                                        theme="bubble"
                                                                        modules={{ toolbar: false }}
                                                                        style={{ fontSize: '0.875em' }}
                                                                    /> */}
                                    <ReadOnlyQuill
                                      value={product.description || ""}
                                    />
                                  </div>
                                  {!product.enabled && (
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        fontSize: "12px",
                                        color: "red",
                                      }}
                                    >
                                      (Deactivated)
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "monospace",
                                  fontWeight: "lighter",
                                }}
                              >
                                {new Intl.NumberFormat("en-UG", {
                                  style: "currency",
                                  currency: "UGX",
                                }).format(product.price)}
                              </div>
                            </div>
                          ))}
                        {(!section.products ||
                          section.products.length === 0) && (
                          <div>No products found.</div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              )}
              {activeNav === "outlets" && restaurants.length > 0 && (
                <OutletManager
                  restaurant={{
                    ...restaurants[0],
                    sections: sections,
                  }}
                  readOnly={true}
                />
              )}
              {activeNav === "products" && (
                <div>
                  {sections.map(
                    (section) =>
                      section.products &&
                      section.products.map((product) => (
                        <Card
                          key={product._id}
                          className="mb-3 shadow-sm border-2"
                        >
                          <Card.Body>
                            <div className="d-flex justify-content-between">
                              <div>
                                <Card.Title>{product.name}</Card.Title>
                                <div style={{ fontSize: "13px" }}>
                                  {/* <ReactQuill
                                                                    value={product.description || 'No description available'}
                                                                    readOnly={true}
                                                                    theme="bubble"
                                                                    modules={{ toolbar: false }}
                                                                    style={{ fontSize: '0.875em' }}
                                                                /> */}
                                  <ReadOnlyQuill
                                    value={
                                      product.description ||
                                      "No description available"
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <img
                                  src={product.image || placeholderImage}
                                  alt={product.name}
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "20%",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "monospace",
                                  fontWeight: "lighter",
                                  color: "green",
                                }}
                              >
                                {new Intl.NumberFormat("en-UG", {
                                  style: "currency",
                                  currency: "UGX",
                                }).format(product.price)}
                              </div>
                              <Button
                                variant="danger"
                                size="md"
                                onClick={() => handleEditProduct(product._id)}
                              >
                                Edit
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      ))
                  )}
                </div>
              )}
              {activeNav === "option-groups" && (
                <div>
                  <Button
                    variant="primary"
                    onClick={() => setShowOptionGroupModal(true)}
                    disabled={optionLoading}
                  >
                    {optionLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Add Option Group"
                    )}
                  </Button>
                  <ListGroup className="mt-4">
                    {optionGroups.map((optionGroup) => (
                      <ListGroup.Item
                        key={optionGroup._id}
                        className="d-flex justify-content-between align-items-center"
                        onClick={() => handleEditOptionGroup(optionGroup)}
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <h5>{optionGroup.name}</h5>
                          <p className="mb-0">
                            Min: {optionGroup.minOptions} | Max:{" "}
                            {optionGroup.maxOptions}
                          </p>
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-delete-${optionGroup._id}`}>
                              Delete Option Group
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteOptionGroup(optionGroup._id);
                            }}
                          >
                            <MdDelete />
                          </Button>
                        </OverlayTrigger>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              )}
              {activeNav === "options" && (
                <div>
                  <Button
                    variant="primary"
                    onClick={() => setShowOptionModal(true)}
                  >
                    Add Option
                  </Button>
                  <ListGroup className="mt-4">
                    {options.map((option) => (
                      <ListGroup.Item
                        key={option._id}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <div>
                          <strong
                            style={{
                              fontSize: "14px",
                              fontFamily: "monospace",
                              fontWeight: "lighter",
                            }}
                          >
                            {option.name}
                          </strong>{" "}
                          -
                          <span
                            style={{
                              fontSize: "14px",
                              fontFamily: "monospace",
                              fontWeight: "lighter",
                              color: "green",
                              paddingLeft: "5px",
                            }}
                          >
                            {new Intl.NumberFormat("en-UG", {
                              style: "currency",
                              currency: "UGX",
                            }).format(option.price)}
                          </span>
                        </div>
                        <div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-edit-${option._id}`}>
                                Edit Option
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => handleEditOption(option)}
                              style={{ marginRight: "5px" }} // Adding space between buttons
                            >
                              <MdEdit />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-delete-${option._id}`}>
                                Delete Option
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteOption(option._id);
                              }}
                            >
                              <MdDelete />
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              )}
              {activeNav === "simulation" && (
                <div style={{ padding: "10px" }}>
                  <p style={{ color: "#002333" }}>
                    This section simulates how the products and sections will
                    look for web users in production.
                  </p>
                  {sections
                    .filter(
                      (section) =>
                        section.products &&
                        section.products.some((product) => product.enabled)
                    )
                    .map((section) => (
                      <div
                        key={section._id}
                        id={`section-${section._id}`}
                        style={{ marginBottom: "20px" }}
                      >
                        <div style={{ marginBottom: "10px" }}>
                          <h2 style={{ fontSize: "1.2em" }}>{section.title}</h2>
                          {/* <ReactQuill
                                                    value={section.description || ''}
                                                    readOnly={true}
                                                    theme="bubble"
                                                    modules={{ toolbar: false }}
                                                    style={{ fontSize: '0.875em' }}
                                                /> */}
                          <ReadOnlyQuill value={section.description || ""} />
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fit, minmax(150px, 1fr))",
                            gap: "15px",
                          }}
                        >
                          {section.products &&
                            section.products.map((product) => (
                              <div
                                key={product._id}
                                style={{
                                  border: "0.5px solid #ccc",
                                  padding: "10px",
                                  textAlign: "left",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                  borderRadius: "8px",
                                  backgroundColor: "white",
                                  transition: "transform 0.2s",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={product.image || placeholderImage}
                                  alt={product.name}
                                  style={{
                                    maxWidth: "100%",
                                    height: "auto",
                                    borderBottom: "1px solid #eee",
                                    paddingBottom: "5px",
                                  }}
                                />
                                <div style={{ paddingTop: "5px" }}>
                                  <h5
                                    style={{
                                      fontSize: "14px",
                                      margin: "10px 0 5px",
                                      color: "#333",
                                    }}
                                  >
                                    {product.name}
                                  </h5>
                                  <div style={{ fontSize: "12px" }}>
                                    {/* <ReactQuill
                                                                    value={product.description || 'No description available.'}
                                                                    readOnly={true}
                                                                    theme="bubble"
                                                                    modules={{ toolbar: false }}
                                                                    style={{ fontSize: '0.875em' }}
                                                                /> */}
                                    <ReadOnlyQuill
                                      value={
                                        product.description ||
                                        "No description available."
                                      }
                                    />
                                  </div>
                                  <p
                                    style={{
                                      fontSize: "1em",
                                      color: "#2E5902",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {new Intl.NumberFormat("en-UG", {
                                      style: "currency",
                                      currency: "UGX",
                                    }).format(product.price)}
                                  </p>
                                  {!product.enabled && (
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        fontSize: "12px",
                                        color: "red",
                                      }}
                                    >
                                      (Deactivated)
                                    </p>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </>
          )}
        </Card.Body>
      </Card>

      {/* Modal for creating a new section */}
      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create a new section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="sectionTitle">
              <Form.Label>Section title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter section title..."
                value={sectionTitle}
                onChange={(e) => setSectionTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="sectionDescription">
              <Form.Label>Section description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter section description..."
                value={sectionDescription}
                onChange={(e) => setSectionDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="restaurantSelect">
              <Form.Label>Select Restaurant</Form.Label>
              <Form.Control
                as="select"
                value={selectedRestaurantId}
                onChange={(e) => {
                  setSelectedRestaurantId(e.target.value);
                  setRestaurantRequired(false);
                }}
                style={{ borderColor: restaurantRequired ? "red" : "" }}
              >
                <option value="" disabled>
                  Select a restaurant
                </option>
                {restaurants.map((restaurant) => (
                  <option key={restaurant._id} value={restaurant.owner}>
                    {restaurant.name}
                  </option>
                ))}
              </Form.Control>
              {restaurantRequired && (
                <Form.Text style={{ color: "red" }}>
                  Please select a restaurant.
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleMenuSectionSubmit}>
            Create section
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for editing a section */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="sectionTitleEdit">
              <Form.Label>Section title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter section title..."
                value={sectionTitle}
                onChange={(e) => setSectionTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="sectionDescriptionEdit">
              <Form.Label>Section description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter section description..."
                value={sectionDescription}
                onChange={(e) => setSectionDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="restaurantSelectEdit">
              <Form.Label>Select Restaurant</Form.Label>
              <Form.Control
                as="select"
                value={selectedRestaurantId}
                onChange={(e) => {
                  setSelectedRestaurantId(e.target.value);
                  setRestaurantRequired(false);
                }}
                style={{ borderColor: restaurantRequired ? "red" : "" }}
              >
                <option value="" disabled>
                  Select a restaurant
                </option>
                {restaurants.map((restaurant) => (
                  <option key={restaurant._id} value={restaurant.owner}>
                    {restaurant.name}
                  </option>
                ))}
              </Form.Control>
              {restaurantRequired && (
                <Form.Text style={{ color: "red" }}>
                  Please select a restaurant.
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSectionEditSubmit}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for creating a new option */}
      <Modal show={showOptionModal} onHide={() => setShowOptionModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create a new option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="optionName">
              <Form.Label>Option name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter option name..."
                value={optionName}
                onChange={(e) => setOptionName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="optionPrice">
              <Form.Label>Option price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter option price..."
                value={optionPrice}
                onChange={(e) => setOptionPrice(parseFloat(e.target.value))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowOptionModal(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleOptionSubmit}>
            Create option
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for creating or editing an option group */}
      <Modal show={showOptionGroupModal} onHide={handleCloseOptionGroupModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentOptionGroupId
              ? "Edit Option Group"
              : "Create a new option group"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="optionGroupName">
              <Form.Label>Option Group Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter option group name..."
                value={optionGroupName}
                onChange={(e) => setOptionGroupName(e.target.value)}
                style={{
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                  marginBottom: "15px",
                }}
              />
            </Form.Group>
            <Form.Group controlId="restaurantSelectOptionGroup">
              <Form.Label>Select Restaurant</Form.Label>
              <Form.Control
                as="select"
                value={selectedRestaurantId}
                onChange={(e) => {
                  setSelectedRestaurantId(e.target.value);
                  setRestaurantRequired(false);
                }}
                style={{
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                  marginBottom: "15px",
                  borderColor: restaurantRequired ? "red" : "",
                }}
              >
                <option value="" disabled>
                  Select a restaurant
                </option>
                {restaurants.map((restaurant) => (
                  <option key={restaurant._id} value={restaurant.owner}>
                    {restaurant.name}
                  </option>
                ))}
              </Form.Control>
              {restaurantRequired && (
                <Form.Text style={{ color: "red" }}>
                  Please select a restaurant.
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="optionSelect">
              <Form.Label>Select Options</Form.Label>
              <Select
                isMulti
                options={options.map((option) => ({
                  value: option._id,
                  label: `${option.name} - ${new Intl.NumberFormat("en-UG", {
                    style: "currency",
                    currency: "UGX",
                  }).format(option.price)}`,
                  mandatory: false,
                }))}
                value={selectedOptions}
                onChange={setSelectedOptions}
                formatOptionLabel={(option, { context }) => (
                  <div className="d-flex justify-content-between align-items-center">
                    <span>{option.label}</span>
                    <Form.Check
                      type="checkbox"
                      checked={selectedOptions.some(
                        (selectedOption) =>
                          selectedOption.value === option.value &&
                          selectedOption.mandatory
                      )}
                      onChange={(e) => {
                        const updatedOptions = selectedOptions.map((opt) =>
                          opt.value === option.value
                            ? { ...opt, mandatory: e.target.checked }
                            : opt
                        );
                        setSelectedOptions(updatedOptions);
                      }}
                      label="Mandatory"
                      className="ml-2"
                    />
                  </div>
                )}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    marginBottom: "15px",
                  }),
                }}
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="minOptions">
                  <Form.Label>Minimum Options</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter minimum options..."
                    value={minOptions}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      setMinOptions(value < 0 ? 0 : value);
                    }}
                    min="0"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="maxOptions">
                  <Form.Label>Maximum Options</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter maximum options..."
                    value={maxOptions}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      setMaxOptions(value < 0 ? 0 : value);
                    }}
                    min="0"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseOptionGroupModal}
            disabled={optionLoading}
          >
            {optionLoading ? (
              <>
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "black" }}
                />
                <span style={{ color: "black" }}> Updating...</span>
              </>
            ) : (
              "Discard Changes"
            )}
          </Button>
          <Button
            variant="success"
            onClick={handleOptionGroupSubmit}
            disabled={optionLoading}
          >
            {optionLoading ? (
              <>
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "black" }}
                />
                <span style={{ color: "black" }}> Updating...</span>
              </>
            ) : currentOptionGroupId ? (
              "Update Option Group"
            ) : (
              "Create Option Group"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for delete confirmation */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this section and all its products?
          This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteModal(false)}
            disabled={isLoadingDeleteOptionGroup}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={confirmDeleteSection}
            disabled={isLoadingDeleteOptionGroup}
            style={{
              cursor: isLoadingDeleteOptionGroup ? "not-allowed" : "pointer",
              color: isLoadingDeleteOptionGroup ? "black" : "white",
            }}
          >
            {isLoadingDeleteOptionGroup ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for delete option group confirmation */}
      <Modal
        show={showOptionGroupDeleteModal}
        onHide={() => setShowOptionGroupDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this option group? This action cannot
          be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowOptionGroupDeleteModal(false)}
            disabled={isLoadingDeleteOptionGroup}
            style={{
              color: isLoadingDeleteOptionGroup ? "black" : "white",
            }}
          >
            Cancel
          </Button>
          <div
            style={{
              cursor: isLoadingDeleteOptionGroup ? "not-allowed" : "pointer",
            }}
          >
            <Button
              variant="danger"
              onClick={confirmDeleteOptionGroup}
              disabled={isLoadingDeleteOptionGroup}
              style={{
                cursor: isLoadingDeleteOptionGroup ? "not-allowed" : "pointer",
                color: isLoadingDeleteOptionGroup ? "black" : "white",
              }}
            >
              {isLoadingDeleteOptionGroup ? "Deleting..." : "Delete"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Modal for delete option confirmation */}
      <Modal
        show={showOptionDeleteModal}
        onHide={() => setShowOptionDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this option? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowOptionDeleteModal(false)}
            disabled={isLoadingDeleteOptionGroup}
            style={{
              color: isLoadingDeleteOptionGroup ? "black" : "white",
            }}
          >
            Cancel
          </Button>
          <div
            style={{
              cursor: isLoadingDeleteOptionGroup ? "not-allowed" : "pointer",
            }}
          >
            <Button
              variant="danger"
              onClick={confirmDeleteOption}
              disabled={isLoadingDeleteOptionGroup}
              style={{
                cursor: isLoadingDeleteOptionGroup ? "not-allowed" : "pointer",
                color: isLoadingDeleteOptionGroup ? "black" : "white",
              }}
            >
              {isLoadingDeleteOptionGroup ? "Deleting..." : "Delete"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Modal for editing an option */}
      <Modal
        show={showEditOptionModal}
        onHide={() => setShowEditOptionModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editOptionName">
              <Form.Label>Option Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter option name..."
                value={editOptionName}
                onChange={(e) => setEditOptionName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="editOptionPrice">
              <Form.Label>Option Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter option price..."
                value={editOptionPrice}
                onChange={(e) => setEditOptionPrice(parseFloat(e.target.value))}
              />
            </Form.Group>
            <Form.Group controlId="editOptionEnabled">
              <Form.Label>Enabled</Form.Label>
              <Switch
                checked={editOptionEnabled}
                onChange={(e) => setEditOptionEnabled(e.target.checked)}
                color="primary"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowEditOptionModal(false)}
          >
            Cancel
          </Button>
          <Button variant="success" onClick={handleEditOptionSubmit}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default RestaurantVendorMenu;
