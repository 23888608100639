import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Col,
  Container,
  Image,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { getOuttaStockListings, getListing } from "actions/listingsActions";
import { useHistory } from "react-router-dom";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import InventoryIcon from "@material-ui/icons/Storage";
import { getUserFromLocalStorage } from "utilities/user";

const OutOfStock = () => {
  const user = getUserFromLocalStorage();
  const listings = useSelector((state) => state.listings);
  const { isLoading, outtaStock } = listings;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (user?.result?._id) {
      dispatch(getOuttaStockListings(user?.result?._id));
    }
  }, [dispatch, user?.result?._id]);

  if (isLoading) {
    return (
      <Spinner
        animation="grow"
        variant="danger"
        style={{
          marginTop: "70px",
          top: "50%",
          left: "50%",
          position: "fixed",
        }}
      />
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Card.Title as="h4">Out of Stock Products</Card.Title>
                  <p className="card-category">
                    <InventoryIcon
                      style={{ color: "#dc3545", fontSize: 17, marginRight: 5 }}
                    />
                    {outtaStock?.data?.length || 0} Products Need Restocking
                  </p>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              {outtaStock?.data?.length === 0 ? (
                <div className="text-center p-4">
                  <InventoryIcon
                    style={{ fontSize: 50, color: "#dc3545", marginBottom: 15 }}
                  />
                  <h4>No Out of Stock Products</h4>
                  <p className="text-muted">
                    All your products are currently in stock
                  </p>
                </div>
              ) : (
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Status</th>
                      <th>Stock</th>
                      <th>Verification</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {outtaStock?.data?.map((listing) => (
                      <tr key={listing._id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <Image
                              src={listing.product_image}
                              style={{
                                width: 45,
                                height: 45,
                                borderRadius: "8px",
                                marginRight: "10px",
                                objectFit: "cover",
                              }}
                              alt={listing.title}
                            />
                            <div>
                              <div style={{ fontWeight: "500" }}>
                                {listing.title}
                              </div>
                              <small
                                className="text-muted"
                                dangerouslySetInnerHTML={{
                                  __html: listing.description,
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>UGX {listing.price?.toLocaleString()}</td>
                        <td>
                          <span className="badge bg-danger">Out of Stock</span>
                        </td>
                        <td className="text-center">{listing.countInStock}</td>
                        <td>
                          {listing?.verifyListing === "VERIFIED" ? (
                            <VerifiedUserIcon
                              style={{ color: "#1DA1F2", fontSize: 24 }}
                            />
                          ) : (
                            <TimelapseIcon
                              style={{ color: "#FF2976", fontSize: 24 }}
                            />
                          )}
                        </td>
                        <td>
                          <button
                            className="btn btn-fill"
                            style={{
                              backgroundColor: "#172601",
                              borderColor: "#84BF04",
                              color: "white",
                              padding: "8px 16px",
                              fontSize: "0.875rem",
                              fontWeight: 500,
                              boxShadow: "0px 3px 5px rgba(0,0,0,0.2)",
                              transition: "all 0.3s ease",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(
                                getListing(
                                  listing._id,
                                  history.push(
                                    `/admin/shopEdit/${listing?._id}`
                                  )
                                )
                              );
                            }}
                          >
                            <i className="fas fa-plus-circle mr-2"></i>
                            Restock
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OutOfStock;
