import {
  FETCH_RESTAURANTS_REQUEST,
  FETCH_RESTAURANTS_SUCCESS,
  FETCH_RESTAURANTS_FAILURE,
  UPDATE_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_SUCCESS,
  UPDATE_RESTAURANT_FAILURE,
  DELETE_RESTAURANT_REQUEST,
  DELETE_RESTAURANT_SUCCESS,
  DELETE_RESTAURANT_FAILURE,
  ATTACH_STORE_REQUEST,
  ATTACH_STORE_SUCCESS,
  ATTACH_STORE_FAILURE,
  UPDATE_OUTLET_SUCCESS,
  DELETE_OUTLET_SUCCESS,
  UPDATE_RESTAURANT_OUTLET,
} from "../constants/actionTypes.js";

const initialState = {
  restaurants: [],
  loading: false,
  error: null,
};

const restaurantReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESTAURANTS_REQUEST:
    case UPDATE_RESTAURANT_REQUEST:
    case DELETE_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_RESTAURANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        restaurants: action.payload,
      };
    case FETCH_RESTAURANTS_FAILURE:
    case UPDATE_RESTAURANT_FAILURE:
    case DELETE_RESTAURANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_RESTAURANT_SUCCESS:
      return {
        ...state,
        loading: false,
        restaurants: state.restaurants.map((restaurant) =>
          restaurant._id === action.payload._id ||
          restaurant.owner === action.payload.owner
            ? action.payload
            : restaurant
        ),
      };
    case DELETE_RESTAURANT_SUCCESS:
      return {
        ...state,
        loading: false,
        // restaurants: state.restaurants.filter(restaurant => restaurant._id !== action.payload),
        restaurants: action.payload,
      };
    case ATTACH_STORE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ATTACH_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        // Optionally update the restaurants state if needed
        restaurants: state.restaurants.map((restaurant) =>
          restaurant._id === action.payload.restaurantId
            ? {
                ...restaurant,
                outlets: [...(restaurant.outlets || []), action.payload],
              }
            : restaurant
        ),
      };
    case ATTACH_STORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_OUTLET_SUCCESS:
      return {
        ...state,
        restaurants: state.restaurants.map((restaurant) => ({
          ...restaurant,
          outlets: restaurant.outlets?.map((outlet) =>
            outlet._id === action.payload._id ? action.payload : outlet
          ),
        })),
      };
    case DELETE_OUTLET_SUCCESS:
      return {
        ...state,
        restaurants: state.restaurants.map((restaurant) => ({
          ...restaurant,
          outlets: restaurant.outlets?.filter(
            (outlet) => outlet._id !== action.payload
          ),
        })),
      };
    case UPDATE_RESTAURANT_OUTLET:
      return {
        ...state,
        restaurants: state.restaurants.map((restaurant) => ({
          ...restaurant,
          outlets: restaurant.outlets?.map((outlet) =>
            outlet._id === action.payload._id
              ? { ...outlet, ...action.payload }
              : outlet
          ),
        })),
      };
    default:
      return state;
  }
};

export default restaurantReducer;
